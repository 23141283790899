import Scrollbar from 'react-custom-scrollbars'
import { CustomCheckBox, ErrorWithTimer, Header } from '../Global/components/reusable'
import {useEffect, useRef, useState} from 'react';
import './termsAndCond.css'
import axios from 'axios';
import { API, SessionVars } from '../Global/components/utils';

export default function TermsAndCondPage(props){ 
    const [text, setText] = useState("");
    const [height, setHeight] = useState();
    const [trackStyle, setTrackStyle] = useState("track-vertical-tc");
    const [error, setError] = useState("");
    const ref = useRef();
    const scrollbarContent = useRef();

    useEffect(() => {
        axios.get(props.endpoint)
        .then(res => { 
            setText(res.data);
            if(scrollbarContent.current.clientHeight + 90 < ref.current.clientHeight){
                setTrackStyle("track-hidden");
            }
            else{
                setTrackStyle("track-vertical-tc");
            }
        })
        .catch(err => {
            console.log(`Error loading ${props.name}`, err.response);
            setError(`Error loading ${props.name} policy`);
        });
    }, [])

    function HandleResize(){
        if(scrollbarContent.current.clientHeight + 90 < ref.current.clientHeight){
            setTrackStyle("track-hidden");
        }
        else{
            setTrackStyle("track-vertical-tc");
        }
        setHeight(window.innerHeight - ref.current.offsetTop - 20);
    }

    useEffect(() => {
        var timeout = setTimeout(() => {
            HandleResize();
        })
        
        window.addEventListener("resize", HandleResize);
        return () => {window.removeEventListener("resize", HandleResize); clearTimeout(timeout);}
    }, [])
    
    return(
        <div className="terms-and-cond-page">
            <Header text={props.name}/>

            <div ref={ref} style={{height: height}} className="container"> 
                <Scrollbar 
                    renderView={props => <div {...props} className="view-tc"/>}
                    renderTrackVertical={props => <div {...props} className={trackStyle}/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical-tc"/>}
                    style={{height: 'calc(100% - 30px)', width: `95%`, marginLeft: 28}}>

                    <div ref={scrollbarContent}>
                        <div dangerouslySetInnerHTML={{__html: text}}/>
                    </div>
                </Scrollbar>
                <ErrorWithTimer setError={setError.bind(this)} error={error}/>
            </div>
        </div>
    );
}

