import { useEffect, useState } from 'react';
import { ContinueBtn, LargeInputWithTextOnTop } from '../Global/components/buttons';
import { useHistory } from 'react-router-dom';
import './topup.css';

export default function TopupPage({props}){
    const [name, setName] = useState("");
    const [cardNo, setCardNo] = useState("");
    const [cardNoToDisplay, setCardNoToDisplay] = useState("");
    const history = useHistory();

    useEffect(()=> {
        let initialLenght = cardNo.length;
        if(initialLenght > 16)
        {
            alert("card number has 16 numbers");
            return;
        }

        let i = 0;
        let endString = ""
        while(i < initialLenght){
            endString += cardNo[i];
            i++;

            if(i % 4 == 0 && i < 15)
                endString += "-";
        }
        setCardNoToDisplay(endString);
    }, [cardNo])

    function HandleContinue(){
        console.log(name);
        if(name.length < 2 ){
            alert("Name to short");
            return;
        }
        else if(name === "Alessio Grande"){
            alert("Please enter your name");
            return;
        }

        history.push("topup/conf");
    }

    return(
        <div className="topup-container">
            <div className="card-container">
                <div className="mobile-container">
                    <img style={{borderRadius: '15px'}} src={process.env.PUBLIC_URL + "/Images/black\ card.png"}/>
                    <div className="card-number">{cardNoToDisplay.length > 0 ? cardNoToDisplay : "1234-5678-1234-5678"}</div>
                    <div className="card-holder-name"><span>{name.length > 0 ? name : "Alessio Grande"}</span></div>
                </div>
            </div>
            <div className="inputs-container mt-4">
                <div className="cardno">
                    <LargeInputWithTextOnTop
                        upText="Card Number"
                        maxLenght="16"
                        onChange={(e) => setCardNo(e.target.value)}
                        placeholder="1234-5678-1234-5678"/>
                </div>

                <div className="card-holder-container">
                    <LargeInputWithTextOnTop
                        upText="Card Holder Name"
                        maxLenght="26"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Alessio Grande"/>
                </div>

                <div className="cardNo-ccv-container">
                    <div className="expiration-date-container">
                        <LargeInputWithTextOnTop
                            upText="Expiration Date"
                            placeholder="MM/YYYY"/>
                    </div>

                    <div className="ccv">
                        <LargeInputWithTextOnTop
                            upText="CVV"
                            minWidth="50px"
                            placeholder="123"/>
                    </div>
                </div>

                <div
                    className="btn-container mt-3 mt-md-5"> 
                    <ContinueBtn
                        onClick={() => HandleContinue()}
                        message="Next"/>
                </div>
            </div>
        </div>
    );
}