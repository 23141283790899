import {useState, useEffect} from 'react';
import { DropdownDateSelector } from '../Global/components/dropdown';
import { LoadingScreen, TransactionGroup } from '../Global/components/reusable';
import { useHistory } from 'react-router-dom';
import "./transaction.css"
import { API, FormatCurrency, inTransaction, ReversTransactionOrder, TokenData, TokenValidation } from '../Global/components/utils';
import axios from 'axios';

const date = new Date();

let yearOptions = [];
let yearsAdded = 0;

while(yearsAdded < 30){
    yearOptions.push(date.getFullYear() - yearsAdded);
    yearsAdded++;
}

const monthsName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function TransactionPage(){
    const [selectedTransaction, setSelectedTransaction] = useState(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [stringToSeach, setStringToSeach] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [transactionsGroup, setTransactionsGroup] = useState([]);
    const [filterTimerId, setFilterTimerId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [leftDatepicker, setLeftDatepicker] = useState("0px");
    const [error, setError] = useState("");
    const [range, setRange] = useState([
        {
            selectedDay: "1",
			selectedMonth: "January",
			selectedYear: date.getFullYear()
		},
		{
            selectedDay: "31",
			selectedMonth: "December",
			selectedYear: date.getFullYear()
		}
	]);
    
    const history = useHistory();

    async function GetTransaction(offset = 0){
        const date = new Date();

        let fromDate = date.getDate() + "-" + monthsName[date.getMonth()] + "-" + (date.getFullYear() - 2).toString();
        let toDate = date.getDate() + "-" + monthsName[date.getMonth()] + "-" + date.getFullYear();

        return axios.get(API.transactionsUrl, {
            params: {
                itemsPerPage: 100,
                fromDate: fromDate,
                toDate: toDate,
                offset: offset
            },
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        })
    }

    function CreateGroup(transactions){
        if(transactions){
            const minDate = new Date(range[0].selectedYear, monthsName.indexOf(range[0].selectedMonth), range[0].selectedDay);
            const maxDate = new Date(range[1].selectedYear, monthsName.indexOf(range[1].selectedMonth), range[1].selectedDay);
            let i = 0;
            let auxList = [];
            
            while(i < transactions.length){
                let sameDay = [];
                let j = i + 1;
                let dateAux = transactions[i].createdAt.split("T")[0];
                let year = parseInt(dateAux.split("-")[0]);
                let month = dateAux.split("-")[1];
                let day = dateAux.split("-")[2];
                let elemDate = new Date(year, parseInt(month) - 1, day);

                if(elemDate >= minDate && elemDate <= maxDate){
                    sameDay.push(transactions[i]);

                    while(j < transactions.length && 
                            sameDay[0].createdAt.split("T")[0] === transactions[j].createdAt.split("T")[0]){
                        
                        sameDay.push(transactions[j]);
                        j++;
                    }
                    auxList.push(sameDay);
                    i = j;
                    j++;
                }
                else{
                    i++;
                }
            }
            return auxList;
        }
        return [];
    }

    function HandleResize(){
        if(window.innerWidth < 590){
            setLeftDatepicker("-30px");
        }
        else{
            setLeftDatepicker("0px");
        }
    }

    //INTIALIZE DATE FOR DATEPICKER
    useEffect(() => {
        const today = new Date();
        const twoDaysAgo = new Date();

        twoDaysAgo.setDate(today.getDate() - 2);
        const pastDay = twoDaysAgo.getDate();
        const pastMonth = monthsName[twoDaysAgo.getMonth()];
        const pastYear = twoDaysAgo.getFullYear();

        setRange([
            {
                selectedDay: pastDay,
                selectedMonth: pastMonth,
                selectedYear: pastYear
            },
            {
                selectedDay: today.getDate(),
                selectedMonth: monthsName[today.getMonth()],
                selectedYear: today.getFullYear()
            }
        ]);
    }, [])

    useEffect(async() => {
        setIsLoading(true);
        if(await TokenValidation()){
            let offset = 0;
            let auxList = [];

            try{
                do{
                    var res = await GetTransaction(offset);
                    offset += 100;

                    res.data.forEach(e => {
                        if(e.amount != 0)
                            auxList.push(e)
                    });
                }while(res.data.length === 100);
            }
            catch(err){
                console.log("ERROR getting the transactions\n", err.response);
                setError("Error loading the transactions, please try to refresh");
            }
            console.log(auxList);
            setTransactions(ReversTransactionOrder(auxList));
            setIsLoading(false);
        }
        else{
            history.replace("/pinCheck");
            setIsLoading(false);
        }
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    }, [])

    useEffect(() => {
        document.getElementById("search-input").value = "";
        if(range.length === 2){
            setTransactionsGroup(CreateGroup(transactions));
        }
    }, [transactions, range])

    useEffect(() => {
        if(selectedTransaction != undefined)
            setIsModalOpen(true);
    }, [selectedTransaction])

    function ApplyFilters(stringToSeach){
        let transactionsToFilter = CreateGroup(transactions);

        if(stringToSeach.length === 0){
            if(transactions.length > 0){
                setTransactionsGroup(CreateGroup(transactions));
            }
        }
        else{
            let auxStringToSearch = stringToSeach.toLowerCase()
            let groupAux = [];

            transactionsToFilter.forEach(sameDay => {
                let auxList = [];
                sameDay.forEach(elem => {
                    if(auxStringToSearch === "in"){
                        if(inTransaction.indexOf(elem.type) >= 0){
                            auxList.push(elem);
                        }
                    }
                    else if(auxStringToSearch === "out"){
                        if(inTransaction.indexOf(elem.type) < 0){
                            auxList.push(elem);
                        }
                    }
                    else if ("iban transfer".includes(auxStringToSearch) && auxStringToSearch.length <= "iban transfer".length){
                            if(elem.merchantCategoryCode == null){
                                auxList.push(elem);
                            }      
                    }
                    else if(elem.createdAt.split("T")[0].includes(auxStringToSearch) || elem.amount === parseFloat(auxStringToSearch)){
                        auxList.push(elem);
                    }
                    else if(elem.merchantDetails !== null && elem.merchantDetails !== undefined){
                        if(elem.merchantDetails.toLowerCase().includes(auxStringToSearch)){
                            auxList.push(elem);
                        }
                    }
                })
                if(auxList.length > 0)
                    groupAux.push(auxList);
            })
            setTransactionsGroup(groupAux);
        }
    }

    useEffect(() => {
        if(filterTimerId !== null){
            clearTimeout(filterTimerId);
            setFilterTimerId(null);
        }

        setFilterTimerId(
            setTimeout(() => {
                ApplyFilters(stringToSeach);
                setFilterTimerId(null);
            }, 200)
        );
    }, [stringToSeach])

    function closeModal(){
        setIsModalOpen(false);
        setSelectedTransaction(undefined);
    }

    return(
        <div className="d-flex transactions-container">
            {isLoading && (<LoadingScreen position="absolute"/>)}
            <div className="transaction-header">
                <span className="transaction-title">Transactions</span>
                <div className="d-flex flex-row">
                    <div className="search-bar">
                        <img width="16" src={process.env.PUBLIC_URL + "/Images/Icons/loupe.png"}/>
                        <input
                            id="search-input"
                            onChange = {e => setStringToSeach(e.target.value)} 
                            placeholder="Search transactions"/>
                    </div>

                    <div 
                        onClick={() => history.push("transactions/analytics")}
                        className="analytics-button">
                        <img src={process.env.PUBLIC_URL + "/Images/Icons/analytics.png"}/>
                    </div>
                </div>
            </div>

            <div className="transaction-body">
                <div className="date-selectors-container">
                    <DropdownDateSelector
                        range ={range}
                        top="-310px"
                        right="unset"
                        left={leftDatepicker}
                        setRange = {setRange.bind(this)}/>
                </div>
                {error.length > 0 ?
                    <div style={{textAlign:'center'}} className="error-message">
                        {error}
                    </div>
                :
                    <div className="transactions-group-container">
                        {transactionsGroup.length > 0 ? 
                            transactionsGroup.map((sameday, i) => {
                                return(
                                <TransactionGroup key={i} transactions={sameday} setSelectedTransaction={setSelectedTransaction.bind(this)}/>
                            )})
                        :
                        <div style={{fontFamily: 'AppFont', fontSize: '20px', marginTop: '10px'}}>
                            No transactions to display
                        </div>
                        }
                    </div>
                }
                
            </div>

            {isModalOpen && (
                <DetailedTransaction 
                    closeModal={closeModal.bind(this)}
                    data={selectedTransaction}/>
            )}
        </div>
    );
}

function DetailedTransaction({data, closeModal}){
    const [addressDispaly, setAddressDisplay] = useState("");
    const [amountToDisplay, setAmountToDisplay] = useState("");
    const [status, setStatus] = useState("");

    let typeToDispaly = "";

    function SetAddress(){
        let address = "";
        if(data.merchantCity !== null){
            address += data.merchantCity;
        }

        if(data.merchantRegion !== null){
            if(address.length > 0){
                address += " - ";
            }
            address += data.merchantRegion;
        }

        if(data.merchantCountry !== null){
            if(address.length > 0){
                address += " - ";
            }
            address += data.merchantCountry;
        }
        setAddressDisplay(address);
    }

    useEffect(() => {
        SetAddress();
        setAmountToDisplay(FormatCurrency(data.amount));
        let aux = ""
        
        aux += data.status;

        data.failureReasons.forEach(err => {
            aux += " - ";
            aux += err.replace(/([^A-Z])([A-Z])/g, '$1 $2');
        })
        setStatus(aux);
    }, [])

    inTransaction.indexOf(data.type) >= 0 ? typeToDispaly = "receive" : typeToDispaly = "send";
    return(
        <div>
            <div className="transaction-modal">
                <div className="info-container">
                <button 
                    onClick={closeModal}
                    className="close-btn">&times;</button>
                    <span className="transaction-title">Transaction Details</span>
                    <div className="preview-container">
                        <div className="d-flex align-items-center">
                            <div className="profile-pic">
                                <img
                                    width="36px"
                                    height="36px" 
                                    src={process.env.PUBLIC_URL + "/Images/sheldon.jpg"}/>
                                <img 
                                    className="transaction-type"
                                    width="14px"
                                    src={process.env.PUBLIC_URL + "/Images/Icons/" + typeToDispaly + ".png"}/>
                            </div>

                            <div className="details-container">
                                <div className="name">
                                    <span> {data.name !== null && data.name !== undefined ? 
                                        "To " + data.name : "IBAN transfer"}
                                    </span>
                                </div>
                                <div className="date">
                                    <span>{data.date}</span>
                                </div>
                            </div>
                        </div>
                        <div className="sum-container">
                            <span>{inTransaction.indexOf(data.type) < 0 ? "-" : "+"} {amountToDisplay}</span>
                        </div>
                    </div>

                    <div className="address">
                        <span className="help-info">Merchant address</span>
                        <span className="actual-info">
                            {addressDispaly.length > 0 ? 
                              addressDispaly : "IBAN transfer"}
                        </span>
                    </div>

                    <div className="other-details">
                        <div className="data-container">
                            <span className="help-info">Date</span>
                            <span className="actual-info">{data.createdAt.split("T")[0]}</span>
                        </div>

                        <div className="data-container">
                            <span className="help-info">Status</span>
                            <span className="actual-info">{status}</span>
                        </div>

                        <div className="data-container">
                            <span className="help-info">Merchant type</span>
                            <span className="actual-info">
                                {data.merchantCategoryCodeDescription !== null && data.merchantCategoryCodeDescription !== undefined ?
                                data.merchantCategoryCodeDescription : "IBAN transfer"}
                            </span>
                        </div>
                    </div>                   
                </div>
            </div>
            <div className="overlay"/>
        </div>
    );
}