import '../css/reusable.css'
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {useHistory } from 'react-router-dom';
import { API, BankAccount, Data, FormatCurrency, GetCardId, inTransaction, TokenData, TokenValidation, topup } from './utils';
import axios from 'axios';
import { ContinueBtn, LargeInputWithTextOnTop } from './buttons';
import Loader from 'react-loader-spinner';
import React from 'react';
import t from "../../services/translations"

export function MiniLogo() {
    return(
        <div className="logo_container">
            <img
                width="100"
                src={process.env.PUBLIC_URL + "/Images/" + t('AppLogo')} 
                className="logo"/>
            <span>{t('AppBrand')}</span>
        </div>
    );
}

export function AlreadyHaveAnAccount({text = "Default value", textGold = "Scurt", link='/app'}){
 
    return(
        <Link style={{textDecoration: 'none'}} to={link}>
            <span className="already-txt">{text}<strong className="gold">{textGold}</strong></span>
        </Link>
    );
}

export function ToastPopup({text, setText, timeVisible = 5000, isError = true}){
    const [opacity, setOpacity] = useState(0);

    const color = isError ? "red" : "#a0cf67"

    useEffect(() =>{
        if(text?.length > 0){
            const toastElem = document.getElementById("toastPopup");
            toastElem.classList.add("toast-popup-active");
            setTimeout(() => {
                setOpacity(0);
                toastElem.classList.remove("toast-popup-active");
                toastElem.addEventListener("transitionend", function fn(){
                    console.log("transition ned")
                    toastElem.removeEventListener("transitionend", fn);
                    setText(null);
                })
            }, timeVisible)
        }
    }, [text])

    return(
        <div id="toastPopup" style={{background: color}} className="toast-popup">
            <span style={{margin: 'auto'}}>{text}</span>
        </div>
    );
}

export function PageTitle({center=false, title ="Default value", message="Default message", strong=""}){
    let titleAlign = center ? "title-txt-reuse text-center"  : "title-txt-reuse text-center text-lg-start"

    return(
        <div className="d-flex flex-column justify-content-center justify-content-lg-left">
            <span className={titleAlign}>{title}</span>
            <br/>
            <span className="message-txt-reuse text-center text-lg-start"> 
                {message} 
                <strong style={{color: "#000"}}> {strong}</strong>
            </span>
        </div>
    );
}

export function Header({text= "Default"}){
    return(
        <span className="title">{text}</span>
    );
}

export function QA({question = "Some random question for you?", id=0,
                    answer = "Equaly random asnwer for your stupid question"}){

    const imgRef = useRef(); 
    const contentRef = useRef();
    const idModified = "FAQ:" + id;

    function showAnswer(){
        var answer = document.getElementById(idModified);
        answer.classList.toggle("show");
        contentRef.current.classList.toggle("grow");
        imgRef.current.classList.toggle("rotate-img");
    }

    return(
        <div className="accordion">
            <div ref={contentRef} className="content">
                <div onClick={showAnswer.bind(this)} className="question">
                    {question}
                    <img
                        ref={imgRef}
                        width="20"
                        height="15" 
                        src={process.env.PUBLIC_URL + "/Images/Icons/Back\ arrow.png"}/>
                </div>
                <div id={idModified} className="answer">
                    <p>{answer}</p>
                </div>
            </div>
        </div>
    );
}

export function HelpPw({top = "2px"}){
    return(
        <div className="helper-container">
            <img 
                width="16"
                style={{top: top, position: 'absolute'}}
                src={process.env.PUBLIC_URL + "/Images/Icons/help-web-button.png"}/>
            <div className="chat-container">
                <div className="arrow1"/>
                <div className="text-container">
                    <span>Your password should contain at least one capital letter, one number and one special character</span>
                </div>
            </div>
            
        </div>
    );
}

export function Notification({id=1, text="Default", isRead=false, to="/dashboard", removeFromList, readNotification}){

    let bubbleStyle = isRead ? "hidden" : "visible" 

    return(
        <div  
            className="notification">
            <div className="d-flex align-items-center">
                <div className="bubble" style={{visibility: bubbleStyle}}/>
                <div 
                    style={{cursor: 'pointer'}}
                    onClick={readNotification} 
                    className="icon-container">
                    <img
                        width="22"
                        height="22" 
                        src={process.env.PUBLIC_URL + "/Images/Icons/bell.png"}/>
                </div>
                <div
                    style={{cursor: 'pointer'}} 
                    onClick={readNotification} 
                    className="text-container">
                    <span className="text m-2">{text}</span>
                </div>
            </div>
            <img 
                onClick={removeFromList}
                className="close-icon"
                height="19"
                width="19"
                src={process.env.PUBLIC_URL + "Images/Icons/cancel.png"}/>
            <div className="separetor"/>
        </div>
    );
}

export function ImageRight(){
    return(
         <img
            width="100%"
            height="100%"
            src={process.env.PUBLIC_URL + "/Images/Heads/head-right.png"}/>
    );
}

export function Transaction({status, type = "Send", person="Fake transaction", date="18 January 2021", amount="100.00"}){
    let amountToDisplay = FormatCurrency(parseFloat(amount));

    let dateToDisplay = date.split("T")[0];
    let namePrefix = "";
    let moneyPrefix = "";
    let typeToDispaly = "";

    if(status === "Declined"){
        namePrefix = "To";
        moneyPrefix = "-";
        typeToDispaly = "declined";
    }
    else if(inTransaction.indexOf(type) >= 0){
        namePrefix = "From";
        moneyPrefix = "+";
        typeToDispaly = "receive";
    }
    else{
        namePrefix = "To";
        moneyPrefix = "-";
        typeToDispaly = "send";
    }

    return(
        <>
        <div className="transaction-container">
            <div style={{width: 'calc(100% - 80px)'}} className="d-flex">
                <div className="image-container">
                    <div className="person-image">
                        <img 
                            width="30"
                            style={{backgroundColor: 'var(--bg-color)'}}
                            src={process.env.PUBLIC_URL + "Images/Icons/bag.png"}/>
                    </div>

                    <img 
                        className="type-image"
                        src={process.env.PUBLIC_URL + `Images/Icons/${typeToDispaly}.png`}
                        width="15"
                        />
                </div>
            
                <div className="details-container">
                    <span className="name">{person != null ? person : "IBAN transfer"}</span>
                    <span className="date">{dateToDisplay}</span>
                </div>
            </div>
            
            <div className="sum-container">
                <span>{moneyPrefix}  {amountToDisplay}</span>
            </div>
        
        </div>
        <div className="transaction-histroy-separator"/>
        </>
    );
}

export function RightContainerSignUp(){
    return(
        <div 
                style={{height: '100%'}}
                className="d-flex flex-column align-self-stretch align-content-left justify-content-between px-5">
        </div>
    );
}

export function LoadingScreen({position = "fixed"}){
    return(
        <div style={{position: position}} className="loading-screen">
            <Loader style={{margin: 'auto'}} type="ThreeDots" color="var(--main-color)" height="90" width="200" />
        </div>
    );
}

export function ErrorWithTimer({error = "", setError = (e) => {}}){
    const [timerId, setTimerId] = useState(null);
    const [position, setPosition] = useState("relative");
    useEffect(() => {
        clearTimeout(timerId);
        if(error.length > 0){
            setPosition("relative");
        }
        else{
            setPosition("absolute");
        }
        setTimerId(setTimeout(() => {
            setError("");
        }, 5000))
    }, [error])

    return(
        <div style={{height: '24px', position: position}} className="error-message">
            {error}
        </div>
    );
}

export function LinksDashboard({onlyOne = false, setHasNoCard}){
    const [balance, setBalance] = useState("");
    const [iban, setIban] = useState("");
    const [accountNumber, setAccountNumber] = useState(""); 
    const [sortCode, setSortCode] = useState("");
    const [swift, setSwitft] = useState("");
    const [noCardDetails, setNoCardDetails] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [indexCardDetails, setIndexCardDetails] = useState(0);
    const [pause, setPause] = useState(false);
    const [bannersRedirect, setBannersRedirect] = useState([]);
    const [bannersPhoto, setBannersPhoto] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [buttonMessage, setButtonMessage] = useState("");
    const [cardStatus, setCardStatus] = useState(sessionStorage.getItem(Data.cardStatus));
    const [link1HasListeners, setLink1HasListeners] = useState(false);
    const [link2HasListeners, setLink2HasListeners] = useState(false);
    const [image, setImage] = useState("Activate.png");
    const [leftArrowVisible, setLeftArrowVisible] = useState('hidden');
    const [rightArrowVisible, setRightArrowVisible] = useState('visible');
    const [timerId, setTimerId] = useState(null);
    const [errorTimerId, setErrorTimerId] = useState(null);
    const [clipboardImg, setClipboardImg] = useState("copy clipboard.png");
    const [activatePopup, setActivatePopup] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [isToastError, setIsToastError] = useState(false);
    const [cardToken, setCardToken] = useState("");
    const [pinPopup, setPinPopup] = useState(false);
    const [error, setError] = useState("");

    const history = useHistory();
    let bannersCount = 0;
    
    let apiObjArr = [];
    let localObjArr = [];
    let idsToFetch = [];
    let fetchObjArr = [];

    function loadLocalObj(){
        let image = [];
        let link = [];
        let id = [];

        if(localStorage.getItem(Data.bannerId) !== null){
            image = localStorage.getItem(Data.bannerImg).split(',');
            link = localStorage.getItem(Data.bannerLink).split(',');
            id = localStorage.getItem(Data.bannerId).split(',');
        }
        
        setBannersPhoto(image);
        setBannersRedirect(link);

        let i = 0;
        while(i < image.length){    
            localObjArr.push({
                id: id[i],
                image: image[i],
                link: link[i]
            })
            i++;
        }
    }

    async function handleTopupResponse(){
        const url = new URLSearchParams(window.location.search);
        const topupSuccessed = url.get("topup_successed");

        if(topupSuccessed == "false"){
            setToastMessage("Your topup has failed");
            setIsToastError(true);

            window.history.replaceState({}, '', window.location.href.split('?')[0]);
        }
        else if(topupSuccessed == "true"){
            //REFRESH BALANCE
            window.history.replaceState({}, '', window.location.href.split('?')[0]);

            setToastMessage("Topup successed!");
            setIsToastError(false);
            if(await TokenValidation()){
                axios.get(API.bankAccountUrl, {
                    headers:{
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    setBalance(FormatCurrency(res.data.balance, res.data.currency));
                })
                .catch(err => {
                    setToastMessage("Error updating balance");
                })
            }
            else{
                history.replace("/pinCheck");
            }
        }        
    }

    //API - LOAD BANERS ID/TITLE/REDIRECT
    useEffect(async () => {
        handleTopupResponse();
        loadLocalObj();
        if(await TokenValidation()){
            axios.get(API.bannersUrl, {
                headers: {
                    'Authorization' : `Bearer ${sessionStorage.getItem(TokenData.token)}` 
                }
            })
            .then(async res => {
                let ids = [];
                let redirects = [];
                bannersCount = res.data.totalCount;
                res.data.items.forEach(item => {
                    apiObjArr.push({
                        id: item.id,
                        redirect: item.redirectUrl
                    });

                    ids.push(item.id);
                    redirects.push(item.redirectUrl);
                })

                if(await TokenValidation() && refresh === true && ids.length !== 0){
                    await checkSavedData();
                }
                else{
                    history.replace("/pinCheck");
                }
            })
            .catch(err => {
                console.log("Banners error", err);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    },[])

    async function checkSavedData(){
        let localIds = [];
        localObjArr.forEach(obj => {
            localIds.push(obj.id);
        });
        
        //id to fetch image for
        idsToFetch = apiObjArr.filter(apiObj => !localIds.includes(apiObj.id));
    
        //id that are still valid
        localObjArr = apiObjArr.filter(apiObj => localIds.includes(apiObj.id));
    
        await FetchImages(idsToFetch);
    }

    async function FetchImages(idsToFetch){
        if(idsToFetch != []){
            let fetchedImages = [];
            let fetchedRedirects = [];
            if(await TokenValidation()){
                const token = sessionStorage.getItem(TokenData.token);
                idsToFetch.forEach((obj, index) => {
                    const URL = API.bannersUrl + "/" + obj.id;
                    //console.log("URL: ", URL, token);
                    axios.get(URL, {
                        headers:{
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        fetchedImages.push(res.data.imageBase64);
                        fetchedRedirects.push(res.data.redirectUrl);
                        fetchObjArr.push({
                            id: obj.id,
                            image: res.data.imageBase64,
                            link: res.data.redirectUrl
                        })
                        if(localObjArr.length + fetchObjArr.length === bannersCount){
                            SaveAndDispalyDate();
                            setRefresh(false);
                        }
                    })
                    .catch(err =>{
                        console.log("Error getting banners img\n", err);
                    })
                });
            }
            else{
                history.replace("/pinCheck");
            }
        }
    }

    function SaveAndDispalyDate() {
        const fullArr = localObjArr.concat(fetchObjArr);

        let image = [];
        let link = [];
        let id = [];

        fullArr.forEach(obj => {
            image.push(obj.image);
            link.push(obj.link);
            id.push(obj.id);
        })

        setBannersPhoto(image);
        setBannersRedirect(link);

        localStorage.setItem(Data.bannerId, id);
        localStorage.setItem(Data.bannerLink, link);
        localStorage.setItem(Data.bannerImg, image);
    }

    function CardStatusHandler(){
        if(cardStatus === "Order"){
            var elem = document.getElementById("next");
            if(elem)
                elem.click();
        }
        else if(cardStatus === "AwaitingActivation"){
            console.log("ACTIVATING");
            setActivatePopup(!activatePopup);
        }
    }

    function NextCardInfo(){
        if(indexCardDetails < noCardDetails){
            document.getElementById(indexCardDetails + 1).classList.remove("bubble-active");   
            document.getElementById(indexCardDetails + 2).classList.add("bubble-active");   
            setIndexCardDetails(indexCardDetails + 1);
            setClipboardImg("copy clipboard.png");    
            document.getElementById("info-popup").classList.remove("info-popup-active");
            clearTimeout(timerId);
        }
    }

    function PrevCardInfo(){
        if(indexCardDetails > 0){
            document.getElementById(indexCardDetails + 1).classList.remove("bubble-active");   
            document.getElementById(indexCardDetails).classList.add("bubble-active"); 
            setIndexCardDetails(indexCardDetails - 1);
            setClipboardImg("copy clipboard.png");    
            document.getElementById("info-popup").classList.remove("info-popup-active");
            clearTimeout(timerId);
        }
    }

    function CopyToClipboard(){
        setClipboardImg("copy clipboard active.png");
        switch(indexCardDetails){
            case 0: {
                navigator.clipboard.writeText(iban);
                setToastMessage("Your IBAN has been copied!");
                break;
            }

            case 1:{
                navigator.clipboard.writeText(swift);
                setToastMessage("Your BIC SWIFT has been copied!");
                break;
            }
                
            case 2: {
                navigator.clipboard.writeText(accountNumber);
                setToastMessage("Your ACCOUNT NUMBER has been copied!");
                break;
            }

            case 3: {
                navigator.clipboard.writeText(sortCode);
                setToastMessage("Your SORT CODE has been copied!");
                break;
            }
        }

        setTimerId(setTimeout(() => {
            setClipboardImg("copy clipboard.png");
        }, 5000));
    }

    useEffect(() => {
        if(noCardDetails === 1){
            document.getElementById("3").style.display = 'none';
            document.getElementById("3").style.position = 'absolute';
            document.getElementById("4").style.display = 'none';
            document.getElementById("4").style.position = 'absolute';
            document.getElementById("bubble-container").style.left = '47%';
        }
        else{
            document.getElementById("3").style.display = 'block';
            document.getElementById("3").style.position = 'relative';
            document.getElementById("4").style.display = 'block';
            document.getElementById("4").style.position = 'relative';
            document.getElementById("bubble-container").style.left = '38%';
        }
    }, [noCardDetails])

    useEffect(() => {
        if(indexCardDetails === 0){
            setLeftArrowVisible('hidden');
        }
        else{
            setLeftArrowVisible('visible');
        }

        if(indexCardDetails === noCardDetails){
            setRightArrowVisible('hidden');
        }
        else{
            setRightArrowVisible('visible');
        }
    }, [indexCardDetails])

    useEffect(() => {
        if(cardStatus === "Order"){
            setButtonMessage("Order your card");
        }
        else if(cardStatus === "AwaitingActivation"){
            setButtonMessage("Activate card");
            setImage("Activated.png");
        }
        else {
            setHasNoCard(false);
        }
    }, [cardStatus])

    //MAKE A INFINIT SLIDER
    useEffect(() => {
        if(link1HasListeners === false){
            const slider = document.getElementById("slider");
           
            slider.addEventListener('transitionend', () => {
                slider.appendChild(slider.firstElementChild);
                slider.style.transition = 'none';
                slider.style.transform = 'translate(0)';
                setTimeout(() => {
                    slider.style.transition = 'all 0.5s';
                })
                setCurrentIndex(0);
                setLink1HasListeners(true);
            })
        }

        if(link2HasListeners === false){
            const slider2 = document.getElementById("slider2");
            slider2.addEventListener('transitionend', () => {
                slider2.appendChild(slider2.firstElementChild);
                slider2.style.transition = 'none';
                slider2.style.transform = 'translate(0)';
                setTimeout(() => {
                    slider2.style.transition = 'all 0.5s';
                })
                setCurrentIndex(0);
                setLink2HasListeners(true);
            })
        }   

        if(sessionStorage.getItem(BankAccount.balance) === null){
            if(TokenValidation()){
                axios.get(API.bankAccountUrl, {
                    headers: {
                        'Authorization' : `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    setBalance(FormatCurrency(res.data.balance, res.data.currency));
                    setIban(res.data.iban);
                    setSwitft(res.data.bicSwift);
                    setSortCode(res.data.ukSortCode);
                    setAccountNumber(res.data.ukAccountNumber);
                    
                    if(res.data.ukAccountNumber === null){
                        setNoCardDetails(1);
                    }
                    else{
                        setNoCardDetails(3);
                    }
                    sessionStorage.setItem(BankAccount.iban, res.data.iban);
                    sessionStorage.setItem(BankAccount.balance, FormatCurrency(res.data.balance, res.data.currency));
                    sessionStorage.setItem(BankAccount.bicSwift, res.data.bicSwift);
                    sessionStorage.setItem(BankAccount.sortCode, res.data.ukSortCode);
                    sessionStorage.setItem(BankAccount.accountNumber, res.data.ukAccountNumber);
                    sessionStorage.setItem(BankAccount.currency, res.data.currency);
                })
                .catch(err => {console.log(err.response)});
            }
            else{
                history.replace("/app");
            }
        }
        else{
            setIban(sessionStorage.getItem(BankAccount.iban));
            setSwitft(sessionStorage.getItem(BankAccount.bicSwift));
            setBalance(sessionStorage.getItem(BankAccount.balance));
            setSortCode(sessionStorage.getItem(BankAccount.sortCode));
            setAccountNumber(sessionStorage.getItem(BankAccount.accountNumber));

            if(sessionStorage.getItem(BankAccount.accountNumber) == "null"){
                setNoCardDetails(1);
            }
            else{
                setNoCardDetails(3);
            }
        }
    }, [])

    //PAUSE THE SLIDER WHEN MOUSE HOVERS OVER IT
    useEffect(() => {
        if(!pause){
            const interval = setInterval(() => {		
                setCurrentIndex(currentIndex + 1);
            }, 5000);

            return() =>{
                if(interval){
                    clearInterval(interval);
                }
            };
        }
	}, []);

    //RESET SLIDER
    useEffect(() => {
        if(currentIndex === 5){
            setTimeout(() => {
                setCurrentIndex(0)
            }, 500)
        }
    }, [currentIndex])

    let cardId = "";
    let serverCardToken = "";
    async function ValidateToken(){
        var res = await GetCardId();
        if(res.data.length === 0){
            setError("No card has been found");
            return;
        }
        else {
            res.data.forEach(card => {
                if(card.type.toLowerCase() === "physical"){
                    if(card.token == cardToken){
                        cardId = card.id;
                        serverCardToken = card.token;
                        setPinPopup(true);
                    }
                    else{
                        setError("Invalid token provided");
                    }
                }
            })
        }
    }

    async function ActivateCard(pin){
        var SHA256 = require('js-sha256').sha256;
        const encryptedPin = SHA256(pin);
        if(await TokenValidation()){
            axios.post(API.activateCadUrl, {}, {
                params: {
                    cardId: cardId,
                    userPin: encryptedPin,
                    cardToken: cardToken
                },
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                setToastMessage("Your card has been activated!");
                setActivatePopup(false);
                setPinPopup(false);
                const elem = document.getElementById("info-popup");

                if(elem.classList.contains("info-popup-active")){
                    elem.classList.remove("info-popup-active");
                    clearTimeout(timerId);
                }
                elem.classList.add("info-popup-active");
            })
            .catch(err => {
                console.log("ERROR activating the card\n", err.response);
                setPinPopup(false);
                if(err.response.data.error?.message !== undefined){
                    setError(err.response.data.error.message);
                }
                else if(err.response.status === 403){
                    setError("Invalid card token number");
                }
                else if(err.response.status >= 500){
                    setError("Server failed to respond");
                }
                else{
                    setError("Activation failed");
                }

                if(errorTimerId !== null){
                    clearTimeout(errorTimerId);
                }

                setErrorTimerId(setTimeout(() => {
                    setErrorTimerId(null);
                    setError("");
                }, 5000))
            })
        }else{
            history.replace("/pinCheck");
        }
    }

    const linkZindex = onlyOne ? ["-1", "absolute"] : ["1", "relative"];
    const orderZindex = onlyOne ? ["1", "relative"] : ["-1", "absolute"];

    return(
        <div className="p-0 m-0 link-dashboard-container">
            <Link id="next" style={{position: 'absolute'}} to={{pathname: '/card/new', state: 'physical'}}/>
            {pinPopup && (
                <PopupWithInput 
                    title="Please insert your pin to activate the physical card"
                    confirmAction={pin => ActivateCard(pin)}
                    denyAction={() => setPinPopup(false)}/>
            )}
            {activatePopup && (
                <div>
                    <div className="activate-popup">
                        <span onClick={() => setActivatePopup(false)} className="close-icon">&times;</span>
                        <div className="title">Please insert your pin to activate the physical card</div>
                        <div className="separetor">
                            <div style={{minWidth: '300px', margin: 'auto 0'}}>
                                <LargeInputWithTextOnTop 
                                    upText="Token Number"
                                    onChange={e => setCardToken(e.target.value)}
                                    placeholder="Please insert your token number"/>
                                <div>
                                    <div style={{height: '24px'}} className="error-message">{error}</div>
                                    <ContinueBtn onClick={() => ValidateToken()}/>
                                </div>
                            </div>
                            <img src={process.env.PUBLIC_URL + "/Images/Card activation helper.png"}/>
                        </div>
                    </div>
                    <div style={{zIndex: 9}} className="overlay"/>
                </div>
            )}
            <ToastPopup text={toastMessage} setText={setToastMessage} isError={isToastError}/>
           
            <div className="link1">
                <div className="img-container">
                    <div style={{cursor: 'pointer'}} onClick={() => history.push("/topup")} className="topup-img"/>
                </div>
                <div className="info-container"> 
                    <div className="money">{balance}</div>
                    <div className="slider-container">
                        <div className="d-flex align-items-center">
                            <img onClick={() => PrevCardInfo()} style={{visibility: leftArrowVisible}} className="navigation-arrow" src={process.env.PUBLIC_URL + "/Images/Icons/collapse-arrow.png"}/>
                            <div className="item-to-show">
                                <div style={{transform: `translateX(${indexCardDetails * -160.5}px)`}} className="item-visible">
                                    <div className="iban">
                                        <span>IBAN</span><br/>
                                        <span>{iban}</span>
                                    </div>

                                    <div className="iban">
                                        <span>Bic swift</span><br/>
                                        <span>{swift}</span>
                                    </div>

                                    <div className="iban">
                                        <span>Account number</span><br/>
                                        <span>{accountNumber}</span>
                                    </div>

                                    <div className="iban">
                                        <span>Sort code</span><br/>
                                        <span>{sortCode}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="bubble-container" style={{display: 'none'}} className="bubbles-container">
                            <div id="1" className="bubble bubble-active"/>
                            <div id="2" style={{marginLeft: '5px'}} className="bubble"/>
                            <div id="3" style={{margin: '0 5px'}} className="bubble"/>
                            <div id="4" className="bubble"/>
                        </div>
                    </div>
                </div>
                <div className="actions-container">
                    <img onClick={() => CopyToClipboard()} style={{width: '18px', height: '18px'}} src={process.env.PUBLIC_URL + "/Images/Icons/" + clipboardImg}/>
                    <img onClick={() => NextCardInfo()} style={{visibility: rightArrowVisible}} className="navigation-arrow" src={process.env.PUBLIC_URL + "/Images/Icons/collapse-arrow.png"}/>
                </div>
            </div>
            
            <div style={{zIndex: linkZindex[0], position: linkZindex[1], margin: '0 30px'}} className="link link-hidden p-0">
                <div 
                    onMouseEnter={() => setPause(true)}
                    onMouseLeave={() => setPause(false)}
                    id="slider"
                    style={{transform: `translateX(-${currentIndex * 100}%)`}}
                    className="slide-container">
                        {bannersPhoto.map((img, index) => {
                            return(
                                <img 
                                    key = {index}
                                    id = {index}
                                    onClick = {() => window.open(bannersRedirect[index])}
                                    src = {`data:image/png;base64,${img}`}/>
                                );
                            })
                        }
                </div> 
            </div>

            <div
                style={{zIndex: orderZindex[0], position: orderZindex[1], margin: '0 30px'}}
                className="order-card-container p-2">
                <div
                    onClick = {() => CardStatusHandler()}
                    className="button-container">
                    <img height="18px" src={process.env.PUBLIC_URL + "/Images/Icons/" + image}/>
                    <span>{buttonMessage}</span>
                </div>
            </div>
            
            <div className="link p-0">
                <div 
                    onMouseEnter={() => setPause(true)}
                    onMouseLeave={() => setPause(false)}
                    id="slider2"
                    style={{transform: `translateX(-${currentIndex * 100}%)`}}
                    className="slide-container">
                    {bannersPhoto.map((img, index) => {
                        if(index < bannersPhoto.length - 1){
                            return(
                                <img
                                id= {index}
                                key = {index} 
                                onClick = {() => window.open(bannersRedirect[index + 1])}
                                src = {`data:image/png;base64,${bannersPhoto[index + 1]}`}/>
                                );
                            }
                        })
                    }
                     <img 
                        onClick = {() => window.open(bannersRedirect[0])}
                        src = {`data:image/png;base64,${bannersPhoto[0]}`}/>
                </div> 
            </div>
        </div>
    );
}

export function Popup({message= "Some text to inform user about an important action", confirmText= "Yes", denyText ="No", 
            confirmAction = () => {}, denyAction = () => {}, hasActions = true, closeAction = () => {}}){
          
    let margin = hasActions ? "unset" : "auto"
    return(
        <div>
            <div className="delete-container">
            <button 
                onClick={() => closeAction()}
                className="close-btn">&times;</button>
                <span style={{margin: margin}} className="message">{message}</span>
                {hasActions && (
                    <div className="dialog-buttons">
                        <button 
                            onClick = {() => confirmAction()}
                            className="confirm">{confirmText}
                        </button>
                        <button onClick={() => denyAction()} className="deny">{denyText}</button>
                    </div>
                )}
                
            </div>
            <div className="overlay2"/>
        </div>
    );
}

export function PopupWithInput({title, overlayStyle = {}, confirmAction = (pin) => {}, denyAction = () => {}}){
    const [pin, setPin] = useState("");
    const [error, setError] = useState("");
    var ref = React.createRef();
    useEffect(()=>{
        if(ref.current) ref.current.focus();
    },[]);
    return(
        <div>
            <div style={overlayStyle} className="overlay"/>
            <div className="popup-with-input">
                <div className="title">{title}</div>
                <input ref={ref} onChange = {e => setPin(e.target.value)} maxLength={7} type="password"/>
                <span className="text-danger">{error}</span>
                <div>
                    <div onClick={() => { if(pin) { confirmAction(pin); } else { setError('Pin must not be empty'); ref.current.focus(); }}} className="confirm">
                        <span>Confirm</span>
                    </div>
                    <div onClick={() => denyAction()} className="deny">
                        <span>Cancel</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function TransactionGroup({transactions, setSelectedTransaction}){
    let dateToPrint = transactions[0].createdAt.split("T")[0];

    return(
        <div className="transaction-group">
            <div className="header">
                <span>{dateToPrint}</span>
            </div>

            <div className="body">
                {transactions.map((transaction, i) => {
                    return(
                    <Transaction2 key={i} data={transaction} setSelectedTransaction={setSelectedTransaction}/>
                )})}
            </div>
        </div>
    )
}

function Transaction2({data, setSelectedTransaction}){
    const [amountToDisplay, setAmountToDisplay] = useState("");
    let typeToDispaly = "";
    
    useEffect(() => {
        setAmountToDisplay(FormatCurrency(data.amount));
    }, [])
    if(data.status === "Declined"){
        typeToDispaly = "declined";
    }
    else{   
        inTransaction.indexOf(data.type) >= 0 ? typeToDispaly = "receive" : typeToDispaly = "send";
    }
    return(
        <div className="transaction2-container my-2">
            <div style={{width: '80%'}} className="d-flex">
                <div className="profile-pic">
                    <img
                        width="30px"
                        height="30px" 
                        src={process.env.PUBLIC_URL + "/Images/Icons/bag.png"}/>
                    <img 
                        className="transaction-type"
                        width="16px"
                        src={process.env.PUBLIC_URL + "/Images/Icons/" + typeToDispaly + ".png"}/>
                </div>

                <div className="info-container">

                    <div className="name">
                        <span>{data.merchantName !== null ? 
                                data.merchantName : "IBAN transfer"}
                        </span>
                    </div>
                    <div className="date">
                        <span>{data.status}</span>
                    </div>
                </div>
            </div>
            
            <div className="sum-container">
                <span>{inTransaction.indexOf(data.type) < 0 ?   "-" : "+"} {amountToDisplay}</span>
            </div>

            <div 
                onClick={() => {setSelectedTransaction(data)}}
                className="view-more">
                <img src={process.env.PUBLIC_URL + "/Images/Icons/view.png"}/>
            </div>
        </div>
    );
}

export function News({img = "", link = ""}){
    return(
        <div className="news-container">
            <div className="image-container">
                <img 
                    onClick={() => {window.open(link, '_blank')}}
                    width="100%"
                    height="300px"
                    style={{cursor: 'pointer'}}
                    src={"data:image/png;base64," + img}/>
            </div>
        </div>
    );
}

export function CustomCheckBox({isActive, setIsActive = (() => {})}){
	let circleStyle = isActive ? "circle active" : "circle deactive";
	let backgroundStyle = isActive ? "checkbox-example active" : "checkbox-example";
    
	return(
		<div className="custom-checkbox">
			<div 
				onClick={() => {setIsActive(!isActive)}}
				className={backgroundStyle}>
				<div className={circleStyle}/>
			</div>
		</div>
	);
}