import { useEffect, useRef, useState } from 'react';
import { Header } from '../Global/components/reusable';
import './account.css';

export default function AccountPage(){
    const [height, setHeight] = useState();
    const ref = useRef();
    const ref2 = useRef();
    
    function HandleResize(){
        setHeight(window.innerHeight - ref.current.offsetTop - ref2.current.offsetTop - 20);
    }

    useEffect(() => {
        var timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);

        return() => {clearTimeout(timeout); window.removeEventListener("resize", HandleResize)}
    }, [])

    return(
        <div ref={ref2} className="account-container">
            <Header text="Account"/>
            <div ref={ref} style={{height: height}} className="btn-container">
            </div>
        </div>
    );
}