import React, {Children, useEffect, useState} from 'react';
import '../css/slideshow.css';
import {useSwipeable} from 'react-swipeable';
import img1 from '../../Images/Signin/PrePay-Image1.jpg';
import img2 from '../../Images/Signin/PrePay-Image2.jpg';
import img3 from '../../Images/Signin/PrePay-Image3.png';


const images = ["/Images/Signin/stadium.jpg", "/Images/Signin/team.jpg", "/Images/Signin/team1.jpg"]

export default function SlideShow(){
	const [currentIndex, setCurrentIndex] = useState(0);

	const updateIndex = (newIndex) => {
		if(newIndex < 0) 
			newIndex = 3 - 1;

		else if(newIndex >= 3){
			newIndex = 0;
		}
		setCurrentIndex(newIndex);
	}

	return(
		<>
			<div className="slideshow-text">
				
				<div className="bulets">
				{images.map((child, index) => {
					return (
						<button
							key = {index}
							style ={{zIndex: 10}}
							className={`${index === currentIndex ? "active" : ""}`} 
							onClick={() => {updateIndex(index);}}/>
						);
					})}
			</div> 
			</div>
			<Carousel 
				currentIndex = {currentIndex}
				updateIndex = {(currentIndex) => updateIndex(currentIndex)}
				style={{zIndex: -10}}>
				<CarouselItem imageSrc={img1}/>
				<CarouselItem imageSrc={img2}/>
				<CarouselItem imageSrc={img3}/>
			</Carousel>
		</>
	);
}

function CarouselItem  ({imageSrc, width}) {
	return (
			<div className="image"
				style={{ backgroundImage: `url(${imageSrc})` }}/>
	);
};

const Carousel = ({children, currentIndex, updateIndex}) =>{
	const [paused, setPaused] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			if(!paused){
				updateIndex(currentIndex + 1);
			}
		}, 5000);

		return() =>{
			if(interval){
				clearInterval(interval);
			}
		};
	});

	const handlers = useSwipeable({
		onSwipedLeft: () => updateIndex(currentIndex + 1),
		onSwipedRight: () => updateIndex(currentIndex -1),
		trackMouse: true
	});

	return(
		<div 
			{...handlers}
			className="carousel"
			onMouseEnter={() => setPaused(true)}
			onMouseLeave={() => setPaused(false)}>
			
			<div 
				className="inner" 
				style={{transform: `translateX(-${currentIndex * 33.333334}%)`}}>
				{React.Children.map(children, (child, index)=>{
					return React.cloneElement(child, {width: "100%"});
				})}
			</div>	
		</div>
	);
};
