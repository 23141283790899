import { ArrowInput, ContinueBtn, GoldenButton, LargeInputWithTextOnTop, SmallDoubleInputsWithTextOnTop } from "../Global/components/buttons";
import { API, RemoveBase64Sufix, SessionVars, TokenData, TokenValidation } from "../Global/components/utils";
import { useEffect, useRef, useState, useCallback } from "react";
import { ErrorWithTimer, Header } from "../Global/components/reusable";
import { Dropdown } from "../Global/components/dropdown";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import './personalInfo.css';
import ReactCrop from "react-image-crop";

export function ChangePicturePage({setProfilePic = () => {}}){
    const [fileType, setFileType] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [onPageLoad, setOnPageLoad] = useState(true);
    const [error, setError] = useState();
    const [minHeight, setMinHeight] = useState();
    const [imgToUpload, setImgToUpload] = useState();
    const [upImg, setUpImg] = useState();
	const [crop, setCrop] = useState({ unit: '%', aspect: 1 / 1, width: 100});
	const [completedCrop, setCompletedCrop] = useState(null);

	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
    const ref = useRef();
        
    const history = useHistory();
    
    function HandleResize(){
        if(ref.current !== null)
            setMinHeight(Math.max(window.innerHeight - ref.current.offsetTop - 20), 420);
    }

    //HANLDE RESIZE OF SCREEN
    useEffect(() => {
        var timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);

        return () => {window.removeEventListener("resize", HandleResize); clearTimeout(timeout);} 
    }, [])

    //LOAD PROFILE PICTURE
    useEffect(async () => {
        if(onPageLoad){
            if(localStorage.getItem(SessionVars.profilePic) !== "" && localStorage.getItem(SessionVars.profilePic) !== null){
                setUpImg(localStorage.getItem(SessionVars.profilePic));
                var auxImg = localStorage.getItem(SessionVars.profilePic);
                setImgToUpload(auxImg.substr("data:image/jpeg;base64,".length));
            }
            else{
                if(await TokenValidation()){
                    const URL = API.getProfilePictureUrl + "/" + sessionStorage.getItem(SessionVars.userId);
                    axios.get(URL, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                        }
                    })
                    .then(res => {
                        setUpImg("data:image/jpeg;base64," + res.data.fileContent);
                        localStorage.setItem(SessionVars.profilePic, "data:image/jpeg;base64," + res.data.fileContent)
                        setLoaded(true);
                        setOnPageLoad(false);
                    })
                    .catch(err => {
                        console.log("Error fetching the profile pic\n",err);
                    })
                } 
                else{
                    history.replace("/pinCheck");
                }
            }
        }
    }, [])

    async function Save(){
        const auxToSaveLocaly = previewCanvasRef.current.toDataURL(fileType);
        const auxToLoad = RemoveBase64Sufix(auxToSaveLocaly);
    
        if(await TokenValidation()){
            axios.post(API.setProfilePictureUrl, {
                type: "Image",
                imageContent: auxToLoad
            },
            {
                headers:{
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${sessionStorage.getItem(TokenData.token)}` 
                }
            })
            .then(res => {
                setLoaded(true);
                setProfilePic(auxToSaveLocaly);
                localStorage.setItem(SessionVars.profilePic, auxToSaveLocaly);
                setError("");
                history.push("/home");
            })
            .catch(err => {
                console.log("Error uploading photo", err.response);
                setError("Something failed while uploading profile pic");
                //setLoaded(false);
            });
        }
        else{
            history.replace("/pinCheck");
        }
    }

    const hadleUploadChange = (e) => {
        const file = e.target.files[0];

        if(!file){
            return;
        }

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        setFileType(file.type);
        reader.onload = () => {
            setUpImg(`data:${file.type};base64,${btoa(reader.result)}`);
            setImgToUpload(`${btoa(reader.result)}`);
        }
    }

    const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
	}

	const image = imgRef.current;
	const canvas = previewCanvasRef.current;
	const crop = completedCrop;

	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	const ctx = canvas.getContext('2d');
	const pixelRatio = window.devicePixelRatio;

	canvas.width = crop.width * pixelRatio * scaleX;
	canvas.height = crop.height * pixelRatio * scaleY;

	ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
	ctx.imageSmoothingQuality = 'high';

	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width * scaleX,
		crop.height * scaleY
	);
	}, [completedCrop]);

    const shiftFocus = () =>{
        const input = document.querySelector('input[id=btn-file');
        
        if(input){
            input.click();
        }
    }

    return(
        <div>
            <div className="d-flex align-items-center">
                <img
                    onClick = {() => history.push("/dashboard/personalInfo")}
                    style={{cursor: "pointer", marginRight: 10}}
                    width = "26"
                    src={process.env.PUBLIC_URL + "/Images/Icons/Back\ arrow.png"}/>

                <span className="page-title">Change profile picture</span>
            </div>
            <div ref={ref} style={{minHeight: minHeight}} className="change-picture-container">
                <div className="d-flex flex-column align-items-center">                
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        style={{width: '300px'}}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />

                    <input 
                        id="btn-file" 
                        className="add-photo" 
                        accept="image/*" 
                        type="file" 
                        onChange={hadleUploadChange}>
                    </input>
                    
                    <div
                        onClick={shiftFocus} 
                        className="add-photo-style mt-4">
                        <div className="circle">
                            <div className="vertical"/>
                            <div className="horizontal"/>
                            </div>
                            <div className="text">
                                <span>Choose from Gallery</span>
                            </div>
                    </div>
                </div>    
                
               
                <div className="d-flex flex-column align-items-center">
                    <div className="preview">
                        Preview
                    </div>
                    <div>
                        <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                            borderRadius: '1000px',
                            width: '150px',
                            height: '150px'
                        }}
                        />
                    </div>
                    <ErrorWithTimer error={error} setError={e => setError(e)}/>
                    <div style={{width: '200px'}}>
                        <ContinueBtn
                            onClick={() => Save()}
                            message="Save"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PersonalInfo(){
    const cache = {};
    function importAll(r) {
        r.keys().forEach((key) => (cache[key] = r(key)));
    }
    // Note from the docs -> Warning: The arguments passed to require.context must be literals!
    importAll(require.context("../Images/Flags", false, /\.(png|jpe?g|svg)$/));

    const options = [{language:"Romania - Romana", img:"romania.png"}, {language:"Espana - Espanol", img:"spain.png"}, {language:"United Kinddom - English", img:"united-kingdom.png"}, {language:"Deutschland - Deutsch", img:"germany.png"},{language:"France - France", img:"france.png"}];

    const [countyList, setCountyList] = useState([]);
    const [county, setCounty] = useState(null);
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [streetName, setStreetName] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [height, setHeight] = useState();
    const [error, setError] = useState("");

    const ref = useRef();
    const dropdownRef = useRef();
    const history = useHistory();

    const toggling = () => setIsOpen(!isOpen);
  
    const onOptionClicked = value => () => {
      setSelectedOption(value);
      setIsOpen(false);
    };

    function Sort(arr){
        let i = 0;
        let j = 0;
        let aux;

        while(i < arr.length){
            j = i + 1;
            while(j < arr.length){
                if(arr[i] > arr[j]){
                    aux = arr[i];
                    arr[i] = arr[j];
                    arr[j] = aux;
                }
                j++;
            }
            i++;
        }
        return arr;
    }

    function HandleResize(){
        setHeight(window.innerHeight - ref.current.offsetTop - 20);
    } 

    useEffect(() => {
        const timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);
        return () => {window.removeEventListener("resize", HandleResize); clearTimeout(timeout);}
    }, [])

    //INITIALIZE FIELDS WITH EXISTING DATA
    useEffect(() =>{
        async function GetCounties(){
            await axios.get("https://api.banking.bss-lab.it/api/world/lookup-state-by-country-id/232")
            .then(res => {
                let auxArr = []
                res.data.map(auxCounty => auxArr.push(auxCounty.name));
                setCountyList(Sort(auxArr));
            })    
            .catch(err => {
                setError("Some error occured while loading the counties")
                console.log("ERROR loading the countries\n", err.response);
            });
        }
        
        async function LoadData(){
            if(await TokenValidation()){
                await axios.get(API.me, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    setHouseNumber(res.data.addressNumber);
                    setStreetName(res.data.addressStreet);
                    setPostcode(res.data.postcode);
                    setCity(res.data.city);
                    setCounty(res.data.stateOrProvince);
                    setAdditionalInfo(res.data.addressRefinement);
                })
                .catch(err => {
                    console.log("ERROR me\n", err.response);
                    setError("Error loading users data, please try to refresh page");
                })
            }
        }

        GetCounties();
        LoadData();
    }, []);

    //EVENT LISTNERS
    useEffect(() => {
        function handler(event) {
            if(!dropdownRef.current?.contains(event.target)) {
                setIsOpen(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])

    let languageStyle = () => {
        if(!selectedOption) return "";

        return isOpen ? "has-value-openned" : "has-value";
    }

    async function Save(){
        if(await TokenValidation()){
            axios.post(API.updatePersonalInfo,
            {
                country: "United Kingdom",
                stateOrProvince: county,
                city: city,
                street: streetName,
                number: houseNumber,
                refinement: additionalInfo,
                postCode: postcode
            },
            {
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                },
            })
            .then(res => {
                history.push("/home");
            })
            .catch(err => {
                console.log("ERROR updating personal info\n", err);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }

    return(
        <div className="personalInfo-container">
            <Header text="Personal Information"/>
            <div ref={ref} style={{height: height}} className="inputs-container">
                <ArrowInput 
                    handlePageChange={() => history.push("/dashboard/personalInfo/picture")}
                    name="Change Picture"/>


                <div className="double-input-container">
                    <div className="left-input">
                        <Dropdown
                            upText="County"
                            options={countyList}
                            initialValue={county}
                            setSelect={setCounty.bind(this)}
                            header="Please select your county"/>
                    </div>
                    <div className="right-input">
                        <LargeInputWithTextOnTop
                            upText="City"
                            initialValue={city}
                            onChange={e => setCity(e.target.value)}
                            placeholder="Please write your city"/>
                    </div>
                </div>

                <div className="retype-pw my-1">
                    <SmallDoubleInputsWithTextOnTop
                        upTextRight="House number"
                        placeholderRight="House number"
                        initialValueRight={houseNumber}
                        upTextLeft="Street name"
                        initialValueLeft={streetName}
                        onChangeLeft={e => setStreetName(e.target.value)}
                        onChangeRight={e => setHouseNumber(e.target.value)}
                        placeholderLeft="Please write your street name"/>
                </div>                

                <div className="double-input-container">
                    <div className="left-input">
                        <LargeInputWithTextOnTop
                            upText="Postcode"
                            initialValue={postcode}
                            placeholder="Please write your postcode"
                            onChange={e => setPostcode(e.target.value)}/>
                    </div>

                    <div className="right-input">
                        <LargeInputWithTextOnTop
                            upText="Appartment, building, floor"
                            initialValue={additionalInfo}
                            onChange={e => setAdditionalInfo(e.target.value)}
                            placeholder="Please write your appartment, building, floor"/>
                    </div>
                </div>
                <ErrorWithTimer setError={setError.bind(this)} error={error}/>
                <div style={{marginTop: '90px'}} onClick={() => Save()} className="btn-container">
                    <GoldenButton message="Save"/>
                </div>
            </div>
        </div>
    );
}