import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { ContinueBtn } from '../Global/components/buttons';
import { MiniLogo } from '../Global/components/reusable';
import SlideShow from '../Global/components/slideshow';
import { API, TokenData, TokenValidation } from '../Global/components/utils';
import './signConfirmed.css';

export default function SignConfirmedPage(props) {
    return(
        <div className="container-fluid row main m-0 p-0">
            <div 
                className="slideshow-container-main d-flex justify-content-center col-md-5 p-0 m-0">
                <SlideShow/>
            </div>
            <RightSide/>
        </div>
    );
}

function RightSide(){
    const token = sessionStorage.getItem(TokenData.token)
    const history = useHistory();
    
    function clickHanlder() {
        if(TokenValidation())
            axios.get(API.pinStatustUrl, {
                headers:{
                    'Authorization': `Bearer ${token}` 
                }
            })
            .then(res => {
                if(res.data != "Stored"){
                    history.push("/pin");
                }
                else{
                    history.push("/home", "_self");
                }
            })
            .catch(err => console.log(err));
        else{
            history.replace("/");
        }
    }

    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row">
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    <div>
                        <Link to="/signin" style={{ textDecoration: 'none' }}>
                            <div className="back-btn-container">
                                <div className="back-arrow"/>
                                <span>Back</span>
                            </div>
                        </Link>

                        <div className="verification-text">
                            Identity verification
                        </div>
                    </div>
                </div>
            </div>
                <div className="checkmark-container">
                    <div className="checkmark">
                        <div className="line1"/>
                        <div className="line2"/>
                        <div className="oval"/>
                    </div>
                    
                    <span>Thank you!</span>
                </div>

                <div className="submit-container">
                    <ContinueBtn
                        onClick={() => clickHanlder()}
                        message="Submit verification"/>
                </div>
            </div>
        </div>
    );
}