import LeftSide from '../Global/components/leftSide';
import {AlreadyHaveAnAccount,  ImageRight,  MiniLogo, PageTitle} from '../Global/components/reusable';
import {BackAndContinueBtns, LargeInputWithTextOnTop, SmallDoubleInputsWithTextOnTop} from '../Global/components/buttons';
import './formLocation.css'
import { useEffect, useState } from 'react';
import { API, SessionVars } from '../Global/components/utils';
import { Dropdown } from '../Global/components/dropdown';
import axios from 'axios';
export default function FormLocationPage(){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

const RightSide = () => {
    const [streetName, setStreetName] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [postcode, setPostcode] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [counties, setCounties] = useState([]);

    function Sort(arr){
        let i = 0;
        let j = 0;
        let aux;

        while(i < arr.length){
            j = i + 1;
            while(j < arr.length){
                if(arr[i] > arr[j]){
                    aux = arr[i];
                    arr[i] = arr[j];
                    arr[j] = aux;
                }
                j++;
            }
            i++;
        }
        return arr;
    }

    useEffect(() => {
        axios.get("https://api.banking.bss-lab.it/api/world/lookup-state-by-country-id/232")
        .then(res => {
            let auxArr = []
            res.data.map(auxCounty => auxArr.push(auxCounty.name));
            setCounties(Sort(auxArr));
        })    
        .catch(err => alert("Some error occured while loading the counties"));
    }, [])

    function continueHandler(){
        sessionStorage.setItem(SessionVars.addressStreet, streetName);
        sessionStorage.setItem(SessionVars.addressRefinement, additionalInfo);
        sessionStorage.setItem(SessionVars.postcode, postcode);
        sessionStorage.setItem(SessionVars.city, city);
        sessionStorage.setItem(SessionVars.stateOrProvince, county);

        window.open("/formProfession", "_self");
    }
    
    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row" style={{height: 'auto'}}>
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                
                    <div className="page-title-container">
                        <PageTitle
                            title="Sign Up"
                            message="Let's get started!"/>
                    </div>
                </div>
                <span style={{fontSize: '20px', fontFamily: 'AppFont'}}>Please enter your registered home address.</span>
                <div className="d-flex flex-column align-items-center">
                    <div className="main-adress-container">
                        <LargeInputWithTextOnTop
                            upText="Address line 1"
                            onChange = {e => {setAdditionalInfo(e.target.value);}}
                            placeholder="Please write your house number or flat details"/>
                    </div>

                    <div className="optional-adreess-container">
                        <LargeInputWithTextOnTop
                            upText="Street name"
                            onChange={e => {setStreetName(e.target.value);}}
                            placeholder="Please enter your street name"/>
                    </div>

                    <div className="small-container">
                        <div style={{width: '48%'}}>
                            <LargeInputWithTextOnTop
                                upText="City"
                                onChange={e => {setCity(e.target.value);}}
                                placeholder="Please enter your city"/>
                        </div>

                        <div style={{width: '48%'}}>
                            <Dropdown 
                                options={counties}
                                header='Please select your county'
                                upText='County'
                                setSelect={x => setCounty(x)}/>
                        </div>
                    </div>

                    <div className="optional-adreess-container">
                        <LargeInputWithTextOnTop
                            upText="Postcode"
                            onChange={e => {setPostcode(e.target.value);}}
                            placeholder="Please enter yuor postcode"/>
                    </div>
                    </div>

                <BackAndContinueBtns
                    isDisabled = {streetName === "" || postcode === ""}
                    backHandler = {() => {window.open("/formName", "_self")}}
                    continueHandler = {() => continueHandler()}/>

                <div className="already-container-signupPage w-100">
                    <AlreadyHaveAnAccount
                        text="Already have an account?"
                        textGold=" Sign In"/>
                </div>
            </div>
        </div>
    );
}