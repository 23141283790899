import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/buttons.css';
import { HelpPw } from './reusable';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export function ContinueBtn({ message = "Continue", isDisabled = false, onClick = (() => { }) }) {
    let specialStyle = isDisabled ? "continue-container disabled" : "continue-container"

    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            className={specialStyle}>
            <span className="txt">{message}</span>
        </button>
    );
}

export function BackAndContinueBtns(props) {
    var back = props.back !== undefined ? props.back : "Back";
    var forward = props.forward !== undefined ? props.forward : "Continue";

    let specialStyle = props.isDisabled ? "btn-continue disabled" : "btn-continue"
    return (
        <div className="buttons d-flex justify-content-center">
            <div className="mx-3">
                <button onClick={props.backHandler} className="btn-back">{back}</button>
            </div>

            <div className="mx-3">
                <button disabled={props.isDisabled} onClick={props.continueHandler} className={specialStyle}>{forward}</button>
            </div>
        </div>
    );
}

export function LargeInputPhoneWithTextOnTop({ upText, placeholder, type = "text", helperTag = false, minWidth = "unset",
    maxLenght = 9999999, error = "", initialValue = null, autoComplete = "", onChange = ((e) => { }), setError = (() => { }) }) {

    const [errorToDispaly, setErrorToDispaly] = useState("");
    const [typeDispaly, setTypeDispaly] = useState(type);

    useEffect(() => {
        setErrorToDispaly(error);
        if (error != "") {
            setTimeout(() => { setErrorToDispaly(""); error = ""; setError("") }, 5000);
        }
    }, [error])

    let localMinWidth;
    helperTag ? localMinWidth = "190px" : localMinWidth = minWidth
    return (
        <div className="large-container d-flex flex-column position-relative">
            <div className="d-flex align-items-center justify-content-start">
                <div style={{ minWidth: minWidth, height: '25px' }} className="up-text mb-1">{upText}</div>
                {helperTag && (
                    <div className="from-password-helper">
                        <HelpPw />
                    </div>
                )}
            </div>

            <div style={{ position: 'relative' }}>
                <PhoneInput
                    international
                    withCountryCallingCode
                    name={upText}
                    placeholder={placeholder}
                    onChange={e => onChange(e)}
                    defaultCountry="GB"
                    maxLength={maxLenght}
                    
                    autoComplete={autoComplete}
                    type={typeDispaly} />
            </div>

            <span style={{ height: '24px' }} className="error-message">{errorToDispaly}</span>
        </div>
    );
}

export function LargeInputWithTextOnTop({ upText, placeholder, type = "text", helperTag = false, minWidth = "unset",
    maxLenght = 9999999, error = "", initialValue = null, autoComplete = "", onChange = ((e) => { }), setError = (() => { }) }) {

    const [errorToDispaly, setErrorToDispaly] = useState("");
    const [typeDispaly, setTypeDispaly] = useState(type);

    useEffect(() => {
        setErrorToDispaly(error);
        if (error != "") {
            setTimeout(() => { setErrorToDispaly(""); error = ""; setError("") }, 5000);
        }
    }, [error])

    let localMinWidth;
    helperTag ? localMinWidth = "190px" : localMinWidth = minWidth
    return (
        <div className="large-container d-flex flex-column position-relative">
            <div className="d-flex align-items-center justify-content-start">
                <div style={{ minWidth: minWidth, height: '25px' }} className="up-text mb-1">{upText}</div>
                {helperTag && (
                    <div className="from-password-helper">
                        <HelpPw />
                    </div>
                )}
            </div>

            <div style={{ position: 'relative' }}>
                <input
                    name={upText}
                    placeholder={placeholder}
                    onChange={e => onChange(e)}
                    maxLength={maxLenght}
                    defaultValue={initialValue}
                    autoComplete={autoComplete}
                    type={typeDispaly} />

                {type === "password" && (
                    <img
                        className="view-pw"
                        onMouseLeave={() => setTypeDispaly("password")}
                        onMouseEnter={() => setTypeDispaly("text")}
                        src={process.env.PUBLIC_URL + "/Images/Icons/view.png"} />
                )}
            </div>

            <span style={{ height: '24px' }} className="error-message">{errorToDispaly}</span>
        </div>
    );
}

export function SmallDoubleInputsWithTextOnTop(props) {
    return (
        <div className="double-container d-flex p-0 m-0">
            <div className="left-input">
                <LargeInputWithTextOnTop
                    upText={props.upTextLeft}
                    placeholder={props.placeholderLeft}
                    onChange={props.onChangeLeft}
                    initialValue={props.initialValueLeft}
                    error={props.errorLeft}
                    setError={props.setErrorLeft} />
            </div>

            <div className="right-input">
                <LargeInputWithTextOnTop
                    upText={props.upTextRight}
                    placeholder={props.placeholderRight}
                    onChange={props.onChangeRight}
                    initialValue={props.initialValueRight}
                    error={props.errorRight}
                    setError={props.setErrorRight} />
            </div>
        </div>
    );
}

export function MenuItemColumn({ icon = "loupe.png", width = 40, height = 40, text = "some text", isActive = false, onClickFunction }) {
    if (isActive) {
        const splitedIcon = icon.split(".");
        icon = splitedIcon[0] + "2." + splitedIcon[1];
    }

    return (
        <div
            onClick={() => { onClickFunction() }}
            className="menuitem-container">
            <div className="nav-icon-container">
                <img
                    src={process.env.PUBLIC_URL + "/Images/Icons/" + icon}
                    className="icon"
                    width={width}
                    height={height} />
            </div>
            <span className="text">{text}</span>
        </div>
    );
}

export function MenuItemRow({ icon = "loupe.png", width, height, text = "some text", onClickFunction }) {
    return (
        <div
            onClick={() => onClickFunction(text)}
            className="menuitem-row-container">
            <div className="nav-icon-container">
                <img
                    src={process.env.PUBLIC_URL + "/Images/Icons/" + icon}
                    className="icon"
                // width ={width}
                // height={height}
                />
            </div>
            <div className="text-container">{text}</div>
        </div>
    );
}

export function ArrowInput({ name = "Name", handlePageChange }) {
    return (
        <div
            style={{ cursor: "pointer" }}
            onClick={() => {
                handlePageChange(name);
            }}
            className="arrowInput-container">
            <span>{name}</span>
            <img
                src={process.env.PUBLIC_URL + "/Images/Icons/Back\ arrow.png"}
                style={{ transform: "rotate(180deg)" }}
                width="22"
                height="16" />
        </div>
    );
}

export function NewInput({ textTop = "Default", placeholder = "default", helperBubble = false, type = "tex", onChange = ((e) => { }) }) {

    function HelperFunction(e) {
        onChange(e);
    }

    return (
        <div className="newInput-container">
            <div className="d-flex align-items-top">
                <span className="text-top">{textTop}</span>
                {helperBubble && (
                    <div className="helper mx-3"><HelpPw top="8px" /></div>
                )}
            </div>
            <input
                type={type}
                onChange={HelperFunction}
                placeholder={placeholder} />
        </div>
    );
}

export function GoldenButton({ message = "Default" }) {
    return (
        <button className="goldenbtn-container">
            <span>{message}</span>
        </button>
    );
}

export function IconTextBtnHorizontal({ icon = "analytics.png", id = "", text = "Some random text", active = true,
    extraIcon = undefined, action = false, onClick = (() => { }) }) {
    let iconName = "/Images/Icons/" + icon;
    let extraIconName = "/Images/Icons/" + extraIcon;

    if (action && extraIcon === undefined)
        iconName = iconName.substr(0, iconName.length - 4) + " active.png";
    else if (action) {
        extraIconName = extraIconName.substr(0, extraIconName.length - 4) + " active.png";
    }

    function MouseIn() {
        if (active) {
            const elem = document.getElementById(id);
            if (elem !== null)
                elem.style.background = "var(--bg-alt3-color)";
        }
    }

    function MouseOut() {
        if (active) {
            const elem = document.getElementById(id);
            if (elem !== null)
                elem.style.background = "";
        }
    }

    let opacity = active ? 1 : 0.6

    return (
        <div
            onMouseEnter={() => MouseIn()}
            onMouseLeave={() => MouseOut()}
            onClick={onClick}
            style={{ opacity: opacity }}
            className="icon-text-btn-container">
            <div>
                <img src={process.env.PUBLIC_URL + iconName} />
            </div>
            <div className="text">
                <span>{text}</span>
            </div>
            {extraIcon && (
                <div style={{ marginRight: '10px' }}>
                    <img src={process.env.PUBLIC_URL + extraIconName} />
                </div>
            )}
        </div>
    );
}