import { useHistory } from "react-router-dom";
import LeftSide from "../Global/components/leftSide.js";
import {AlreadyHaveAnAccount, MiniLogo, PageTitle} from "../Global/components/reusable.js";
import {ContinueBtn} from '../Global/components/buttons';
import { useEffect, useState } from "react";
import "./phoneVerification.css"
import axios from "axios";
import { API, SessionVars, TokenData, TokenValidation} from "../Global/components/utils.js";
import { autocompleteClasses } from "@mui/material";

function PhoneVerificationPage(){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

export function RightSide({phoneVerification = true}){
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [timerId, setTimerId] = useState("");
    const history = useHistory();
    let auxCodeArr = [0, 0, 0, 0, 0, 0];
    const phone = sessionStorage.getItem(SessionVars.appName);
    console.log(phone);
    useEffect(() => {
        if(errorMessage != ""){
            setTimeout(() => {setErrorMessage("")}, 5000);
        }
    }, [errorMessage])
    
    const OnClick = (props) => {
        props.currentTarget.setSelectionRange(0, 0);
    }

    const HandleFocus = (props) =>{
        props.currentTarget.setSelectionRange(0, 0);
        props.currentTarget.setAttribute("placeholder", "");
    }

    const BlurFnc = (props) => {
        if(!props.currentTarget.value)
            props.currentTarget.setAttribute("placeholder", "__");
    }
    
    function AutoFill(value){
        console.log("auto filling");
        let i = 0;
        while(i < 6){
            let nextfield = document.querySelector( `input[name=code${i + 1}]`);
            nextfield.value = value[i];
            auxCodeArr[i] = value[i];
            nextfield.classList.add("code-completed");
            i++;
        }
    }

    const HandleChange = (props) => {
        console.log("hanlde change");
        const aux = props.currentTarget.value; 
        if( aux.length >= 6){
            ClearInputs();
            AutoFill(aux.trim());
        }
        else{
            var currentIndex = parseInt(props.currentTarget.name.substring(4));

            let nextfield;
            if(props.currentTarget.value.length > 0){
                props.currentTarget.classList.add("code-completed");
                nextfield = document.querySelector( `input[name=code${currentIndex + 1}]`);
            }
            else{
                props.currentTarget.classList.remove("code-completed");
                nextfield = document.querySelector( `input[name=code${currentIndex - 1}]`);
            }
    
            if(props.currentTarget.value.length >= 2){
                props.currentTarget.value = props.currentTarget.value.substring(0,1);
            }
            auxCodeArr[currentIndex - 1] = props.currentTarget.value;
    
            if(nextfield != null){
                nextfield.focus();
            }
        }
    }

    function ClearInputs(){
        console.log("clearing inputs");
        let currentIndex = 1;
        while(currentIndex < 7){
            let nextfield = document.querySelector( `input[name=code${currentIndex}]`);
            nextfield.placeholder = "__";
            nextfield.value = null;
            nextfield.classList.remove("code-completed");
            currentIndex++;
        }
    }

    function handleSubmit(){
        let codeStr = "";
        auxCodeArr.map(char => codeStr += char);
        var SHA256 = require("js-sha256").sha256;
        const encryptedCode = SHA256(codeStr);
        console.log(encryptedCode, codeStr);
        if(phoneVerification === true){
            ClearInputs();
            console.log(sessionStorage.getItem(SessionVars.phoneChannelId));
            axios.get(API.codeValidationUrl, {
                params:{
                    id: sessionStorage.getItem(SessionVars.phoneChannelId),
                    code: codeStr
                }
            }).then(res => {
                if(res.data === true){
                    sessionStorage.setItem(SessionVars.phoneChannelCode, codeStr);
                    console.log(codeStr);
                    history.push("/phoneConfirmed");
                }
                else
                    throw("some error");
            })
            .catch(err => {
                setErrorMessage("Code is invalid or has expired");    
                console.log("Invalid or expired code\n", err)
            });
            // window.open("/phoneConfirmed", "_self");
        }
        else{
            console.log(codeStr);
            if(TokenValidation()){
                var config = {
                    method: 'post',
                        url: `${API.storePinUrl}${encryptedCode}`,
                    headers: { 
                    'Authorization': `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                };
                axios(config)
                .then(res => {
                    const cryptojs = require("crypto-js");
                    // var ciphertext = cryptojs.AES.encrypt(password, codeStr).toString();
                    // localStorage.setItem(SessionVars.password, ciphertext);
                    // localStorage.setItem(SessionVars.appName, phone);
                    history.push("/home");
                })
                .catch(err =>{
                    console.log("Error with the pin\n", err);
                    setErrorMessage("Failed to set the pin");
                    errorMessage()
                });
            }
            else{
                history.replace("/");
            }
        }
    }
    
    function handleResend() {
        ClearInputs();
        axios.post(API.channelValidationUrl, {
            identifier: sessionStorage.getItem(SessionVars.appName),
            type: "Phone"
        })
        .then(res => {
            if(res.status === 200)
            {
                setMessage("Validation code has been resent");
                sessionStorage.setItem(SessionVars.phoneChannelId, res.data);
                const elem = document.getElementById("info-popup");

                if(elem.classList.contains("info-popup-active")){
                    elem.classList.remove("info-popup-active");
                    clearTimeout(timerId);
                }
                elem.classList.add("info-popup-active");

                setTimerId(setTimeout(() => {
                    elem.classList.remove("info-popup-active");
                    setMessage("");
                }, 5000));
            }
        })
        .catch(err => {
            setErrorMessage("Some error occured while trying to resent the code");
            console.log("Resend error: ", err);
        })
    }


    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row">
                <div id="info-popup" style={{width: '300px', zIndex: 12, display:'flex'}} className="info-popup">
                    <span style={{margin: 'auto'}}>{message}</span>
                </div>
                <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                    
                    <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                        <div>
                            <MiniLogo/>
                        </div>

                        <div className="page-title-container">
                            {
                                phoneVerification == true ? 
                                
                                <div className="page-title-reworked">
                                    Enter the 6 digit code we sent to <strong>{phone}</strong>
                                </div>
                            
                                :
                                <PageTitle
                                    title={"Pin Page"}
                                    message={"Set your pin"}
                                    strong={phone}/>
                            }
                        </div>
                    </div>
                
                <div className="d-flex justify-content-center w-100">
                    <div className="code-container d-flex col-xl-10 col-lg-12 col-md-10 col-12 align-self-center align-self-lg-start justify-content-between">
                        
                    <input name="code1" 
                        onClick={OnClick.bind(this)}
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next5" placeholder="__"/>

                    <input name="code2" 
                        onClick={OnClick.bind(this)}
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next4" placeholder="__"/>

                    <input name="code3" 
                        onClick={OnClick.bind(this)}
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next3" placeholder="__"/>
                    
                    <input name="code4" 
                        onClick={OnClick.bind(this)}
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)}
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next2" placeholder="__"/>

                    <input name="code5" 
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next1" placeholder="__"/>

                    <input name="code6" 
                        onClick={OnClick.bind(this)}
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted" placeholder="__"/>
                    </div>
                </div>

                <div>    
                    <div className="d-flex flex-column align-items-center">
                        <span style={{height: '24px'}} className="error-message mb-3">{errorMessage}</span>
                        
                        <div className="continue-btn-container-higher">
                            <ContinueBtn 
                                message = {phoneVerification ?  "Continue" : "Set Pin"}
                                onClick={() => handleSubmit()}/>
                        </div>
                        {phoneVerification && (
                            <div 
                                onClick={() => {handleResend()}}
                                className="d-flex resend-container mt-4">
                                <img className="icon" src={process.env.PUBLIC_URL + "/Images/Icons/reload.png"}/>
                                <span className="resend-txt mx-4">Resend Code</span>
                            </div>
                        )}

                        {!phoneVerification && (
                            <div style={{height: '50px'}}/>
                        )}
                    </div>
                </div>
                {phoneVerification && (
                    <div className="already-container-signupPage w-100">
                        <AlreadyHaveAnAccount
                            text="Already have an account?"
                            textGold=" Sign In"/>
                    </div>  
                )}
            </div>
        </div>
    );
}

export default PhoneVerificationPage; 