import { SessionVars, TokenData, TokenValidation } from '../Global/components/utils';
import { ArrowInput, ContinueBtn, LargeInputWithTextOnTop, NewInput } from '../Global/components/buttons';
import {useEffect, useRef, useState} from 'react';
import { Header, HelpPw } from '../Global/components/reusable';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './security.css';

export function SecurityPage(){
    const [height, setHeight] = useState();
    
    const history = useHistory();
    const ref = useRef();
    const ref2 = useRef();

    function HandleResize(){
        setHeight(window.innerHeight - ref.current.offsetTop - ref2.current.offsetTop - 30);
    }

    useEffect(() => {
        const timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);
        return () => { window.removeEventListener("resize", HandleResize); clearTimeout(timeout)}
    }, [])

    return(
        <div ref={ref2} className="securirtypage-container">
            <Header text="Security"/>

            <div ref={ref} style={{height: height}} className="security-fields-container">
                <ArrowInput
                    handlePageChange={() => history.push("/dashboard/security/password")}
                    name="Password"/>
                </div>
            </div>
            
    );
}

export function PasswordPage(){
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [resetPassword, setResetPassword] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [minWidth, setMinWidth] = useState("152px");
    const [errorTimer, setErrorTimer] = useState();
    const [messageTimer, setMessageTimer] = useState();
    const [height, setHeight] = useState();

    const history = useHistory();
    const ref = useRef();
    const ref2 = useRef();

    function HandleResize(){
        setHeight(window.innerHeight - ref2.current.offsetTop - 20);
        if(window.innerWidth < 760){
            setMinWidth("127px");    
        }
        else{
            setMinWidth("152px");
        }
    }

    useEffect(() => {
        setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);

        return () => window.removeEventListener("resize", HandleResize);
    }, [])
    
    useEffect(() => {
        if(error.length > 0){
            clearTimeout(errorTimer);
            setErrorTimer(setTimeout(() => {
                setError("");
            }, 5000))
        }
    }, [error])

    useEffect(() => {
        if(message.length > 0){
            clearTimeout(messageTimer);
            setMessageTimer(setTimeout(() => {
                setMessage("");
            }, 5000))
        }
    }, [message])

    function ValidatePassword() {
        if(newPassword.length < 6){
            setError("Your password needs to be at least 6 characters long")
            clearTimeout(messageTimer);
            setMessage("");
            return false;
        }

        if(!/[0-9]+/.test(newPassword) || !/[A-Z]+/.test(newPassword)){
            setError("Your password needs to contain at least one capital letter and one number");
            clearTimeout(messageTimer);
            setMessage("");
            return false;
        }
        
        if(!/[!@#$%^&£*?~`';()[\]]/.test(newPassword)){
            setError("Your password needs to contain at least one special character(!@#$%^&*?£()[]~`';)");
            clearTimeout(messageTimer);
            setMessage("");
            return false;
        }
        
        if(newPassword != resetPassword){
            setError("Your passwords don't match");
            clearTimeout(messageTimer);
            setMessage("");
            return false;
        }  

        setError("");
        clearTimeout(errorTimer);
        return true;
    }

    async function savePassword(){
        if(ValidatePassword()){
            if(await TokenValidation()){
                var data = JSON.stringify({
                    "newPassword": `${newPassword}`,
                    "currentPassword": `${password}`
                  });
                  
                  var config = {
                    method: 'post',
                    url: 'https://api.banking.bss-lab.it/api/identity/my-profile/change-password',
                    headers: { 
                      'Content-Type': 'application/json', 
                      'Authorization': `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(res => {
                    setMessage("Password changed successfuly");
                    localStorage.setItem(SessionVars.password, null);
                  })
                  .catch(err =>{
                    setError("Invalid current password");
                  });
            }
            else{
                history.replace("/pinCheck");
            }
        }
    }

    return(
        <div ref={ref} className="change-page-container">
            <div className="header-backimg-container">
                <img
                    onClick = {() => history.push("/dashboard/security")} 
                    style={{cursor: "pointer", marginRight: 10}}
                    width = "26"
                    src={process.env.PUBLIC_URL + "/Images/Icons/Back\ arrow.png"}/>
                <Header text="Password"/>
            </div>
            <div ref={ref2} style={{height: height}} className="password-container-security">
                <div className="current-password">
                    <LargeInputWithTextOnTop
                        upText="Current password"
                        autoComplete="new-password"
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Please write your current password"/>
                </div>

                <div  className="new-password">
                    <LargeInputWithTextOnTop
                        upText="New password"
                        autoComplete="new-password"
                        type="password"
                        minWidth={minWidth}
                        onChange={e => setNewPassword(e.target.value)}
                        placeholder="Please write your new password"
                        helperTag={true}/>
                </div>

                <div  className="retype-password">
                    <LargeInputWithTextOnTop
                        upText="Confirm password"
                        autoComplete="new-password"
                        type="password"
                        onChange={e => setResetPassword(e.target.value)}
                        placeholder="Please confirm your password"/>
                </div>
                { error.length > 0 &&
                    <span className="error-message" style={{height: '20px', marginBottom: '10px'}}>{error}</span>
                }
                {error.length === 0 &&
                    <span className="success-message" style={{height: '20px', marginBottom: '10px'}}>{message}</span>
                }
                <div className="save-changes-btn">
                    <ContinueBtn
                        onClick={() => savePassword()}
                        message="Save"/>
                </div>
                
            </div>
        </div>
    );
}