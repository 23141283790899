import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from '../locale/en.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: enTranslations
    },
    fallbackLng: "en",
    interpolation: { escapeValue: false }
  }, undefined);

const t = (text:any, options: any | undefined = undefined) => i18n.t<string>(text, options);
export default t;