import LeftSide from "../Global/components/leftSide";
import { AlreadyHaveAnAccount, CustomCheckBox, ImageRight, MiniLogo, PageTitle } from "../Global/components/reusable";
import {BackAndContinueBtns} from '../Global/components/buttons';
import "./terms&conditions.css"
import {Scrollbars} from 'react-custom-scrollbars';
import {useState} from 'react';
import { SessionVars } from "../Global/components/utils";
import t from '../services/translations';

export default function TermsConditionsPage(){
    return(
        <div className="container-fluid row main m-0 p-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

function RightSide(){
    const [tAndCCheck, setTAndCCheck] = useState(false);
    const [promotionsCheck1, setPromotionsCheck1] = useState(false);
    const [promotionsCheck2, setPromotionsCheck2] = useState(false);

    const continueHandler = () => {
        sessionStorage.setItem(SessionVars.hasAgreedTerms, tAndCCheck);
        sessionStorage.setItem(SessionVars.hasAgreedTermsPrivacy, promotionsCheck1);
        sessionStorage.setItem(SessionVars.hasAgreedMarketing, promotionsCheck2);
        window.open("/formName", "_self");
    }

    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row">
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>

                    <div className="page-title-container">
                        <PageTitle
                            title="Terms and Conditions"
                            message="Agreement"/>
                    </div>
                </div>

                <div className="terms-cond-container mb-2">
                    <Scrollbars
                    renderTrackVertical={props => <div {...props} className="track-vertical2"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical2"/>}
                    style={{height: 366, width: '100%'}}>
                        <div className="txt">
                            <span>
                                Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.

                                1. YOUR AGREEMENT
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.

                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.

                                2. PRIVACY
                                Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.

                                Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.

                                1. YOUR AGREEMENT
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.

                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.

                                2. PRIVACY
                                Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.

                                Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.

                                1. YOUR AGREEMENT
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.

                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.

                                2. PRIVACY
                                Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.
                                <br/><br/>
                                
                                <a href="https://www.google.com" target='_blank'>Terms and Conditions</a>
                            </span>

                            <div className="checkbox-container">
                                <div className="checkbox mb-2">
                                    <CustomCheckBox isActive={tAndCCheck} setIsActive={setTAndCCheck}/> 
                                    <span className="message">I agree to the <strong>Terms of Services</strong> and <strong>Privacy Policy</strong></span>
                                </div>

                                <div className="checkbox mb-2">
                                    <CustomCheckBox isActive={promotionsCheck1} setIsActive={setPromotionsCheck1}/> 
                                    <span className="message">
                                        I wish to receive news, promotions, discounts and targeted communications from {t('RepxPartner')}
                                        Wanderers Footaball Club based on my provided data, and accept the use of my personal data for marketing, profiling
                                        and analysis as outlined in the Privacy Policy
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                
                <BackAndContinueBtns
                    isDisabled={!tAndCCheck}
                    backHandler= {() => {window.open("/phoneConfirmed", "_self")}}
                    continueHandler= {() => {continueHandler()}}/>
                                    
                <div className="already-container-signupPage w-100">
                    <AlreadyHaveAnAccount
                        text="Already have an account?"
                        textGold=" Sign In"/>
                </div>
            </div>
        </div>
    );
}