import axios from 'axios';
import { useEffect, useState } from 'react';
import { Home } from '../Dashboar/dashboar';
import { ErrorWithTimer, News } from '../Global/components/reusable';
import { API, Data, SessionVars, TokenData, TokenValidation } from '../Global/components/utils';
import './notification.css';
import Offers from './offers';
import Settings from './settings';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';

function NotificationNews(){
    const [linksToDisplay, setLinksToDisplay] = useState([]);
    const [imgsToDisplay, setImgsToDisplay] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    let numberOfNews = 0;

    function LoadFromMemory(idsToFetch = []){
        let idsMemo = [];     
        let linksMemo = [];
        let imgMemo = [];
        let indexMemo = [];


        if(sessionStorage.getItem(Data.newsImg) !== null){
            idsMemo = sessionStorage.getItem(Data.newsId).split(',');        
            linksMemo = sessionStorage.getItem(Data.newsLink).split(',');
            imgMemo = sessionStorage.getItem(Data.newsImg).split(',');
        }

        idsMemo.forEach((id, index) => {
            if(idsToFetch.includes(id)){
                indexMemo.push(index);
            }
        })
        if(indexMemo.length === numberOfNews){
            //ALL DATA IS ALREADY LOCALY SAVED
            console.log("ALL DATA IS ALREADY SAVED");
        }
        else{

            idsMemo = idsMemo.filter((id, index) => indexMemo.includes(index));
            linksMemo = linksMemo.filter((id, index) => indexMemo.includes(index));
            imgMemo = imgMemo.filter((id, index) => indexMemo.includes(index));
            
            idsToFetch = idsToFetch.filter(id => !idsMemo.includes(id));
            
            FetchRestAndDisplay(idsMemo, linksMemo, imgMemo, idsToFetch);
        }
    }

    function FetchRestAndDisplay(idsMemo, linksMemo, imgMemo, idsToFetch){
        let ids = idsMemo;
        let links = linksMemo;
        let imgs = imgMemo;

        idsToFetch.forEach(id => {
            let url = API.newsUrl + "/" + id;
            axios.get(url, {
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then( res => {
                ids.push(id);
                links.push(res.data.redirectUrl);
                imgs.push(res.data.imageBase64);

                if(imgs.length === numberOfNews){
                    setLinksToDisplay(links);
                    setImgsToDisplay(imgs);
                }
            })
        })
    }

    useEffect(async () => {
        let idsFetched = [];
        setLoading(true);
        if(await TokenValidation()){
            axios.get(API.newsUrl, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                numberOfNews = res.data.totalCount;
                res.data.items.forEach(element => {
                    idsFetched.push(element.id);
                });;
                // LoadFromMemory(idsFetched);
                let imgs = [];
                let links = [];
                idsFetched.forEach(id => {
                    let url = API.newsUrl + "/" + id; 
                    axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                        }
                    })
                    .then( res => {
                        imgs.push(res.data.imageBase64);
                        links.push(res.data.redirectUrl);
                        setLoading(false);
                        if(imgs.length === numberOfNews){
                            setImgsToDisplay(imgs);
                            setLinksToDisplay(links);
                        }
                    })
                    .catch(err => {
                        console.log(`ERROR loaing banner with id:${id}`, err.response);
                        setError("Not all banners loaded successfuly");
                        setImgsToDisplay(imgs);
                        setLinksToDisplay(links);
                    })
                })
            })
            .catch(err => {
                console.log("ERROR getting the news\n", err.response);
                if(err.response.status >= 500){
                    setError("Internal server error loading the news");
                }
                else{
                    setError("Error loading the news");
                }
                setLoading(false);
            })
        }
        else{
            history.replace("/");
            setLoading(false);
        }
        

    }, [])

    return(
        <div className="news-page-container">
            {loading && (
                <div style={{position: 'absolute'}}>
                    <Loader type="ThreeDots" color="var(--main-color)" height="100" width="100" />
                </div>
            )}
            <span className='error-message'>{error}</span>
            {imgsToDisplay.map((img, i) => {
                return(
                    <News key={i} img={img} link={linksToDisplay[i]}/>
                );
            })}
        </div>
    );
}


export function NotificationPage(){
    const history = useHistory();
   
    return(
        <div className="notification-page-container">
            <div className="actual-notifications">
            <div className="header mb-3">
                <span className="notification-title">Latest News</span>
                <div className="header-links">
                    <div className="links">
                        <button onClick={() => history.push("/news")}><span>News</span></button>
                        <button onClick={() => history.push("/offers")} className="mx-1"><span>Offers</span></button>
                    </div>
                    <img
                        src={process.env.PUBLIC_URL + "/Images/Icons/Settings.png"} 
                        onClick={() => history.push("/settings")} 
                        className="settings-icon"/>
                </div>
            </div>
               <NotificationNews/>
            </div>
        </div>
    );
}

export function OffersPage(){
    const history = useHistory();
    
    return(
        <div className="notification-page-container">
            <div className="actual-notifications">
            <div className="header mb-3">
                <span className="notification-title">Latest News</span>
                <div className="header-links">
                    <div className="links">
                        <button onClick={() => history.push("/news")}><span>News</span></button>
                        <button onClick={() => history.push("/offers")} className="mx-1"><span>Offers</span></button>
                    </div>
                    <img
                        src={process.env.PUBLIC_URL + "/Images/Icons/Settings.png"} 
                        onClick={() => history.push("/settings")} 
                        className="settings-icon"/>
                </div>
            </div>
               <Offers/>
            </div>
        </div>
    );
}

export function SettingsPageNotification(){
    const history = useHistory();

    return(
        <div className="notification-page-container">
            <div className="actual-notifications">
                <div className="header mb-3">
                    <span className="notification-title">Latest News</span>
                    <div className="header-links">
                        <div className="links">
                            <button onClick={() => history.push("/news")}><span>News</span></button>
                            <button onClick={() => history.push("/offers")} className="mx-1"><span>Offers</span></button>
                        </div>
                        <img
                            src={process.env.PUBLIC_URL + "/Images/Icons/Settings.png"} 
                            onClick={() => history.push("/settings")} 
                            className="settings-icon"/>
                    </div>
                </div>

               <Settings/>
            </div>
        </div>
    );
}