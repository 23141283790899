import axios from 'axios';
import { ContinueBtn } from '../Global/components/buttons';
import { MiniLogo, PageTitle } from '../Global/components/reusable';
import { SessionVars, API, TokenData, TokenValidation, Data } from '../Global/components/utils';
import { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import './pinCheck.css';
import { askForPermissioToReceiveNotifications } from '../Firebase/push-notification';

export default function PinCheckPage(props){
    const [errorId, setErrorId] = useState(null);
    const [error, setError] = useState("");
    const [strongText, setStrongText] = useState("");
    const [nextPage, setNextPage] = useState("");
    const [isReturningUser, setIsReturningUser] = useState(false);

    const history = useHistory();
    let auxCodeArr = [0, 0, 0, 0, 0, 0];

    const HandleFocus = (props) =>{
        if(props !== null)
            props.currentTarget.setAttribute("placeholder", "");
    }

    const BlurFnc = (props) => {
        if(!props.currentTarget.value)
            props.currentTarget.setAttribute("placeholder", "__");
    }
    
    function ClearInputs(){
        let currentIndex = 1;
        while(currentIndex < 7){
            let nextfield = document.querySelector( `input[name=code${currentIndex}]`);
            if(nextfield !== null){
                nextfield.placeholder = "__";
                nextfield.value = null;
                nextfield.placeholder = "__";
                nextfield.classList.remove("code-completed");
                currentIndex++;
            }
        }
    }

    const HandleChange = (props) => {
        var currentIndex = parseInt(props.currentTarget.name.substring(4));

        let nextfield;
        if(props.currentTarget.value.length > 0){
            props.currentTarget.classList.add("code-completed");
            nextfield = document.querySelector( `input[name=code${currentIndex + 1}]`);
        }
        else{
            props.currentTarget.classList.remove("code-completed");
            nextfield = document.querySelector( `input[name=code${currentIndex - 1}]`);
        }

        if(props.currentTarget.value.length >= 2){
            props.currentTarget.value = props.currentTarget.value.substring(1,2);
        }
        auxCodeArr[currentIndex - 1] = props.currentTarget.value;

        if(nextfield != null){
            nextfield.focus();
        }
    }

    function kycStatus(token){
        axios.get(API.kycStatusUrl, {
            headers:{
                Authorization : `Bearer ${token}`
            }
        })
        .then(res => {
            if(res.data.status === "Approved"){                
                const url = new URLSearchParams(window.location.search);
                const redirectUrl = url.get("custom_redirect");
                if(redirectUrl != null){
                    setNextPage("/transactions");
                    localStorage.setItem(Data.redirectUrl, null);
                }
                else{
                    setNextPage("/home");
                }
            }
            else if (res.data.status === "Pending"){
                setNextPage("/signDone");
            }
            else{
                setNextPage("/kyc");
            }
        })
        .catch(err => {
            console.log("Error checking KYC status\n", err.response);
        })
    }

    function ClearPreviousTimer(){
        clearTimeout(errorId);

        if(error.length > 0){
            setErrorId(setTimeout(() => {
                setError("");
            }, 5000))
        }
    }

    async function login(email, password){
        var qs = require('qs');
        var data = qs.stringify({
            'grant_type': 'password',
            'username': email,
            'password': password,
            'scope': 'offline_access email BankingMiddleware openid',
            'client_id': 'BankingMiddleware_App',
            'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
        });
        
        var config = {
            method: 'post',
            url: API.connectTokenUrl,
            headers: { 
                'content-type': 'application/x-www-form-urlencoded',
                'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
            },
            data : data
        };

        axios(config)
        .then(res => {
            const date = new Date();
            sessionStorage.setItem(TokenData.token, res.data.access_token);
            sessionStorage.setItem(TokenData.refreshToken, res.data.refresh_token);
            sessionStorage.setItem(TokenData.createdAt, date.getTime());
            sessionStorage.setItem(TokenData.tokenLifespan, res.data.expires_in);
            sessionStorage.setItem(Data.numberOfFailedPin, 2);
            kycStatus(res.data.access_token);
        })
        .catch(err => {
            console.log("ERROR logging the user\n", err.response);
            if(err.response.data.error_description === "Invalid username or password!"){
                if(sessionStorage.getItem(Data.numberOfFailedPin) === null){
                    sessionStorage.setItem(Data.numberOfFailedPin, 2);
                }
                var noTries = sessionStorage.getItem(Data.numberOfFailedPin);
                setError(`Invalid pin! You may try (${noTries}) times`);
                sessionStorage.setItem(Data.numberOfFailedPin, noTries - 1);
            }
            else if(err.response.data.error_description){
                setError(err.response.data.error_description);
                ClearPreviousTimer();
            }
            else{
                setError("Server failed to respond");
                ClearPreviousTimer();
            }
        })
    }

    const handelSubmitV2 = async () => {
        let pin = "";
        auxCodeArr.map(item => pin += item);
        var SHA256 = require("js-sha256").sha256;
        let encryptedPin = SHA256(pin);

        let email = "";
        let password  = "";

        if(props.location.state !== undefined){
            if(await TokenValidation()){
                email = props.location.state.email;
                password = props.location.state.password;
                axios.post(API.validatePinUrl, {}, {
                    params:{
                        userPin: encryptedPin
                    },
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    const cryptojs = require("crypto-js");
                    var ciphertext = cryptojs.AES.encrypt(password, pin).toString();
                    var ciphertextToBase64 = cryptojs.enc.Base64.stringify(cryptojs.enc.Utf8.parse(ciphertext));
                    localStorage.setItem(SessionVars.password, ciphertextToBase64);
                    localStorage.setItem(SessionVars.appName, email);
                    login(email, password);
                })
                .catch(err => {
                    console.log("ERROR validating pin\n", err.response);
                    if(err.response.data.error_description){
                        setError(err.response.data.error_description);
                    }
                    else if(err.response.data.error != undefined){
                        setError(err.response.data.error.message);
                    }
                    else{
                        setError("Server failed to respond");
                    }
                    ClearPreviousTimer();
                })
            }
            else{
                history.replace("/signin");
                return;
            }  
        }
        else{
            try{

                email = localStorage.getItem(SessionVars.appName);
                const cryptojs = require("crypto-js");
                var ciphertextToBase64 = localStorage.getItem(SessionVars.password);
                var ciphertext = cryptojs.enc.Base64.parse(ciphertextToBase64).toString(cryptojs.enc.Utf8);
                var bytes = cryptojs.AES.decrypt(ciphertext, pin);
                password = bytes.toString(cryptojs.enc.Utf8);
                login(email, password);
            }
            catch{
                history.replace("/signIn");
            }
        }
        ClearInputs();
    }

    function SwitchAccount(){
        localStorage.clear();
        sessionStorage.clear();
        history.replace("/app");
    }
    
    useEffect(async () => {
        //CHECK IF LOGIN IS POSSIBLE
        if(localStorage.getItem(SessionVars.password) === null && props.location.state === undefined){
            history.replace("/signin");
        }

        document.getElementsByTagName("html")[0].style.backgroundColor = "var(--bg-alt2-color)";

        if(nextPage != ""){
            var token = await askForPermissioToReceiveNotifications();
            localStorage.setItem(TokenData.notificationToken, token);
            axios.post(API.registerPushNotifications, {
                token: token,
                device: "Web",
                provider: "Firebase"
            }, 
            {
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                console.log(localStorage.getItem(TokenData.notificationToken));
            })
            .catch(err => {
                console.log("ERROR registering to notification\n", err.response);
            })
            
            document.getElementById("next").click();
        }
    }, [nextPage])


    function checkIfUserLoggedWithEmailPw(){
        setIsReturningUser(props.location.state === undefined);
    }

    useEffect(() => {
        checkIfUserLoggedWithEmailPw();

        axios.get(API.cardCost)
        .then(res => {
            sessionStorage.setItem(SessionVars.cardPrice, res.data.cost);
            sessionStorage.setItem(SessionVars.currencyType, res.data.currency);
        })
        .catch(err => console.log("ERROR getting card cost\n", err.response))
        
        if(localStorage.getItem(SessionVars.password) == "null" && props.location.state === undefined){
            history.replace("/app")
        }
        
        if(localStorage.getItem(SessionVars.appName) != undefined){
            setStrongText(localStorage.getItem(SessionVars.appName))
        }
        else{
            setStrongText(props.location.email);
        }
    }, [])

    return(
        <div className="pin-check-container">
            <div className="d-flex flex-lg-column flex-column align-items-center justify-content-center">
                        <div>
                            <MiniLogo/>
                        </div>

                        <div className="page-title-container">
                            <div style={{textAlign: 'center'}}>
                                {isReturningUser == true&& (
                                    <div style={{fontSize: '34px', fontFamily: 'AppFont', color: 'grey'}}>
                                        Welcome back <strong style={{color: '#000'}}>
                                            {localStorage.getItem(SessionVars.name)}
                                        </strong>
                                    </div>
                                )}

                                <div style={{fontFamily:'AppFontBold', fontSize: '26px'}}>Please insert you PIN</div>
                            </div>

{/*                            
                            <PageTitle
                                center={true}
                                title={"Welcome back"}
                                message="Please enter your pin"
                                strong={strongText}/> */}
                        </div>
                    </div>
            <div className="d-flex flex-column justify-content-center w-75">
                <div className="code-container d-flex col-xl-10 col-lg-12 col-md-10 col-12 align-self-center justify-content-between">
                    
                    <input name="code1" 
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next5" placeholder="__"/>

                    <input name="code2" 
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next4" placeholder="__"/>

                    <input name="code3" 
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next3" placeholder="__"/>
                    
                    <input name="code4" 
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)}
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next2" placeholder="__"/>

                    <input name="code5" 
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted next1" placeholder="__"/>

                    <input name="code6" 
                        onFocus={HandleFocus.bind(this)} 
                        onBlur={BlurFnc.bind(this)} 
                        onChange={HandleChange.bind(this)} 
                        className="code-incompleted" placeholder="__"/>
                    </div>
                <div className="d-flex flex-column w-100" style={{height: '24px'}}>
                    <span className="error-message align-self-center mt-3">{error}</span>
                </div>
            </div>
            
            <Link style={{position: 'absolute'}} id="next" to={nextPage}/>
            <div onClick={() => handelSubmitV2()} className="continue-btn">
                <ContinueBtn/>
                </div>
            <div className="d-flex flex-column align-items-center">
                
            <div className="switch-text">
                <span>This is not your account?</span>
                <span className="logout" onClick={() => SwitchAccount()}>Log out</span>
            </div>
            </div>
        </div>
    );
}