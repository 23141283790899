import LeftSide from '../Global/components/leftSide';
import {AlreadyHaveAnAccount, MiniLogo, PageTitle } from '../Global/components/reusable'
import {BackAndContinueBtns, LargeInputWithTextOnTop, SmallDoubleInputsWithTextOnTop} from '../Global/components/buttons';
import {useEffect, useState} from 'react';
import "./formName.css"
import { SessionVars } from '../Global/components/utils';
import { DropdownBirthDaySignUp } from '../Global/components/dropdown';
import t from '../services/translations';

export default function FormNamePage(){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

const daysInMonth = [31, 28, 31, 30, 31, 30, 31 ,31, 30, 31, 30 ,31];
const monthsNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12];
const days = [];

for(let i = 1; i<=31; i++){
    days.push(i);
}

const date = new Date();

function RightSide() {
    const [firstNameError, setFirstNameError] = useState("");
    const [sureNameError, setSureNameError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [firstName, setFirstName] = useState("");
    const [sureName, setSureName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [error, setError] = useState("");
    const [infoPopup, setInfoPopup] = useState(false);
    const [years, setYears] = useState([]);
    
    const [daySelected, setDaySelected] = useState(null);
    const [monthSelected, setMonthSelected] = useState(null);
    const [yearSelected, setYearSelected] = useState(null);

    function continueHandler(){
        if(/[^0-9]/.test(phoneNumber)){
            setPhoneNumberError("Invalid phone number");
        }
        else if(firstName.length > 64){
            setFirstNameError("First name needs to be shorter than 64 characters");
        }
        else if(sureName.length > 64){
            setSureNameError("Surename needs to be shorter than 64 characters");
        }
        else{
            setPhoneNumberError("");
            sessionStorage.setItem(SessionVars.dateOfBirth, dateOfBirth);
            sessionStorage.setItem(SessionVars.firstName, firstName);
            sessionStorage.setItem(SessionVars.surName, sureName);
            sessionStorage.setItem(SessionVars.phoneNumber, phoneNumber);
            window.open("/formRegion", "_self");
        }
    }

    function backHandler(){
        window.open("/termsconditions", "_self");
    }

    function updateBirthdayOptions(){
        if(years.length == 0){
            const thisYear = date.getFullYear();
            let auxArr = [];
            for(let i = 17; i <= 130; i++){
                auxArr.push(thisYear - i);
            }
            setYears(auxArr);
        }
    }

    useEffect(() => {
        const date = new Date();

        if(dateOfBirth !== null){
            const birthYear = dateOfBirth.split("/")[2];
            const birthMonth = dateOfBirth.split("/")[1];
            const birthDay = dateOfBirth.split("/")[0];
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            console.log(year, birthYear);
            console.log(month, birthMonth);
            console.log(day, birthDay);

            if(year - birthYear > 18){
                setError("");
                return;
            }
            
            if(year - birthYear < 18){
                setError("You need at least 18 years old");
                return;
            }
            
            if(year - birthYear === 18){
                if(month - birthMonth > 0){
                    setError("");
                    return;
                }

                if(month - birthMonth < 0){
                    setError("You need at least 18 years old");
                    return;
                }

                if(month - birthMonth === 0){
                    if(day - birthDay >= 0){
                        setError("");
                        return;
                    }

                    if(day - birthDay < 0){
                        setError("You need at least 18 years old");
                        return;
                    }
                }
            }

            console.log(date.getFullYear(), date.getMonth() + 1, date.getDate());
        }
           
    }, [dateOfBirth])

    useEffect(() => {
        updateBirthdayOptions();
    }, [])

    useEffect(() => {
        if(daySelected != null && monthSelected != null && yearSelected != null){
            setDateOfBirth(daySelected + '/' + monthSelected + '/' + yearSelected);
        }
	}, [daySelected, monthSelected, yearSelected])


    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row" style={{height: 'auto'}}>
            {infoPopup && (
                <>
                    <div className='signup-info-popup'>
                        <div className='header'>
                            <div className='popup-title'>
                                <img width={75} src={process.env.PUBLIC_URL + "/Images/" + t('AppLogo')}/>
                                <span>{t('AppBrand')}</span>
                            </div>
                            <button onClick={() => setInfoPopup(false)}>&times;</button>
                        </div>
                        Styling external links
                        I saw this in a good amount of products. It is a good practice to style differently the links which point to external resources. This could be easily done with the technique above. Let's say we have the following paragraph of text:
                        We could add a small icon after that link indicating that it points to page outside the current domain.
                        a 
                            text-decoration: none;
                            font-weight: bold;
                            color: var(--fg-color);

                        aafter 
                            content: "";
                            display: inline-block;
                            background: url("icon-external.jpg") no-repeat top right;
                            width: 14px;
                            height: 12px;
                    </div>
                    <div className='overlay'/>
                </>
            )}

            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                
                    <div className="page-title-container">
                        <PageTitle
                            title="Sign Up"
                            message="Let's get started!"/>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-center">
                    <p className="additional-info">
                        We need to ask a few questions and you’ll need
                        some photo ID to check you qualify for t('AppBrand') Debit Card.<img onClick={() => setInfoPopup(true)} src={process.env.PUBLIC_URL + "/Images/Icons/info.png"}/>
                    </p>

                     <div className="name-container">
                        <SmallDoubleInputsWithTextOnTop
                            upTextLeft="First Name"
                            placeholderLeft="Please enter your first name"
                            errorLeft = {firstNameError}
                            setErrorLeft = {setFirstNameError.bind(this)}
                            onChangeLeft = {(e) => {setFirstName(e.target.value);}}
                            upTextRight="Surname"
                            placeholderRight="Please enter your surname"
                            errorRight = {sureNameError}
                            setErrorRight = {setSureNameError.bind(this)}
                            onChangeRight = {(e) => {setSureName(e.target.value);}}/>
                    </div>
                    
                    <div className="birthday-container">
                        <span style={{fontFamily: 'AppFont', fontSize: '24px', lineHeight: '19px', color: 'var(--fg-alt3-color)',cursor: 'default', marginBottom: '10px'}}>
                            Date of birth
                        </span>
                        <div className='birthday-dropdowns-container'>
                            <div className='birthday-dropdown-extra-styling'>
                                <DropdownBirthDaySignUp setSelected={x=> setDaySelected(x)} options={days} headerValue='Day'/>
                            </div>

                            <div className='birthday-dropdown-extra-styling'>
                                <DropdownBirthDaySignUp setSelected={x=> setMonthSelected(x)} options={monthsNumber} headerValue='Month'/>
                            </div>

                            <div className='birthday-dropdown-extra-styling'>
                                <DropdownBirthDaySignUp setSelected={x=> setYearSelected(x)} options={years} headerValue='Year'/>
                            </div>
                        </div>
                    </div>

                    <div className="phone-container pt-2">
                        <LargeInputWithTextOnTop
                            upText="Mobile number"
                            placeholder="Please enter your mobile number"
                            onChange = {(e) => {setPhoneNumber(e.target.value);}}
                            error = {phoneNumberError}
                            setError = {setPhoneNumberError.bind(this)}/>
                    </div> 
                </div>
                   
                <div className="d-flex flex-column">
                    <div className="error-message">
                        {error}
                    </div>
                        <BackAndContinueBtns
                            backURL="/termsconditions"
                            continueURL="/formLocation"
                            isDisabled = {firstName === "" || sureName === "" || phoneNumber === "" 
                                            || dateOfBirth === null || error.length !== 0}
                            backHandler = {backHandler}
                            continueHandler = {continueHandler}/>
                    </div>
                    <div className="already-container-signupPage w-100">
                        <AlreadyHaveAnAccount
                            text="Already have an account?"
                            textGold=" Sign In"/>
                    </div>
                
            </div>
        </div>
    );
}