import React, { useEffect, useState, useRef } from "react";
import '../css/Datepicker.css';
import Scrollbars from "react-custom-scrollbars";
import DatePicker, { Calendar } from 'react-modern-calendar-datepicker';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Popup } from "./reusable";
import { monthsName } from './utils.js';

const date = new Date();

function IsLeapYear(year){
	return year % 4 === 0 ? true : false;
}

function MoreThanTwoYears(range){
	const noMillsecInDay = 1000 * 3600 * 24;
	const date1 = new Date(range[0].selectedYear + '/' + range[0].selectedMonth + '/' + range[0].selectedDay);
	const date2 = new Date(range[1].selectedYear + '/' + range[1].selectedMonth + '/' + range[1].selectedDay); 
	let noDayMax = 730;
	let i = range[0].selectedYear;

	while(i <= range[1].selectedYear){
		if(i % 4 === 0)
			noDayMax++;
		i++;
	}
	const diff = date2 - date1;

	if(diff / noMillsecInDay > noDayMax){
		return true;
	}
	return false;
}

export function DatePickerBody({isRanged = false, bodyTop="0px", bodyRight = "unset", bodyLeft="unset", range = [], onSelected = () => {},
								setRange = () => {}, refPassed, setIsPickerOpen = () => {}, setIsDDOpen = () => {}}){

	const [selectedDay, setSelectedDay] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(date.toLocaleString('en', {month: 'long'}));
	const [monthIndex, setMonthIndex] = useState(date.getMonth());
	const [selectedYear, setSelectedYear] = useState(date.getFullYear());
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedDateInput, setSelectedDateInput] = useState(null);
	const [rangeDay, setRangeDay] = useState(null);
	const [rangeMonth, setRangeMonth] = useState(null);
	const [rangeYear, setRangeYear] = useState(null); 
	const [days, setDays] = useState([]);
	const [isDDYearOpen, setIsDDYearOpen] = useState(false);
	const [isDDMonthOpen, setIsDDMonthOpen] = useState(false);
	const [isManulDateOpen, setIsManulDateOpen] = useState(true);
	const [moreThanTwoYearsPopup, setMoreThanTwoYearsPopup] = useState(false);
	const [infoPopup, setInfoPopup] = useState(false);

	const years = [];
	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	const noDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
	const daysName = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
	
	let wrapperRef;

	function useOutsideAlerter() {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			wrapperRef = document.getElementById("body");
			function handleClickOutside(event) {
				if (wrapperRef && !wrapperRef.contains(event.target)) {
					setIsPickerOpen(false);
					setIsDDYearOpen(false);
					setIsDDMonthOpen(false);
					setIsManulDateOpen(false);
				}
			}

			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, []);
	}

    useOutsideAlerter(wrapperRef);

	for(let i = date.getFullYear(); i >= 1900; i--){
        years.push(i);
    }

	//UPDATING THE DISPLAYED DATE 
	useEffect(() => {
		if(selectedYear && selectedMonth && selectedDay){
			let auxSelectedDay = selectedDay

			if(auxSelectedDay[auxSelectedDay.length - 1] == "c"){
				auxSelectedDay =  auxSelectedDay.substring(0, auxSelectedDay.length - 1);
			}
			const date1 = new Date(selectedYear, monthsName.indexOf(selectedMonth), selectedDay);
			const today = new Date();
			let aux;
			console.log(date1, today, date1 <= today);
			if(date1 <= today){
				aux = new Date(selectedMonth + ' ' + auxSelectedDay + ',' + selectedYear + ' 00:00:01');
			}
			else {
				setSelectedDay(today.getDate())
				setSelectedMonth(monthsName[today.getMonth()]);
				setSelectedYear(today.getFullYear());
				setInfoPopup(true);
				return;
			}
			
			setSelectedDate(aux.toLocaleString('en', {weekday : 'short'}) + ', ' 
						  + aux.toLocaleString('en', {month : 'short'}) + ' ' + auxSelectedDay);

			const actualMonthNo = months.indexOf(selectedMonth) + 1;
			const monthNoToDisplay = actualMonthNo < 10 ? '0' + actualMonthNo.toString() : actualMonthNo;
			setSelectedDateInput(auxSelectedDay + '/' + monthNoToDisplay + '/' + selectedYear);
			
			setRangeDay(selectedDay);
			setRangeMonth(selectedMonth);
			setRangeYear(selectedYear);
		}
	},[selectedDay])

	//SENDING THE SELECTED DATE
	useEffect( () => {
		onSelected(selectedDateInput);
	}, [selectedDate]) 

	//UPDATING THE RANGE
	useEffect( () => {
		if(rangeDay && rangeMonth && rangeYear){
			let auxRangeDay = rangeDay;

			if(auxRangeDay[auxRangeDay.length - 1] == "c"){
				auxRangeDay =  auxRangeDay.substring(0, auxRangeDay.length - 1);
			}

			if(range.length === 2){
				setRange([{selectedDay: auxRangeDay,
							selectedMonth: rangeMonth,
							selectedYear: rangeYear}]);
			}

			else if(range.length >= 1){
				const date1 = new Date(range[0].selectedYear, months.indexOf(range[0].selectedMonth), range[0].selectedDay, 0, 0);
				const date2 = new Date(selectedYear, months.indexOf(selectedMonth), selectedDay, 0, 0);

				if(date1 != date2){
					if(date1 < date2)
						setRange([...range,  {selectedDay: auxRangeDay,selectedMonth: rangeMonth,selectedYear: rangeYear}]);
					else
						setRange([{selectedDay: auxRangeDay,selectedMonth: rangeMonth,selectedYear: rangeYear}, ...range]);
				}
			}
			else{
				setRange([...range,  {selectedDay: auxRangeDay,selectedMonth: rangeMonth,selectedYear: rangeYear}]);
			}
		}
	}, [rangeDay])

	useEffect(() => {
		if(range.length > 2){
			setRange(range.slice(2));
		}

		if(range.length === 2){
			if(MoreThanTwoYears(range)){
				setRange([
					{
						selectedDay: "1",
						selectedMonth: "January",
						selectedYear: date.getFullYear()
					},
					{
						selectedDay: "31",
						selectedMonth: "December",
						selectedYear: date.getFullYear()
					}	
				]);
				
				setMoreThanTwoYearsPopup(true);
				setSelectedDate("");
				return;
			}
		}

		if(isRanged){
			if(range.length === 1)
			{
				let month0 = months.indexOf(range[0].selectedMonth) + 1;
				let day = range[0].selectedDay;

				if(month0 < 10){
					month0 = "0" + month0
				}

				if(day < 10){
					day = "0" + day;
				}

				setSelectedDateInput(day + '/' + month0 + '/' + range[0].selectedYear + ' - ' +
				'DD/MM/YYYY');
			}
			else if(range.length === 2){
				let month0 = months.indexOf(range[0].selectedMonth) + 1;
				let month1 = months.indexOf(range[1].selectedMonth) + 1;
				let day0 = range[0].selectedDay;
				let day1 = range[1].selectedDay;

				if(month0 < 10){
					month0 = "0" + month0;
				}
				
				if(day0 < 10){
					day0 = "0" + day0;
				}

				if(month1 < 10){
					month1 = "0" + month1;
				}

				if(day1 < 10){
					day1 = "0" + day1;
				}

				setSelectedDateInput(day0 + '/' + month0 + '/' + range[0].selectedYear + ' - ' +
									 day1 + '/' + month1 + '/' + range[1].selectedYear);
			}
		}
	}, [range])

	//CHECKING IF LEAP YEAR WHEN NAVIGATING THRU CALENDAR
	useEffect(() => {
		const aux = new Date(selectedMonth + ' ' + '1' + ',' + selectedYear + ' 00:00:01');
	
		let i = 0;
		const auxDays = []

		while(i < aux.getDay()){
			auxDays.push(' ');
			i++;
		}
		i = 1;
		let currentNoDay = noDaysInMonth[months.indexOf(selectedMonth)];
		if(selectedMonth === "February" && IsLeapYear(selectedYear)) currentNoDay++;
		
		while(i <= currentNoDay){
			auxDays.push(i);
			i++
		}

		setDays(auxDays);
	}, [selectedMonth, selectedYear])

	const toggleDDYear = () => {
		if(isDDMonthOpen) setIsDDMonthOpen(false);
		else setIsDDYearOpen(!isDDYearOpen);
	}

	const isNext = months.indexOf(selectedMonth) >= date.getMonth() &&
				   selectedYear >= date.getFullYear() ? "none" : "";
	
	function ClearData() {
		setSelectedDay(null);
		setSelectedMonth(date.toLocaleDateString('en', {month: 'long'}));
		setSelectedYear(date.getFullYear());
		setSelectedDate(null);
		setSelectedDateInput(null);
		setIsDDOpen(false);
		setIsPickerOpen(false);
		setIsDDMonthOpen(false);
		setIsDDYearOpen(false);
		setIsManulDateOpen(false);
		setRange([
			{
				selectedDay: "1",
				selectedMonth: "January",
				selectedYear: date.getFullYear()
			},
			{
				selectedDay: "31",
				selectedMonth: "December",
				selectedYear: date.getFullYear()
			}
		])
	}

	function OkBtn(){
		setIsPickerOpen(false);
		setIsDDOpen(false);
		
	}

	function onSelectedYear(props){
		setSelectedYear(props.target.innerHTML);
		setIsDDYearOpen(false);
		setIsDDMonthOpen(true);
	}

	function onSelectedMonth(props){
		setSelectedMonth(props.target.innerHTML);
		setMonthIndex(months.indexOf(props.target.innerHTML));
		setIsDDMonthOpen(false);
	}

	function onSelectedDay(props){
		const value = props.target.innerHTML;
		let dayAux = "";
		
		if(value.length < 3){
			dayAux = value.substring(0, 2);
		}
		else{
			const aux = value.substring(6,8);
			dayAux = aux[1] === '<' ? aux[0] : aux;
		}
		
		if(rangeDay === dayAux){
			dayAux += "c";
		}		
		
		setSelectedDay(dayAux)
	}

	function onNext(){
		let aux = monthIndex + 1;

		if(aux > 11) {
			aux = 0;
			const yearAux = parseInt(selectedYear) + 1;
			setSelectedYear(parseInt(yearAux));
		}
		
		setMonthIndex(aux);
		setSelectedMonth(months[aux]);
	}

	function onPrev(){
		let aux = monthIndex - 1;

		if(aux < 0) {
			aux = 11;
			const yearAux = parseInt(selectedYear) - 1;
			setSelectedYear(parseInt(yearAux));
		}

		setMonthIndex(aux);
		setSelectedMonth(months[aux]);
	}

	function handleManulDate(props){
		const value = props.currentTarget.value.substring(0, 10);
		if(value.length === 10){
			props.currentTarget.blur();
			setSelectedDay(parseInt(value.substring(0,2)));
			setSelectedMonth(months[parseInt(value.substring(3,5)) - 1]);
			setSelectedYear(parseInt(value.substring(6,10)));
			props.currentTarget.value = value; 
		}
	}

	function openManualDate(){
		setIsManulDateOpen(!isManulDateOpen); 
	}	

	let selectedDateStyle = isRanged ? "selected-date-text ranged-header-text" : "selected-date-text";
	let selectedDateText = isRanged ? selectedDateInput : selectedDate
	let headerStyle = isRanged ? "headerRanged" : "header"
	let calendarStyle = isRanged ? "calendar calendarRanged" : "calendar"
	let bodyStyle = isRanged ? "body bodyRanged" : "body"
 	let manualContainerStyle = isManulDateOpen ? "manual-container manual-container-open" : "manual-container manual-container-close";
	let selectableDay = "flex";
	let visibility = "visible";
	let backgroundColor = "transparent";

	return(
		<div id="body" ref={refPassed}>
			{moreThanTwoYearsPopup && (
				<Popup
					closeAction={() => setMoreThanTwoYearsPopup(false)}
					hasActions={false}
					message="The oldest transaction you can select is 2 years in the past from todays date"/>
			)}

			{infoPopup && (
				<Popup
					closeAction={() => setInfoPopup(false)}
					hasActions={false}
					message="You selected a future date"/>
			)}
			<div className="calendar-container">
				<div className={bodyStyle} style={{top: bodyTop, right: bodyRight, left: bodyLeft}}>
					<div className={headerStyle}>
						<div className="select-date-text">Selected date</div>
						<div className="selected-date-container">
							<div className={selectedDateStyle}>{selectedDateText}</div>
							
							<div className="d-flex w-75 align-items-end justify-content-end">
								{isManulDateOpen && !isRanged &&(	
									<input
										placeholder="DD/MM/YYYY"
										onChange={handleManulDate.bind(this)}
										className={manualContainerStyle}>
									</input>
								)}

								{!isRanged &&(
									<div 
										className="edit-icon mx-1"
										onClick={openManualDate}>
									</div>
								)}
							</div>
						</div>
					</div>	

					<div className={calendarStyle}>
						<div className="controlls-container">
							<div className="selectors-container" onClick={toggleDDYear}>
								{selectedMonth || date.toLocaleString("en", { month: 'long'})} {selectedYear || date.getFullYear()}
								<div className="arrow-indicator"/>
							</div>

							<div className="change-month-container">
								<div className="prev" onClick={onPrev}/>
								<div className="next" onClick={onNext} style={{display: `${isNext}`}}/>
							</div>
						</div>

						{isDDYearOpen && (
							<div id="bodyYears" ref={refPassed} className="dd-years-container">
								<Scrollbars>
									<div className="scrollable">
										{years.map(year =>{
											return <div onClick={onSelectedYear.bind(this)}>{year}</div>
										})}
									</div>
								</Scrollbars>
							</div>
						)}

						{isDDMonthOpen && (
							<div id="bodyMonths" className="dd-month-container">
								<div className="scrollable">
									{months.map(month =>{
										return <div onClick={onSelectedMonth.bind(this)}>{month}</div>
									})}
								</div>
							</div>
						)}

						<div className="days-container">
							<div className="days-name">
								{daysName.map(name => {
									return <div style={{cursor:"pointer"}}>{name}</div>
								})}
							</div>

							<div className="days">
								{days.map(day => { 
										if(day.toString() == ' ') {
											selectableDay = "hidden";
											visibility = "hidden"
										}
										else {
											selectableDay = "flex";
											visibility = "visible";
										}
										if(isRanged){
											if(range.length === 1){
												if(day == range[0].selectedDay && selectedMonth == range[0].selectedMonth && 
													selectedYear == range[0].selectedYear){
														backgroundColor = "var(--main-color)";
													}
													else{
														backgroundColor = "transparent";
													}
												}
											else if(range.length === 2){
												const now = new Date(selectedYear, months.indexOf(selectedMonth), day, 0, 0);
												const date0 = new Date(range[0].selectedYear, months.indexOf(range[0].selectedMonth), range[0].selectedDay, 0, 0);
												const date1 = new Date(range[1].selectedYear, months.indexOf(range[1].selectedMonth), range[1].selectedDay, 0, 0);
												
												if(now > date0 && now < date1){
													backgroundColor = "var(--bg-alt3-color)	";
												}
												else if (!(now < date0 || now > date1)){
													backgroundColor = "var(--main-color)";
												}
												else{
													backgroundColor = "transparent";
												}
											}
										}
										else{
											if(selectedDateInput){
												const arrayDate = selectedDateInput.split('/');
												const dayS = arrayDate[0];
												const month = arrayDate[1];
												const year = arrayDate[2];

												if(dayS == day && months.indexOf(selectedMonth) == month - 1
													&& selectedYear == year)
													backgroundColor = "var(--main-color)";
												else
													backgroundColor = "transparent";
											}
										}
									return (
										<div
											onClick={onSelectedDay.bind(this)}
											style={{cursor:"pointer", display: `${selectableDay}`, visibility: `${visibility}`, 
													backgroundColor: `${backgroundColor}`}}>

												<span>{day}</span>
										</div>
									);
								})}
							</div>
						</div>

						<div className="btns-container">
							<button onClick={ClearData.bind(this)}> Cancel </button>
							<button onClick={OkBtn}> Okay </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export  function DatePickerMobile({upFont = "AppFontBold", upFontSize = 18, headerHeight = 59, left = "0px",
								isRanged = false, bodyTop="-200px" ,hasBorder=true, range = [], onSelected = () => {} ,setRange = () => {}, refPassed}){
									
	const [selectedDay, setSelectedDay] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(date.toLocaleString('en', {month: 'long'}));
	const [monthIndex, setMonthIndex] = useState(date.getMonth());
	const [selectedYear, setSelectedYear] = useState(date.getFullYear());
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedDateInput, setSelectedDateInput] = useState(null);
	const [rangeDay, setRangeDay] = useState(null);
	const [rangeMonth, setRangeMonth] = useState(null);
	const [rangeYear, setRangeYear] = useState(null); 
	const [days, setDays] = useState([]);
	
	const [isOpen, setIsOpen] = useState(false);
	const [isDDYearOpen, setIsDDYearOpen] = useState(false);
	const [isDDMonthOpen, setIsDDMonthOpen] = useState(false);
	const [isManulDateOpen, setIsManulDateOpen] = useState(true);
    const [wasOpenned, setWasOpenned] = useState(false);

	const years = [];
	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	const noDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
	const daysName = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
	
	const wrapperRef = useRef(null);

	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsOpen(false);
					setIsDDYearOpen(false);
					setIsDDMonthOpen(false);
					setIsManulDateOpen(false);
				}
			}

			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

    useOutsideAlerter(wrapperRef);

	for(let i = date.getFullYear(); i >= 1900; i--){
        years.push(i);
    }

	//UPDATING THE DISPLAYED DATE 
	useEffect(() => {
		if(selectedYear && selectedMonth && selectedDay){
			const aux = new Date(selectedMonth + ' ' + selectedDay + ',' + selectedYear + ' 00:00:01');

			setSelectedDate(aux.toLocaleString('en', {weekday : 'short'}) + ', ' 
						  + aux.toLocaleString('en', {month : 'short'}) + ' ' + selectedDay);

			const actualMonthNo = months.indexOf(selectedMonth) + 1;
			const monthNoToDisplay = actualMonthNo < 10 ? '0' + actualMonthNo.toString() : actualMonthNo;
			setSelectedDateInput(selectedDay + '/' + monthNoToDisplay + '/' + selectedYear);
		}

		setRangeDay(selectedDay);
		setRangeMonth(selectedMonth);
		setRangeYear(selectedYear);
	},[selectedDay])

	//SENDING THE SELECTED DATE
	useEffect( () => {
		onSelected(selectedDateInput);
	}, [selectedDate]) 

	//UPDATING THE RANGE
	useEffect( () => {
		if(rangeDay && rangeMonth && rangeYear){

			if(range.length === 2){
				setRange([{selectedDay: rangeDay,
							selectedMonth: rangeMonth,
							selectedYear: rangeYear}]);
			}

			else if(range.length === 1){
				const date1 = new Date(range[0].selectedYear, months.indexOf(range[0].selectedMonth), range[0].selectedDay, 0, 0);
				const date2 = new Date(selectedYear, months.indexOf(selectedMonth), selectedDay, 0, 0);

				if(date1 != date2){
					if(date1 < date2)
						setRange([...range,  {selectedDay: rangeDay,selectedMonth: rangeMonth,selectedYear: rangeYear}]);
					else
						setRange([{selectedDay: rangeDay,selectedMonth: rangeMonth,selectedYear: rangeYear}, ...range]);
				}
			}
			else{
				setRange([...range,  {selectedDay: rangeDay,selectedMonth: rangeMonth,selectedYear: rangeYear}]);
			}
		}
	}, [rangeDay])

	useEffect(() => {
		if(range.length > 2){
			setRange(range.slice(2));
			return;
		}

		if(isRanged){
			if(range.length === 1)
			{
				const month0 = months.indexOf(range[0].selectedMonth) + 1;
				setSelectedDateInput(range[0].selectedDay + '/' + month0 + '/' + range[0].selectedYear + ' - ' +
				'DD/MM/YYYY');
			}
			else if(range.length === 2){
				const month0 = months.indexOf(range[0].selectedMonth) + 1;
				const month1 = months.indexOf(range[1].selectedMonth) + 1;
				setSelectedDateInput(range[0].selectedDay + '/' + month0 + '/' + range[0].selectedYear + ' - ' +
									 range[1].selectedDay + '/' + month1 + '/' + range[1].selectedYear);
			}
		}
	}, [range])

	//CHECKING IF LEAP YEAR WHEN NAVIGATING THRU CALENDAR
	useEffect(() => {
		const aux = new Date(selectedMonth + ' ' + '1' + ',' + selectedYear + ' 00:00:01');
	
		let i = 0;
		const auxDays = []

		while(i < aux.getDay()){
			auxDays.push(' ');
			i++;
		}
		i = 1;
		let currentNoDay = noDaysInMonth[months.indexOf(selectedMonth)];
		if(selectedMonth === "February" && IsLeapYear(selectedYear)) currentNoDay++;
		
		while(i <= currentNoDay){
			auxDays.push(i);
			i++
		}

		setDays(auxDays);
	}, [selectedMonth, selectedYear])

	useEffect(() => {
        function handler(event) {
            
        }
        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler)
    }, [])

	const toggle = () => {
		setWasOpenned(true);
		setIsOpen(!isOpen);
	}

	const toggleDDYear = () => {
		if(isDDMonthOpen) setIsDDMonthOpen(false);
		else setIsDDYearOpen(!isDDYearOpen);
	}

	const isNext = months.indexOf(selectedMonth) >= date.getMonth() &&
				   selectedYear >= date.getFullYear() ? "none" : "";
	
	function ClearData() {
		setSelectedDay(null);
		setSelectedMonth(date.toLocaleDateString('en', {month: 'long'}));
		setSelectedYear(date.getFullYear());
		setSelectedDate(null);
		setSelectedDateInput(null);
		setIsOpen(false);
		setIsDDMonthOpen(false);
		setIsDDYearOpen(false);
		setIsManulDateOpen(false);
		setRange([
			{
				selectedDay: "1",
				selectedMonth: "January",
				selectedYear: date.getFullYear()
			},
			{
				selectedDay: "31",
				selectedMonth: "December",
				selectedYear: date.getFullYear()
			}
		])
	}

	function OkBtn(){
		setIsOpen(false);
	}

	function onSelectedYear(props){
		setSelectedYear(props.target.innerHTML);
		setIsDDYearOpen(false);
		setIsDDMonthOpen(true);
	}

	function onSelectedMonth(props){
		console.log(props.target);
		var month = "";
		if(props.target.childElementCount != 0){
			month = props.target.childNodes[0].innerHTML;
		}
		else{
			month = props.target.innerHTML;
		}
		setSelectedMonth(month);
		setMonthIndex(months.indexOf(props.target.innerHTML));
		setIsDDMonthOpen(false);
	}

	function onSelectedDay(props){
		const value = props.target.innerHTML;
		if(value.length < 3){
			setSelectedDay(value.substring(0, 2));
		}
		else{
			const aux = value.substring(6,8);
			setSelectedDay(aux[1] === '<' ? aux[0] : aux);
		}
	}

	function onNext(){
		let aux = monthIndex + 1;

		if(aux > 11) {
			aux = 0;
			const yearAux = parseInt(selectedYear) + 1;
			setSelectedYear(parseInt(yearAux));
		}
		
		setMonthIndex(aux);
		setSelectedMonth(months[aux]);
	}

	function onPrev(){
		let aux = monthIndex - 1;

		if(aux < 0) {
			aux = 11;
			const yearAux = parseInt(selectedYear) - 1;
			setSelectedYear(parseInt(yearAux));
		}

		setMonthIndex(aux);
		setSelectedMonth(months[aux]);
	}

	function handleManulDate(props){
		const value = props.currentTarget.value.substring(0, 10);
		if(value.length === 10){
			props.currentTarget.blur();
			setSelectedDay(parseInt(value.substring(0,2)));
			setSelectedMonth(months[parseInt(value.substring(3,5)) - 1]);
			setSelectedYear(parseInt(value.substring(6,10)));
			props.currentTarget.value = value; 
		}
	}

	function openManualDate(){
		setIsManulDateOpen(!isManulDateOpen); 
	}	

	let selectedDateStyle = isRanged ? "selected-date-text ranged-header-text" : "selected-date-text";
	let selectedDateText = isRanged ? selectedDateInput : selectedDate
	let headerStyle = isRanged ? "headerRanged" : "header"
	let calendarStyle = isRanged ? "calendar calendarRanged" : "calendar"
	let bodyStyle = isRanged ? "body bodyRanged" : "body"
 	let manualContainerStyle = isManulDateOpen ? "manual-container manual-container-open" : "manual-container manual-container-close";
	let selectableDay = "flex";
	let visibility = "visible";
	let backgroundColor = "transparent"
	let calendarHeaderStyle = isRanged ? "calendar-header calendar-header-ranged" : "calendar-header"

	function arrowStyle(){
		if(!wasOpenned)
			return "arrow2";

		if(isOpen){
			return "arrow2 arrow2-open";
		}
		else{
			return "arrow2 arrow2-close";
		}
	}

	function borderStyle(){
		if(hasBorder === true){
			if(isRanged === true){
				return "0px";
			}
			else{
				return "1px solid var(--main-color)";
			}
		}
		else{
			return "0px";
		}
	}
	headerHeight = parseInt(headerHeight);
	upFontSize = parseInt(upFontSize);

	return(
		<div ref={wrapperRef}>
			<div className="calendar-container">
				{!isRanged &&(
					<div className="mb-1">
						<span className="text">Date of birth</span>
					</div>
				)}
				<button 
					ref = {refPassed}
					onClick={toggle}
					className={calendarHeaderStyle}
					style={{border: borderStyle()}}>
					<div className={selectedDateInput != null ? "header-text header-with-text" : "header-text header-without-text"}>
						{selectedDateInput || "DD/MM/YYYY"}
					</div>
					
					<div className={arrowStyle()}/>
				</button>

				{isOpen &&(
					<div>
						<div className={bodyStyle} style={{top: bodyTop, left: left}}>
							<div className={headerStyle}>
								<div className="select-date-text">Select date</div>
								<div className="selected-date-container">
									<div className={selectedDateStyle}>{selectedDateText}</div>
									
									<div className="d-flex w-75 align-items-end justify-content-end">
										{isManulDateOpen && !isRanged &&(	
											<input
												placeholder="DD/MM/YYYY"
												onChange={handleManulDate.bind(this)}
												className={manualContainerStyle}>
											</input>
										)}

										{!isRanged &&(
											<div 
												className="edit-icon mx-1"
												onClick={openManualDate}>
											</div>
										)}
									</div>
								</div>
							</div>	

						<div className={calendarStyle}>
							<div className="controlls-container">
								<div className="selectors-container" onClick={toggleDDYear}>
									{selectedMonth || date.toLocaleString("en", { month: 'long'})} {selectedYear || date.getFullYear()}
									<div className="arrow-indicator"/>
								</div>

								<div className="change-month-container">
									<div className="prev" onClick={onPrev}/>
									<div className="next" onClick={onNext} style={{display: `${isNext}`}}/>
								</div>
							</div>

							{isDDYearOpen && (
								<div className="dd-years-container">
									<Scrollbars>
										<div className="scrollable">
											{years.map(year =>{
												return <div onClick={onSelectedYear.bind(this)}>{year}</div>
											})}
										</div>
									</Scrollbars>
								</div>
							)}

							{isDDMonthOpen && (
								<div className="dd-month-container">
									<div className="scrollable">
										{months.map(month =>{
											return <div onClick={onSelectedMonth.bind(this)}><span style={{margin: 'auto'}}>{month}</span></div>
										})}
									</div>
								</div>
							)}

							<div className="days-container">
								<div className="days-name">
									{daysName.map(name => {
										return <div style={{cursor:"pointer"}}>{name}</div>
									})}
								</div>

								<div className="days">
									{days.map(day => { 
											if(day.toString() == ' ') {
												selectableDay = "hidden";
												visibility = "hidden"
											}
											else {
												selectableDay = "flex";
												visibility = "visible";
											}
											if(isRanged){
												if(range.length === 1){
													if(day == range[0].selectedDay && selectedMonth == range[0].selectedMonth && 
														selectedYear == range[0].selectedYear){
															backgroundColor = "var(--main-color)";
														}
														else{
															backgroundColor = "transparent";
														}
													}
												else if(range.length === 2){
													const now = new Date(selectedYear, months.indexOf(selectedMonth), day, 0, 0);
													const date0 = new Date(range[0].selectedYear, months.indexOf(range[0].selectedMonth), range[0].selectedDay, 0, 0);
													const date1 = new Date(range[1].selectedYear, months.indexOf(range[1].selectedMonth), range[1].selectedDay, 0, 0);
													
													if(now > date0 && now < date1){
														backgroundColor = "var(--bg-alt3-color)	";
													}
													else if (!(now < date0 || now > date1)){
														backgroundColor = "var(--main-color)";
													}
													else{
														backgroundColor = "transparent";
													}
												}
											}
											else{
												if(selectedDateInput){
													const arrayDate = selectedDateInput.split('/');
													const dayS = arrayDate[0];
													const month = arrayDate[1];
													const year = arrayDate[2];

													if(dayS == day && months.indexOf(selectedMonth) == month - 1
														&& selectedYear == year)
														backgroundColor = "var(--main-color)";
													else
														backgroundColor = "transparent";
												}
											}
										return (
											<div
												onClick={onSelectedDay.bind(this)}
												style={{cursor:"pointer", display: `${selectableDay}`, visibility: `${visibility}`, 
														backgroundColor: `${backgroundColor}`}}>

													<span>{day}</span>
											</div>
										);
									})}
								</div>
							</div>

							<div className="btns-container">
								<button onClick={ClearData.bind(this)}> Cancel </button>
								<button onClick={OkBtn}> Okay </button>
							</div>
						</div>
					</div>
					</div>
				)}
			</div>
		</div>
	);
}

export function CustomDatepicker(){
	const [selectedDayRange, setSelectedDayRange] = useState({
		from: null,
		to: null
	  });

	return (    
		<Calendar
			value={selectedDayRange}
			onChange={setSelectedDayRange}
			inputPlaceholder="Select a day range"
      />
	);
}