import { useEffect, useRef, useState } from 'react';
import './cardConfirm.css';
import t from '../services/translations'

export default function CardConfirm({openModal}){
    const [modalPosition, setModalPosition] = useState("fixed");

    const refModal = useRef();

    function HandleResize(){
        if(refModal.current.clientHeight + 20 > window.innerHeight){
            setModalPosition("absolute");
        }
        else{
            setModalPosition("fixed");    
        }
    }

    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    })

    return(
        <div>
            <div ref={refModal} style={{position: modalPosition}} className="modal-container-card-confirm">   
                <button 
                    onClick={openModal.bind(this, "")}
                    className="close-btn">&times;</button>
                
                    <div className="title-container">
                        <span className="title">Order a card</span>
                    </div>
                    <div className="header">
                        <div className="datails-container">
                            <div className="delivery-address">
                                <span>Flowers Street no.5</span>
                                <span>700519</span>
                                <span>New York</span>
                                <span>New Your, USA</span>
                            </div>
                    </div>
                        <div className="card-container">
                            <img 
                                src={process.env.PUBLIC_URL + "/Images/" + t('CardFrontImage')}
                                className="card-logo"/>
                        </div>
                    </div>

                <div className="body">
                    <div
                        onClick={openModal.bind(this, "Card New Address")} 
                        className="change-btn-cont">
                        <button className="change-btn">
                            Change delivery address
                        </button>
                    </div>

                    <div
                        onClick = {openModal.bind(this, "Card Done")}
                        className="continue-btn-cont">
                        <button className="continue-btn">
                            Order Card
                        </button>
                    </div>
                </div>
            </div>
            <div className="overlay"/>
        </div>
    );
}