import axios from 'axios';
import { useEffect, useState } from 'react';
import '../css/leftSide.css';
import { API, getCardPrince, SessionVars } from './utils';
import t from '../../services/translations'
function LeftSide() {
    const [cost ,setCost] = useState();
    
    useEffect(() =>{
        if(sessionStorage.getItem(SessionVars.cardPrice) === null ||
            sessionStorage.getItem(SessionVars.cardPrice) === undefined)
            {
                sessionStorage.setItem(SessionVars.cardPrice, getCardPrince());
            }
        else{
            setCost(sessionStorage.getItem(SessionVars.cardPrice));
        }
    })
    console.log(getCardPrince());
    return (
        <div className="left col-lg-5 px-0 mx-0 d-flex flex-column justify-content-around">
            <div className="row mt-5 mb-2 px-5 mx-0">
                <span className="welcome-text mx-0 w-100">WELCOME TO {t('AppBrandCapitalized')} DEBIT CARD</span>
            </div>

            <div className="row px-0 mx-0 mb-5 w-100">
                <span className="login-text mx-0 w-100">Login to activate your card, check your balance, view and download statements, lock your card and more.</span>
            </div>
            <div className="card-container my-5 center">
                <img 
                    className="card"
                    src={process.env.PUBLIC_URL + "Images/" + t('CardFrontImage')}/>
                <span className='mt-2'>Price £{getCardPrince()}</span>
            </div>
        </div>
        );
}

export default LeftSide;