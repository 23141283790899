import LeftSide from "../Global/components/leftSide";
import { AlreadyHaveAnAccount, MiniLogo, PageTitle } from "../Global/components/reusable";
import { ContinueBtn, LargeInputPhoneWithTextOnTop, LargeInputWithTextOnTop } from '../Global/components/buttons';
import React, { useEffect, useRef, useState } from "react";
import './phoneInsert.css'
import axios from "axios";
import { API, SessionVars } from "../Global/components/utils";
import 'react-phone-number-input/style.css'

function InsertPhonePage() {
    return (
        <div className="container-fluid row main m-0 p-0">
            <LeftSide />
            <RightSide />
        </div>
    );
}

const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}

// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context("../Images/Flags", false, /\.(png|jpe?g|svg)$/));

const options = [
    { language: "United Kindom - English", img: "united-kingdom.png" },
    { language: "Deutschland - Deutsch", img: "germany.png" },
    { language: "France - France", img: "france.png" },
    { language: "Espana - Espanol", img: "spain.png" },
    { language: "Romania - Romana", img: "romania.png" }
];

function RightSide() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [phone, setPhone] = useState();
    const [phoneError, setPhoneError] = useState("");
    const dropdownRef = useRef();

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    function continueBtn() {
        if (phone != "") {
            setPhoneError("");
        }
        console.log(phone);
        axios.post(API.channelValidationUrl, {
            identifier: phone,
            type: "PhoneNumber"
        })
            .then(res => {
                setPhoneError("");
                console.log("res", res.data);
                sessionStorage.setItem(SessionVars.appName, phone);
                sessionStorage.setItem(SessionVars.phoneChannelId, res.data);
                window.open("/phoneVerification", "_self");
            })
            .catch(err => { setPhoneError("Invalid phone"); console.log("Invalid phone number\n: ", err) });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        function handler(event) {
            if (!dropdownRef.current?.contains(event.target)) {
                setIsOpen(false)
            }
        }
        window.addEventListener('click', handler)

        return () => window.removeEventListener('click', handler)
    }, [])

    return (
        <div className="col-lg-7 p-0 m-0 d-flex right row">
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">

                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo />
                    </div>

                    <div className="page-title-container">
                        <PageTitle
                            title="Phone verification"
                            message="Set your phone number" />
                    </div>
                </div>

                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-column w-100">
                        <span style={{ fontFamily: 'AppFontBold', fontSize: '22px', marginBottom: '15px', lineHeight: '20px' }} className="">
                            Enter your phone number and
                            we’ll send you a code you need
                            to enter on the next page
                        </span>

                        <div className="phone-container-signupPage mb-2">
                            <LargeInputPhoneWithTextOnTop
                                error={phoneError}
                                setError={setPhoneError.bind(this)}
                                upText="Phone number"
                                onChange={setPhone}
                                placeholder="Please enter your phone number" />

                        </div>
                    </div>
                </div>

                <div className="continue-btn-container-signupPage">
                    <ContinueBtn onClick={() => { continueBtn() }} />
                </div>

                <div className="already-container-signupPage w-100">
                    <AlreadyHaveAnAccount
                        text="Already have an account?"
                        textGold=" Sign In" />
                </div>

            </div>
        </div>
    );
}

export default InsertPhonePage;