import './main.css';
import OrderCardHome from './orderCardHome';

export default function CardHome({openModal = () => {}, cardInfo = {}, modal = ""}){
    return(
        <div className="">
            <OrderCardHome cardInfo={cardInfo} modal={modal} openModalUp={openModal.bind(this)}/>
        </div>
    );
}
