
import LeftSide from "../Global/components/leftSide";
import {AlreadyHaveAnAccount, MiniLogo, PageTitle} from '../Global/components/reusable';
import { ContinueBtn } from '../Global/components/buttons';
import './photoUpload.css'
import { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { useHistory, Link } from 'react-router-dom';
import { API, RemoveBase64Sufix, SessionVars, TokenData, TokenValidation } from "../Global/components/utils";
import ReactCrop from "react-image-crop";
import { async } from "@firebase/util";

export default function PhotoUploadPage(props){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide password = {props.location.state}/>
        </div>
    );
}

function  RightSide({password = ""}) {
    const [fileType, setFileType] = useState("");
    const [imgToUpload, setImgToUpload] = useState("");
    const [nextPage, setNextPage] = useState("");
    const history = useHistory();
    const [upImg, setUpImg] = useState(null);
	const [crop, setCrop] = useState({ unit: '%', aspect: 1 / 1, width: 100});
	const [completedCrop, setCompletedCrop] = useState(null);
    const [error, setError] = useState();
    const [loaded, setLoaded] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
    let username = sessionStorage.getItem(SessionVars.username);
    console.log(username, password);
    const hadleUploadChange = (e) => {
        const file = e.target.files[0];
        
        setCrop({ unit: '%', aspect: 1 / 1, width: 100});
        if(!file){
            return;
        }

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        setFileType(file.type);
        reader.onload = () => {
            setUpImg(`data:${file.type};base64,${btoa(reader.result)}`);
            setImgToUpload(`${btoa(reader.result)}`);
        }
    }

    function kycCheck(){
        axios.get(API.kycStatusUrl, {
            headers: {
                'Authorization' : `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        })
        .then(res => {
            console.log("success");
            if(res.data.status === "Unknown" || res.data.status === "Denied")
                setNextPage("/kyc");
            else if(res.data.status === "Pending")
                setNextPage("/signDone");
            else 
                setNextPage("/signIn");
        })
        .catch(err => {
            console.log("Error checking KYC", err);
        })
    }

    async function Save(){
        const date = new Date(); 
        try{
            const response = await getToken(username, password);
            sessionStorage.setItem(TokenData.token, response.data.access_token);
            sessionStorage.setItem(TokenData.refreshToken, response.data.refresh_token);
            sessionStorage.setItem(TokenData.createdAt, date.getTime());
            sessionStorage.setItem(TokenData.tokenLifespan, response.data.expires_in);  
        }
        catch(err) {
            console.log(err.response);
        }

        const auxToSaveLocaly = previewCanvasRef.current.toDataURL(fileType);
        const auxToLoad = RemoveBase64Sufix(auxToSaveLocaly);

        if(await TokenValidation()){
            axios.post(API.setProfilePictureUrl, {
                type: "Image",
                imageContent: auxToLoad
            },
            {
                headers:{
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${sessionStorage.getItem(TokenData.token)}` 
                }
            })
            .then(res => {
                setLoaded(true);
                localStorage.setItem(SessionVars.profilePic, auxToSaveLocaly);
                setError("");
                kycCheck();
            })
            .catch(err => {
                console.log("Error uploading photo", err.response);
                setError("Something failed while uploading profile pic");
                //setLoaded(false);
            });
        }
        else{
            setError("Your token is no longer valid, you will be redirect to main page in 5 seconds");
            setDisabled(true);
            setTimeout(() => {
                history.replace("/app");
            }, 5000);
        }
    }

    const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
	}

	const image = imgRef.current;
	const canvas = previewCanvasRef.current;
	const crop = completedCrop;

	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	const ctx = canvas.getContext('2d');
	const pixelRatio = window.devicePixelRatio;

	canvas.width = crop.width * pixelRatio * scaleX;
	canvas.height = crop.height * pixelRatio * scaleY;

	ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
	ctx.imageSmoothingQuality = 'high';

	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width * scaleX,
		crop.height * scaleY
	);
	}, [completedCrop]);

    async function getToken(usr, pw){
        //GET THE TOKENS
        var qs = require('qs');
        var data = qs.stringify({
            'grant_type': 'password',
            'username': `${username}`,
            'password': `${password}`,
            'scope': 'offline_access email BankingMiddleware openid',
            'client_id': 'BankingMiddleware_App',
            'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
        });

        var config = {
            method: 'post',
            url: 'https://auth.banking.bss-lab.it/connect/token',
            headers: { 
                'content-type': 'application/x-www-form-urlencoded',
                'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
            },
            data : data
        };

        return await axios(config);

    }

    async function getTokensFromLogin(){
        const objLogin = {
            grantType: "password",
            username: username,
            password: password,
            scope: "offline_access email BankingMiddleware openid",
            clientId: "BankingMiddleware_App",
            clientSecret: "tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx"
        }

        await axios.get(API.openConfigurationUrl, objLogin)
        .then(async res => {
            //GET THE TOKENS
            var qs = require('qs');
            var data = qs.stringify({
                'grant_type': 'password',
                'username': `${username}`,
                'password': `${password}`,
                'scope': 'offline_access email BankingMiddleware openid',
                'client_id': 'BankingMiddleware_App',
                'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
            });

            var config = {
                method: 'post',
                url: 'https://auth.banking.bss-lab.it/connect/token',
                headers: { 
                    'content-type': 'application/x-www-form-urlencoded',
                    'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
                },
                data : data
            };

            await axios(config)
            .then(response => {
                const date = new Date();
                sessionStorage.setItem(TokenData.token, response.data.access_token);
                sessionStorage.setItem(TokenData.refreshToken, response.data.refresh_token);
                sessionStorage.setItem(TokenData.createdAt, date.getTime());
                sessionStorage.setItem(TokenData.tokenLifespan, response.data.expires_in);  
                console.log("tokens saved");         
                
            })
            .catch(error => {
                console.log("Token error: ", error);
                return false;
            });
        })
        .catch(err => {
            console.log("Failed login: ", err)
            return false;
        });
        return true;
    }

    const shiftFocus = () =>{
        const input = document.querySelector('input[id=btn-file');
        
        if(input){
            input.click();
        }
    }

    // async function continueHandler(){
    //     const token = sessionStorage.getItem(TokenData.token);
    //     if(token === null || token === undefined || !await TokenValidation()){
    //         getTokensFromLogin();
    //     }
    //     else{
    //         axios.get(API.kycStatusUrl, {
    //             headers: {
    //                 'Authorization' : `Bearer ${token}`
    //             }
    //         })
    //         .then(res => {
    //             console.log("success");
    //             if(res.data.status === "Unknown" || res.data.status === "Denied")
    //                 setNextPage("/kyc");
    //             else if(res.data.status === "Pending")
    //                 setNextPage("/signDone");
    //             else 
    //                 setNextPage("/signIn");
    //         })
    //         .catch(err => {
    //             console.log("Error checking KYC", err);
    //         })
    //     }        
    // }

    useEffect(() => {
        console.log(upImg);
    }, [])
    useEffect(() => {
        if(nextPage !== "")
            document.getElementById("next").click();
    }, [nextPage])

    return(
        <div className="col-lg-7 p-0 pb-3 m-0 d-flex right row" style={{height: 'auto'}}>
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                
                    <div className="page-title-container">
                        <PageTitle
                            title="Upload profile photo"
                            message="Looking sharp today!"/>
                    </div>
                </div>
                
                <div className="cropper-container">
                    <div className="d-flex flex-column justify-content-between">
                        <ReactCrop
                            src={upImg === null ? 
                            process.env.PUBLIC_URL + "/Images/Icons/Camera icon.png" : upImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            style={upImg === null ? {width: '116px', margin: 'auto'} : {width: '300px'}}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                            />

                        <input 
                            id="btn-file" 
                            className="add-photo" 
                            accept="image/*" 
                            type="file" 
                            onChange={hadleUploadChange}>
                        </input>

                        <div
                            onClick={shiftFocus} 
                            className="add-photo-style mt-4">
                            <div className="circle">
                                <div className="vertical"/>
                                <div className="horizontal"/>
                                </div>
                                <div className="text">
                                    <span>Choose from Gallery</span>
                                </div>
                        </div>
                    </div>

                    <div className="preview d-flex flex-column justify-content-between">
                        <div>Preview</div>
                            <canvas
                                ref={previewCanvasRef}
                                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                style={{
                                    borderRadius: '1000px',
                                    width: '150px',
                                    height: '150px',
                                    margin: '10px 0'
                                }}
                            />
                        
                        <div style={{margin: '0px'}} className="continue-btn justify-self-end">
                            <ContinueBtn
                                isDisabled={isDisabled}
                                message = "Save"
                                onClick = {() => Save()}/>
                        </div>
                    </div>
                </div>
                <span className="error-message" style={{height: '24px', textAlign: 'center'}}>{error}</span>

                <Link id="next" 
                    to={{
                        pathname: `${nextPage}`,
                        state: {
                            email: sessionStorage.getItem(SessionVars.appName),
                            password: password
                        }}}
                />

                <div>
                   
                </div>
            </div>
           
        </div>
    );
}