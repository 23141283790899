import { ContinueBtn } from '../Global/components/buttons';
import {useEffect, useRef, useState} from 'react';
import './orderCard.css';
import { API, Data, TokenData, TokenValidation } from '../Global/components/utils';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { PopupWithInput } from '../Global/components/reusable';
import t from '../services/translations'

export default function OrderCard({openModal}){
    const [isChecked, setIsChecked] = useState(false);
    const [modalPosition, setModalPosition] = useState("fixed");
    const [pinPopup, setPinPopup] = useState(false);
    const [error, setError] = useState("");
    const [errorTimerId, setErrorTimerId] = useState(null);

    const refModal = useRef();
    const history = useHistory();

    function HandleResize(){
        if(refModal.current.clientHeight + 20 > window.innerHeight){
            setModalPosition("absolute");
        }
        else{
            setModalPosition("fixed");    
        }
    }

    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    }, [])

    useEffect(() => {
        if(errorTimerId != null){
            clearTimeout(errorTimerId);
        }

        if(error !== ""){
            setErrorTimerId(setTimeout(() => {
                setError("");
                setErrorTimerId(null);
            }, 5000))
        }
    }, [error])

    async function ConfirmAction(pin){
        if(await TokenValidation()){
            var SHA256 = require("js-sha256").sha256;
            let encryptedPin = SHA256(pin);
            
            axios.post(API.requestVirtualCardUrl, {}, {
                params: {
                    userPin: encryptedPin
                },
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                console.log(res);
                openModal("Card done")
            })
            .catch(err => {
                console.log("ERROR ordering a card\n", err.response);
    
                if(err.response.status === 403){
                    setError("Invalid pin");
                } 
                else{
                    setError("Something failed when proccessing your request");
                }
                setPinPopup(false);
            })
        }
        else {
            history.replace("/pinCheck");
        }
        
    }

    function HandleContinue(){
        if(sessionStorage.getItem(Data.cardTypeToOrder) === "physical"){
            openModal("Card Details");
        }
        else{
            setPinPopup(true);
        }
    }

    return(
        <div>
            <div ref={refModal} style={{position: modalPosition}} className="modal-container-order-card">   
                {pinPopup && (
                    <PopupWithInput
                        overlayStyle={{borderRadius: '14px'}}
                        confirmAction = {(pin) => ConfirmAction(pin)}
                        denyAction ={() => setPinPopup(false)}
                        title="Please insert pin to add a virtual card"/>
                )}
                <button 
                    onClick={openModal.bind(this, "")}
                    className="close-btn">&times;</button>
                <div className="header">
                    <div className="title-container">
                        <span className="title1">Add Card</span>
                        <div className="checkbox p-2">
                            <input type="checkbox" checked={isChecked} onClick={() => setIsChecked(!isChecked)}/>
                            <span>I agree to the <strong>Terms of Services</strong> and <strong>Privacy Policy</strong></span>
                        </div>
                    </div>
                    <div className="card-container">
                        <img 
                            src={process.env.PUBLIC_URL + "/Images/" + t('CardFrontImage')}
                            className="card-logo"/>
                    </div>
                </div>

                <div className="body">
                    <div style={{height: '24px', textAlign: 'center'}} className="error-message">{error}</div>
                    <div
                        onClick={() => HandleContinue()} 
                        className="continue-btn">
                        <ContinueBtn 
                            isDisabled={!isChecked}
                            message="Next"/>
                    </div>
                </div>
            </div>
            <div className="overlay"/>
        </div>
    );
}