import { ContinueBtn, LargeInputWithTextOnTop, SmallDoubleInputsWithTextOnTop } from '../Global/components/buttons';
import { Dropdown } from '../Global/components/dropdown';
import './cardNewAddress.css';
import {useState, useEffect, useRef} from 'react';
import { Popup } from '../Global/components/reusable';
import axios from 'axios';
import { API, TokenData, TokenValidation } from '../Global/components/utils';
import t from '../services/translations'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height
    };
  }  

export default function CardNewAddress({openModal, setDeliveryAddress = () => {}}){
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [countyList, setCountyList] = useState();
    const [postcode, setPostcode] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [streetNumber, setstreetNumber] = useState("");
    const [county, setCounty] = useState("");
    const [city, setCity] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [modalPosition, setModalPosition] = useState("fixed");
    const modalRef = useRef();

    function HandleResize(){
        if(window.innerWidth - 20 < modalRef.current.clientHeight){
            setModalPosition("absolute");
        }
        else{
            setModalPosition("fixed");    
        }
    }

    function HandleContinue(){
       
        let aux = {
            city: city,
            country: "United Kingdom",
            addressStreet: streetAddress,
            addressNumber: streetNumber,
            postcode: postcode,
            stateOrProvince: county,
            additionalInfo: additionalInfo
        }
        setDeliveryAddress(aux);     
        openModal("Card Details");   
    }

    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    })

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        console.log(windowDimensions);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    function Sort(arr){
        let i = 0;
        let j = 0;
        let aux;

        while(i < arr.length){
            j = i + 1;
            while(j < arr.length){
                if(arr[i] > arr[j]){
                    aux = arr[i];
                    arr[i] = arr[j];
                    arr[j] = aux;
                }
                j++;
            }
            i++;
        }
        return arr;
    }

    useEffect(async () =>{
        async function GetCounties(){
            await axios.get("https://api.banking.bss-lab.it/api/world/lookup-state-by-country-id/232")
                .then(res => {
                    let auxArr = []
                    res.data.map(auxCounty => auxArr.push(auxCounty.name));
                    setCountyList(Sort(auxArr));
                })    
                .catch(err => alert("Some error occured while loading the counties"));
        }
        async function LoadInitialAddress(){
            axios.get(API.me, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                setAdditionalInfo(res.data.addressRefinement);
                setCity(res.data.city);
                setCounty(res.data.stateOrProvince);
                setStreetAddress(res.data.addressStreet);
                setstreetNumber(res.data.addressNumber);
                setPostcode(res.data.postcode);
            })
            .catch(err => {
                console.log("ERROR getting user info\n", err.response);
            })
        }
        if(await TokenValidation()){
            await GetCounties();
            await LoadInitialAddress()
        }
        else{
            document.location.replace("/pinCheck");
        }
    }, []);

    return(
        <div>
            <div ref={modalRef} style={{position: 'absolute'}} className="modal-container-new-address">   
                <button 
                    onClick={openModal.bind(this, "")}
                    className="close-btn">&times;</button>
                <div className="header">
                    <div className="title-container">
                        <span className="title1">Order a card</span>
                        <span className="subtitle1">New delivery address</span>
                    </div>
                    <div className="card-container">
                        <img 
                            src={process.env.PUBLIC_URL+ "/Images/" + t('CardFrontImage')}
                            className="card-logo"/>
                    </div>
                </div>

                <div className="body">
                    <div className="input-container">
                        <div className="left-input">
                            <Dropdown
                                upText="County"
                                setSelect={setCounty.bind(this)}
                                initialValue={county}
                                options = {countyList}
                                header="Please select your county"/>
                        </div>

                        <div className="right-input">
                            <LargeInputWithTextOnTop
                                upText="City"
                                initialValue={city}
                                onChange={e => setCity(e.target.value)}
                                placeholder="Please write your city"/>
                        </div>
                    </div>

                    <div className="input-container">
                        <div className="left-input">
                            <LargeInputWithTextOnTop
                                upText="Street name"
                                initialValue={streetAddress}
                                onChange = {e => setStreetAddress(e.target.value)}
                                placeholder="Please write your street name"/>
                        </div>

                        <div className="right-input">
                            <LargeInputWithTextOnTop
                                upText="House number"
                                initialValue={streetNumber}
                                onChange = {e => setstreetNumber(e.target.value)}
                                placeholder="Please write your house number"/>
                        </div>
                    </div>

                    <div className="input-container">
                        <div className="left-input">
                            <LargeInputWithTextOnTop
                                initialValue={additionalInfo}
                                upText="Additional address information"
                                onChange = {e => setAdditionalInfo(e.target.value)}
                                placeholder="e.g. Building 17, Floor 3"/>
                        </div>
                        
                        <div className="right-input">
                            <LargeInputWithTextOnTop
                                upText="Postcode"
                                initialValue={postcode}
                                onChange = {e => setPostcode(e.target.value)}
                                placeholder="Please write your postcode"/>
                        </div>
                    </div>

                    <div   
                        style={{minWidth: '300px'}}
                        onClick={() => HandleContinue()} 
                        className="continue-btn mt-3 align-self-center">
                        <ContinueBtn message="Confirm new delivery address"/>
                    </div>
                </div>
            </div>
            <div className="overlay"/>
        </div>
    );
}