import { TokenData } from '../Global/components/utils';
var forge = require('node-forge');
var axios = require('axios');
var uuid = require('uuid');

function createKeyEnvelope(key, publicKey) {
  var publicKeyRsa = forge.pki.publicKeyFromPem("-----BEGIN PUBLIC KEY-----" + forge.util.encode64(forge.util.hexToBytes(publicKey)) + "-----END PUBLIC KEY-----")
  return forge.util.bytesToHex(publicKeyRsa.encrypt(forge.util.hexToBytes(key), "RSA-OAEP", { md: forge.md.sha512.create(), mgf1: { md: forge.md.sha512.create() } }));
}

function decryptMessage(hexKey, hexIv, cipherText) {
  var key = forge.util.hexToBytes(hexKey);
  var iv = forge.util.hexToBytes(hexIv);
  var text = forge.util.hexToBytes(cipherText);
  var decipher = forge.cipher.createDecipher('AES-CBC', key);
  decipher.start({iv: iv});
  decipher.update(forge.util.createBuffer(text));
  decipher.finish();
  return JSON.parse(decipher.output);
}

async function getCardTotp(cardId, customerPin) {
  try {
    var req = await axios.get(`https://api.banking.bss-lab.it/api/app/banking/card-secret/${cardId}/${customerPin}`,
    { 
      headers:{
          Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
      }
    });
    return req.data;
  } catch (Exc) { throw "getCardTotp failed with status code " + Exc; }
}

async function getCardPan(apiEndpoint, issuerName, cardId, secret, encryptionKey, publicKeyFingerprint, traceId, apiKeyId, meaSecret) {
  var req = await axios.post(`${apiEndpoint}/${issuerName}/getPan`, { 
    cardId, secret, encryptionKey, publicKeyFingerprint
  }, { headers: {
    'mea-trace-id': traceId,
    'mea-api-key-id': apiKeyId,
    'mea-secret': meaSecret
} });

  if (req.status === 200) {
    return req.data;
  }

  throw "getCardPan failed with status code " + req.status;
}

export async function meaWallet(cardId, customerPin) {
  var key = forge.random.getBytesSync(32);
  var hexKey = forge.util.bytesToHex(key);
  var cardTotp = await getCardTotp(cardId, customerPin);
  if(cardTotp.providerType === 'Meawallet') {
    var data = cardTotp.meawallet;
    var encryptionKey = createKeyEnvelope(hexKey, data.publicKey);
    var encryptedCardData = await getCardPan(data.apiUrlBase, data.issuerType, cardId, cardTotp.secret, encryptionKey, data.publicKeyFingerprint, data.traceId, data.apiKeyId, data.secret);
    var { pan, cvv, expiry, embossname } = decryptMessage(hexKey, encryptedCardData.iv, encryptedCardData.encryptedData);
    
    return {
        pan: pan,
        cvv: cvv,
        exp: expiry,
        name: embossname
    }
  } else {
    throw `Provider ${cardTotp.providerType} not implemented.`;
  }
}

