import { setRef } from '@mui/material';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowInput } from '../Global/components/buttons';
import { ErrorWithTimer, Header } from '../Global/components/reusable';
import { API, TokenData, TokenValidation } from '../Global/components/utils';
import './support.css';

export default function SupportController(){
    let component = null;

    switch(component){
        case "email":{
            break;
        }

        default:{
            component = <SupportPage/>
            break;
        }
    }

    return(
        <div>
            {component}
        </div>
    );
}

async function Email(history, setError){
    if(await TokenValidation()){
        axios.get(API.supportEmailUrl, {
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        })
        .then(res => {
            window.open(`mailto:${res.data}?subject=&body=`, "_self");
        })
        .catch(err => {
            console.log("Error getting\n", err);
            setError("Error getting the support mail");
        });
    }
    else{
        history.replace("/pinCheck");
    }
}

async function Phone(history, setError){
    if(await TokenValidation()){
        axios.get(API.supportPhoneUrl, {
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        }) 
        .then(res => {
            document.location.href = `tel:+${res.data}`;
        })
        .catch(err =>{ 
            console.log("Error with phone number\n", err);
            setError("Error getting the support number");
        })
    }
    else{
        history.replace("/pinCheck");
    }
}

function SupportPage(){
    const [height, setHeight] = useState();
    const [error, setError] = useState();
    const ref = useRef();
    const history = useHistory();

    function HandleResize(){
        setHeight(window.innerHeight - ref.current.offsetTop - 20);
    }

    useEffect(() => {
        var timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);
        
        return () => {
            clearTimeout(timeout);
            window.removeEventListener("resize", HandleResize);
        }
    })

    return(
        <div className="support-container">
            <Header text="Support"/>

            <div ref={ref} style={{height: height}} className="inputs-container">
                <ArrowInput handlePageChange={() => Email(history, setError.bind(this))} name="Email"/>
                <ArrowInput handlePageChange={() => Phone(history, setError.bind(this))} name="Phone"/>
                <ErrorWithTimer setError={setError.bind(this)} error={error}/>
            </div>
        </div>
    );
}