import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import t from '../../services/translations';

const authBaseUrl = "https://auth.banking.bss-lab.it";
const apiBaseURL = "https://api.banking.bss-lab.it";

export const API ={ 
  channelValidationUrl  : apiBaseURL + "/api/app/identity-channel-validations/request-identity-channel-validation",
  codeValidationUrl : apiBaseURL + '/api/app/identity-channel-validations/identity-channel-code-valid',
  openConfigurationUrl : authBaseUrl + '/.well-known/openid-configuration',
  connectTokenUrl : authBaseUrl + '/connect/token',
  userInfoUrl : authBaseUrl + '/connect/userinfo',
  registerUrl : apiBaseURL + '/api/account/register', 
  kycStatusUrl : apiBaseURL + '/api/app/kyc-services/status',
  kycServiceBeginUrl : apiBaseURL + '/api/app/kyc-services/begin',
  kycServiceEndUrl : apiBaseURL + '/api/app/kyc-services/end',
  setProfilePictureUrl : apiBaseURL + '/api/account/profile-picture',
  getProfilePictureUrl : apiBaseURL + '/api/account/profile-picture',
  regionsUrl : apiBaseURL + '/api/world/regions',
  countriesByRegionUrl : apiBaseURL + '/api/world/countries-by-region/%s',
  countriesUrl : apiBaseURL + '/api/world/lookup-countries',
  stateProvinceUrl : apiBaseURL + '/api/world/lookup-state-by-country-id/%i',
  citiesUrl : apiBaseURL + '/api/world/lookup-city-by-state-id/%i',
  updatePersonalInfo: apiBaseURL + '/api/app/users/update-address',
  occupationsUrl : apiBaseURL + '/api/person/occupations',
  professionsUrl : apiBaseURL + '/api/person/professions',
  monthlyIncomesUrl : apiBaseURL + '/api/person/incomes',
  changePasswordUrl : apiBaseURL + '/api/identity/my-profile/change-password',
  faqUrl : apiBaseURL + '/api/app/faq',
  termsUrl : apiBaseURL + '/api/registration/terms',
  privacyUrl : apiBaseURL + '/api/registration/privacy',
  supportEmailUrl : apiBaseURL + '/api/support/email',
  supportPhoneUrl: apiBaseURL + '​/api/support/phone',
  pinStatustUrl : apiBaseURL + '/api/app/banking-user-pin/status',
  resetCardPin: apiBaseURL + '/api/app/banking/reset-card-pin',
  storePinUrl : apiBaseURL + '/api/app/banking-user-pin/store/',
  validatePinUrl : apiBaseURL + '/api/app/banking/validate-pin/',
  bankAccountUrl : apiBaseURL + '/api/app/banking/bank-account',
  closeCard: apiBaseURL + '/api/app/banking/close-card/',
  freezeCard: apiBaseURL + '/api/app/banking/suspend-card',
  beneficiariesUrl: apiBaseURL + '/api/app/banking/beneficiaries',
  cardsUrl: apiBaseURL + '/api/app/banking/cards',
  cardBenefits: apiBaseURL + '​/api/registration/card-benefits',
  requestPhysicalCardUrl : apiBaseURL + '/api/app/banking/request-physical-card',
  requestVirtualCardUrl: apiBaseURL + '/api/app/banking/request-virtual-card',
  cardRules: apiBaseURL + '​/api/app/card-rule-settings',
  cardCost: apiBaseURL + '/api/app/banking/physical-card-cost',
  setCardRules: apiBaseURL + '/api/app/banking/set-card-rule',
  allNotificationsUrl : apiBaseURL + '/api/app/notifications',              
  markNotificationsAsReadUrl : apiBaseURL + '/api/app/notifications/read-all',
  markNotificationAsReadUrl : apiBaseURL + '/api/app/notifications/read',
  deleteAllNotificationsUrl : apiBaseURL + '/api/app/notifications/hide-all',
  deleteNotificationUrl : apiBaseURL + '/api/app/notifications/hide',
  transactionsUrl: apiBaseURL + "/api/app/banking/transactions",          
  bannersUrl : apiBaseURL + '/api/app/marketing-banner',
  activateCadUrl: apiBaseURL + '/api/app/banking/activate-card',
  closeCardUrl : apiBaseURL + '/api/app/banking/close-card',
  suspendCardUrl : apiBaseURL + '/api/app/banking/suspend-card',
  myNotificationSubscriptionsUrl : apiBaseURL + '/api/app/notificationsubscriptions/my-subscriptions',
  setNotificationSubscriptionsUrl : apiBaseURL + '/api/app/notificationsubscriptions',
  resetCardPinUrl : apiBaseURL + '/api/app/banking/reset-card-pin',
  getCardPinUrl : apiBaseURL + '/api/app/banking/card-pin',
  offersUrl : apiBaseURL + '/api/app/offers',
  newsUrl : apiBaseURL + '/api/app/news',
  downloadBankStatement: apiBaseURL + '/api/app/banking/bank-statement',
  downloadTransactions: apiBaseURL + '​/api/app/banking/transaction-report/',
  me: apiBaseURL + '/api/app/users/me',
  registerPushNotifications: apiBaseURL + '/api/app/notifications/register-push-notification',
  unregisterPushNotifications: apiBaseURL + '/api/app/notifications/unregister-push-notification',
  sentEmailResetPw: apiBaseURL + '/api/account/send-password-reset-code',
  topUp : apiBaseURL + '/api/app/top-up/begin'
}

export const BankAccountDetails = {
  balance : "balance",
  iban : "iban",
  currency : "currency",
  bicSwift : "bicSwift"
}

export const SessionVars = {
  userId: "userId",
  emailChannelId: "emailChannelId",
  emailChannelCode: "emailChannelCode",
  phoneChannelId: "phoneChannelId",
  phoneChannelCode: "phoneChannelCode",
  name: "name",
  username: "username",
  password: "password",
  appName: "appName",
  hasAgreedTerms: "hasAgreedTerms",
  hasAgreedTermsPrivacy: "hasAgreedTermsPrivacy",
  hasAgreedMarketing: "hasAgreedMarketing",
  firstName: "firstName",
  surName: "surName",
  phoneNumber: "phoneNumber",
  dateOfBirth: "dateOfBirth",
  city: "city",
  stateOrProvince: "stateOrProvince",
  country: "country",
  addressStreet: "addressStreet",
  addressNumber: "addressNumber",
  addressRefinement: "addressRefinement",
  postcode: "postcode",
  profession: "profession",
  occupation: "occupation",
  monthlyIncome: "monthlyIncome",
  membershipNumber: "membershipNumber",
  returnUrl: "returnUrl",
  returnUrlHash: "returnUrlHash",
  captchaResponse: "captchaResponse",
  profilePic: "profilePic",
  cardStatus: "cardStatus",
  hasCard: "hasCard",
  lastDateCardPromtShown: "lastDateCardPromtShown",
  idleTimer: "idleTimer",
  revocationEndpoint: "revocationEndpoint",
  cardPrice: "cardPrice",
  currencyType: "currencyType"
}

export const BankAccount = {
  balance: 'balance',
  iban: 'iban',
  bicSwift: 'bicSwift',
  accountNumber: 'accountNumber',
  sortCode: 'sortCode',
  totalNoTransactions:  'totalNoTransactions',
  currency: 'currency'
}

export const TokenData = {
  token: "token",
  refreshToken: "refreshToken",
  createdAt: 0,
  tokenLifespan: "tokenLifespan",
  notificationToken: 'notificationToken'
}

export const Data = {
  bannerId: 'bannerId',
  bannerImg: 'bannerImg',
  bannerLink: 'bannerLink',
  newsId: 'newsId',
  newsImg: 'newsImg',
  newsLink: 'newsLink',
  cardTypeToOrder: 'cardTypeToOrder',
  cardId: 'cardId',
  cardStatus: 'cardStatus',
  hasCard: 'hasCard',
  timerId: 'timerId',
  pickerState: 'pickerState',
  numberOfFailedPin: 'numberOfFailedPin',
  redirectUrl: 'redirectUrl'
}

export const Paths = {
  card: "/Images/" + t('CardFrontImage'),
}

export const inTransaction = ["CardReceive", "Receive", "InterLedgerOmnibus", "ManualCredit"];

export function getCardPrince(){
  return process.env.REACT_APP_CARD_PRICE;
}

export async function TokenValidationv2(){
  const tokenLifespanMs = localStorage.getItem(TokenData.tokenLifespan);

  sessionStorage.setItem(SessionVars.idleTimer, setTimeout(() =>{
    document.location.replace("/pinCheck");
  }, tokenLifespanMs));
}


export async function TokenValidation(){
  const date = new Date();
  const tokenCreation = parseInt(sessionStorage.getItem(TokenData.createdAt));
  const tokenEnd = tokenCreation + parseInt(parseInt(sessionStorage.getItem(TokenData.tokenLifespan)) * 1000);
  
  clearTimeout(sessionStorage.getItem(SessionVars.idleTimer));

  sessionStorage.setItem(SessionVars.idleTimer, setTimeout(() => {
    //document.location.replace("/pinCheck");
  }, 1000 * 60 * 2));

  if(date.getTime() <= tokenEnd){
      return true;
  }
  else{
      const refreshToken = sessionStorage.getItem(TokenData.refreshToken);
      console.log("ref", refreshToken);
      var qs = require('qs');
      var data = qs.stringify({
        'grant_type': 'refresh_token',
        'refresh_token': `${refreshToken}`,
        'client_id': 'BankingMiddleware_App',
        'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
      });
      var config = {
        method: 'post',
        url: API.connectTokenUrl,
        headers: { 
          'content-type': 'application/x-www-form-urlencoded', 
          'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
        },
        data : data
      };
      
      await axios(config)
      .then(res => {
        sessionStorage.setItem(TokenData.token, res.data.access_token);
        sessionStorage.setItem(TokenData.refreshToken, res.data.refresh_token);
        sessionStorage.setItem(TokenData.createdAt, date.getTime());
        
        return true;
      })
      .catch(err => {
        console.log("Error refresing tokens\n", err);
        return false;
      });
  }
  return false;
}

export async function TokenGenerator(username = 'b.nicoara@software-dep.net', password = 'aaaA1!',
              tokenEndpoint = API.connectTokenUrl){
                
  var qs = require('qs');
  var data = qs.stringify({
      'grant_type': 'password',
      'username': username,
      'password': password,
      'scope': 'offline_access email BankingMiddleware openid',
      'client_id': 'BankingMiddleware_App',
      'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
  });

  var config = {
      method: 'post',
      url: tokenEndpoint,
      headers: { 
          'content-type': 'application/x-www-form-urlencoded',
          'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
      },
      data : data
      };

  await axios(config)
  .then(response => {
      const date = new Date();
      sessionStorage.setItem(TokenData.token, response.data.access_token);
      sessionStorage.setItem(TokenData.refreshToken, response.data.refresh_token);
      sessionStorage.setItem(TokenData.createdAt, date.getTime());
      sessionStorage.setItem(TokenData.tokenLifespan, response.data.expires_in);
  })
  .catch(error => {
    console.log("Token error: ", error);
    return "Invalid email/password combination";
  });
}

export function FormatCurrency(value = "1000", type = sessionStorage.getItem(BankAccount.currency)){
  let symbol;

  if(type != null){
    switch(type.toLocaleLowerCase()){
      case "gbp":{
        symbol = "£";
        break;
      }
      case "eur":{
        symbol = "€";
        break;
      }
      case "usd":{
        symbol = "$";
        break;
      }
      case "sgd":{
        symbol = "S$";
        break;
      }
    }
    if(/[-]/g.test(value)){
      value = value.replace("-", "");
      symbol = "- " + symbol;
    }

    let auxValue = "";
    const splitedValue = value.toString().split(".");
    const firstHalf = splitedValue[0];
    let firstDigits = firstHalf.length % 3;
    auxValue = firstHalf.substr(0 , firstDigits);
   
    while(firstDigits < firstHalf.length){
      if(auxValue.length > 0)
        auxValue += ",";
      auxValue += firstHalf[firstDigits++];
      auxValue += firstHalf[firstDigits++];
      auxValue += firstHalf[firstDigits++];
    }

    if(splitedValue.length == 2){
      auxValue += ".";
      auxValue += splitedValue[1];
    }
    return symbol + auxValue;
  }
  setTimeout(() => {
    FormatCurrency(value ,sessionStorage.getItem(BankAccount.currency));
  }, 100)
}

export function GetCardId(){
	return (
		axios.get(API.cardsUrl, {
			headers:{
				Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
			}
		})
	)
}

export const monthsName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function ReversTransactionOrder(list = []){
  let auxList = [];
  let i = list.length - 1;

  while(i >= 0){
      auxList.push(list[i]);
      i--;
  }

  return auxList;
}

export function RemoveBase64Sufix(string){
  let i = 6;
  let aux = string[i - 6] + string[i - 5] + string[i - 4] + string[i - 3] + string[i - 2] + string[i - 1] + string[i];
  
  while(aux != "base64,"){
      i++;
      aux = string[i - 6] + string[i - 5] + string[i - 4] + string[i - 3] + string[i - 2] + string[i - 1] + string[i];
  }
  
  i++;
  return string.substr(i);
}

export async function GetTransaction(rangeLocal, offset = 0){
  let fromDate = rangeLocal[0].selectedYear + "-" + rangeLocal[0].selectedMonth + "-" + rangeLocal[0].selectedDay; ;
  let monthAux = monthsName.indexOf(rangeLocal[1].selectedMonth) + 1;
  let toDate = rangeLocal[1].selectedYear + "-" + monthAux  + "-" + rangeLocal[1].selectedDay + 'T' + "23:59:59.999Z"; 

  return axios.get(API.transactionsUrl, {
      params: {
          itemsPerPage: 100,
          fromDate: fromDate,
          toDate: toDate,
          offset: offset
      },
      headers:{
          Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
      }
  })
}

export function SplitObjAtributs(arr){
  let aux = []
  arr.map(a => {aux.push(a.name)});
  return aux;
}
