import { initializeApp } from 'firebase/app';
import 'firebase/messaging'
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

export const askForPermissioToReceiveNotifications = async () => {
    try {
      const firebaseApp = initializeApp({
        apiKey: "AIzaSyCH-f8_xgH0va2cp78T4IuptLTOySajtJ8",
        authDomain: "wolves-app-61fe4.firebaseapp.com",
        projectId: "wolves-app-61fe4",
        storageBucket: "wolves-app-61fe4.appspot.com",
        messagingSenderId: "399583958863",
        appId: "1:399583958863:web:2a1fd6f394882f2a9a173a",
        measurementId: "G-5V0LVEXDKC"
      });
        
        const messaging = getMessaging(firebaseApp);
        const token = await getToken(messaging);

        console.log(token);
        return token;
    } catch (error) {
        console.error(error);
    }
}