import axios from 'axios';
import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from '../Global/components/reusable';
import { API, BankAccount, BankAccountDetails, Data, FormatCurrency, SessionVars, TokenData } from '../Global/components/utils';
import './gift.css';
import t from '../services/translations';

export default function GiftPage({modalOpened, openModal, type=""}){
    const [containerHeight, setContainerHeight] = useState("265px");
    const [opacity, setOpacity] = useState("0");
    const [benefits, setBenefits] = useState();
    const [height, setHeight] = useState("unset");
    const [infoPopup, setInfoPopup] = useState(false);
    const [message, setMessage] = useState("");

    const giftRef = useRef();
    
    function HandleResize(){
        if(giftRef.current !== null){
            if(modalOpened !== "Card New Address"){
                setContainerHeight(window.innerHeight - giftRef.current.offsetTop - 30);
                setOpacity("1");    
            }   
        }
    }

    async function LoadCardBenefits(){
        axios.get(API.cardBenefits, {
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        })
        .then(res => setBenefits(res.data))
        .catch(err => console.log("ERROR getting the benefits\n", err));
    }

    useEffect(async () => {
        const timeOut  = setTimeout(() => {HandleResize()});
        window.addEventListener("resize", HandleResize);
        await LoadCardBenefits();
        return () => {
            window.removeEventListener("resize", HandleResize);
            clearTimeout(timeOut);
        }
    }, [])

    useEffect(() => {
        if(modalOpened === "Card New Address"){
            setHeight(800);
        }
        else{
            setHeight("unset");
        }
    }, [modalOpened])

    function OnClick(){
        sessionStorage.setItem(Data.cardTypeToOrder, type);
        
        if(type === "physical" && sessionStorage.getItem(BankAccountDetails.balance) < sessionStorage.getItem(SessionVars.cardPrice)){
        //if(true){
            setInfoPopup(true);
            //openModal("Order Card");
            let formatCurrency = FormatCurrency("1000000.50");
            console.log("format currency", formatCurrency);
            setMessage(`You need min ${formatCurrency} in your account to order a card`);
        }
        else{
            openModal("Order Card");
        }
    }     

    return(
        <div ref={giftRef} style={{minHeight: containerHeight, height:height, opacity: opacity}} className="gift-container">
            {infoPopup && (
                <Popup
                    message={message}
                    hasActions={false}
                    closeAction={() => setInfoPopup(false)}/>
            )}
            <div className="left-side">
                <Link id="link" style={{position: 'absolute'}} to={{pathname: '/card', state: {type: 'virtual', cardId: '1234'}}}/>
                <div className="card-container">

                    <img width="320px" src={process.env.PUBLIC_URL + type === "virtual" ?  
                        "/Images/" + t('CardFrontImage') : "/Images/" + t('VirtualCardFrontImage')}/>
                    <div 
                        onClick={() => { OnClick(); }}
                        style={{width: '120px'}} className="btn-container">
                        <span>{type ==="physical" ? "Order a Card" : "Add a card"}</span>
                    </div>
                </div>
            </div>
            <div className="right-side">
                <div dangerouslySetInnerHTML={{__html: benefits}}/>
            </div>
        </div>
    );
}