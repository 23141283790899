import { DropdownDateSelector, DropdownWithSimpleArrow } from '../Global/components/dropdown';
import {useState, useEffect, useRef} from 'react';
import './analytics.css';
import { Bar } from 'react-chartjs-2';
import WolvesChart from '../Global/components/wolvesChart';
import { API, Data, FormatCurrency, inTransaction, ReversTransactionOrder, TokenData, TokenValidation } from '../Global/components/utils';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { LoadingScreen } from '../Global/components/reusable';

const date = new Date();
let yearOptions = [];
let yearsAdded = 0;
while(yearsAdded < 30){
    yearOptions.push(date.getFullYear() - yearsAdded);
    yearsAdded++;
}
const monthsNameShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const specialOptions = ["Today", "This week", "Last week", "This month", "Last month"]

export default function AnalyticsPage(){
    const [message, setMessage] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [transactionType, setTransactionType] = useState("In");
    const [selectedTime, setSelectedTime] = useState("Day");
    const [barWidth, setBarWidth] = useState(500);
    const [historyTimeInterval, setHistoryTimeInterval] = useState(null);
    const [labels, setLabels] = useState([]);
    const [content, setContent] = useState(null);
    const [filename, setFilename] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [dataTotal, setDataTotal] = useState([]);
    const [dataSecondary, setDataSecondary] = useState([]);
    const [color, setColor] = useState("#a0cf67");
    const [error, setError] = useState("");
    const [range, setRange] = useState([
        {
            selectedDay: "1",
			selectedMonth: "January",
			selectedYear: date.getFullYear()
		},
		{
            selectedDay: "31",
			selectedMonth: "December",
			selectedYear: date.getFullYear()
		}
	]);
    const [rangeHistory, setRangeHistory] = useState([
        {
            selectedDay: "1",
			selectedMonth: "January",
			selectedYear: date.getFullYear()
		},
		{
            selectedDay: "31",
			selectedMonth: "December",
			selectedYear: date.getFullYear()
		}
	]);
    const [rangeAnalytics, setRangeAnalytics] = useState([
        {
            selectedDay: "1",
			selectedMonth: "January",
			selectedYear: date.getFullYear()
		},
		{
            selectedDay: "31",
			selectedMonth: "December",
			selectedYear: date.getFullYear()
		}
	]);
    const [leftDatepicker, setLeftDatepicker] = useState("unset");
    const [rightDatepicker, setRightDatepicker] = useState("0px");
    const [loadingTransactions, setLoadingTransactions] = useState(false);

	const monthsName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const noDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const history = useHistory();
    const refTimeFilter = useRef();
    
    function FormatTransactionType(type){
        if(inTransaction.indexOf(type) < 0)
            return "Out";

        return "In";
    }

    async function GetTransaction(offset = 0){
        const date = new Date();
        let fromDate = (date.getFullYear() - 2).toString() + "-" + monthsName[date.getMonth()] + "-" + date.getDate();
        let toDate = date.getFullYear() + "-" + monthsName[date.getMonth()] + "-" + date.getDate();

        return axios.get(API.transactionsUrl, {
            params: {
                itemsPerPage: 100,
                fromDate: fromDate,
                toDate: toDate,
                offset: offset
            },
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        })
    }

    useEffect(async () => {
        setLoadingTransactions(true);
        if(await TokenValidation()){
            let auxList = [];
            try{
                do{
                    var res = await GetTransaction();
                    res.data.forEach(item => auxList.push(item));
    
                }while(res.data.lenght === 100)
            }
            catch(err){
                console.log("ERROR getting the transactions\n", err.response);
                setError("Error loading the transactions, please try to refresh");
            }
            finally{
                setLoadingTransactions(false);
            }
            setTransactions(ReversTransactionOrder(auxList));
        }
        else history.replace("/pinCheck");
    }, [])

    useEffect(() => {
        if(rangeHistory.length === 2){
            let labelsAux = [];
            let dataTotalAux = [];
            let dataSecondaryAux = [];
            
            transactions.filter(elem => elem.status != "Declined").forEach(elem => {
                const date = elem.createdAt.split("T")[0];
                const year = date.split("-")[0];
                const month = date.split("-")[1];
                const day = date.split("-")[2];
                const elemDate = new Date(year, month - 1, day);
                const minDate = new Date(rangeHistory[0].selectedYear, monthsName.indexOf(rangeHistory[0].selectedMonth), rangeHistory[0].selectedDay);
                const maxDate = new Date(rangeHistory[1].selectedYear, monthsName.indexOf(rangeHistory[1].selectedMonth), rangeHistory[1].selectedDay);
                
                if(selectedTime === "Day"){
                    if(elemDate <= maxDate && elemDate >= minDate){
                        const poss = labelsAux.indexOf(day + " " + monthsName[month - 1].substr(0, 3))
                        if(poss == -1){
                            labelsAux.push(day + " " + monthsName[month - 1].substr(0, 3));
                            dataTotalAux.push(elem.amount);
    
                            if(transactionType === "Pending"){
                                if(elem.status === "Pending")
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                            else{
                                if(transactionType === FormatTransactionType(elem.type))
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                        }
                        else{
                            dataTotalAux[poss] += elem.amount;
                            if(transactionType === FormatTransactionType(elem.type)){
                                dataSecondaryAux[poss] += Math.abs(elem.amount)
                            }
                        }
                        
                    } 
                }
                else if(selectedTime === "Month"){
                    if(elemDate <= maxDate && elemDate >= minDate){
                        const poss = labelsAux.indexOf(monthsName[month - 1].substr(0, 3) + " " + elemDate.getFullYear());
                        if(poss == -1){
                            labelsAux.push(monthsName[month - 1].substr(0, 3) + " " + elemDate.getFullYear());
                            dataTotalAux.push(elem.amount);
    
                            if(transactionType === "Pending"){
                                if(elem.status === "Pending")
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                            else{
                                if(transactionType === FormatTransactionType(elem.type))
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                        }
                        else{
                            dataTotalAux[poss] += elem.amount;
                            if(transactionType === FormatTransactionType(elem.type)){
                                dataSecondaryAux[poss] += Math.abs(elem.amount)
                            }
                        }
                        
                    }
                }
                else{
                    if(elemDate <= maxDate && elemDate >= minDate){
                        const poss = labelsAux.indexOf(elemDate.getFullYear());
                        if(poss == -1){
                            labelsAux.push(elemDate.getFullYear());
                            dataTotalAux.push(elem.amount);
    
                            if(transactionType === "Pending"){
                                if(elem.status === "Pending")
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                            else{
                                if(transactionType === FormatTransactionType(elem.type))
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                        }
                        else{
                            dataTotalAux[poss] += elem.amount;
                            if(transactionType === FormatTransactionType(elem.type)){
                                dataSecondaryAux[poss] += Math.abs(elem.amount)
                            }
                        }
                        
                    }
                }
            })
            const chartContainerWidth = document.getElementById("chart-container").clientWidth;
            setLabels(labelsAux);
            setDataSecondary(dataSecondaryAux);
            setDataTotal(dataTotalAux);
            setBarWidth(labelsAux.length * 30 > chartContainerWidth ? labelsAux.length * 30 : chartContainerWidth);
        }
    }, [rangeHistory, selectedTime, transactionType, transactions])

    useEffect(() =>{
        if(rangeHistory.length === 2)
            setHistoryTimeInterval(rangeHistory[0].selectedMonth + " " + rangeHistory[0].selectedYear + " - " + rangeHistory[1].selectedMonth + " " + rangeHistory[1].selectedYear)
    }, [rangeHistory])

    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    })

    const data = {
        labels: labels,
        datasets: [
          {
            data: dataSecondary,
            label: transactionType,
            backgroundColor: `var(--main-color)`,
            borderRadius: 2,
          },

          {
            data: dataTotal,
            label: 'Total',
            backgroundColor: `var(--bg-alt3-color)`,
            borderRadius: 2,
          },
        ],
    };

    const options ={
        barThickness: 25,
        maintainAspectRatio: false,
        
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            },
        },

        plugins: {
            legend: {
                display: false
            },

            tooltip: {
                callbacks: {
                    label: function(context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += FormatCurrency(context.parsed.y);
                        }
                        return label;
                    }
                }
            }
        },
    }
    
    function toggleBorder(props){
        setTransactionType(props.currentTarget.innerHTML);
    }

    function HandleResize(){
        if(window.innerWidth < 515){
            setLeftDatepicker("-25px");
            setRightDatepicker("unset");
        }
        else{
            setLeftDatepicker("unset");
            setRightDatepicker("0px");
        }
    }

    async function DownloadCardStatemanet(){
        if(await TokenValidation()){
            setIsLoading(true);
            let fromData = range[0].selectedYear + "-" + range[0].selectedMonth + "-" + range[0].selectedDay;
            let toData = range[1].selectedYear + "-" + range[1].selectedMonth + "-" + range[1].selectedDay; 
            let formatDataInUrl = `?fromDate=${fromData}&toDate=${toData}`;
            axios.get(API.downloadBankStatement + formatDataInUrl, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                },
                responseType: 'arraybuffer'
            })
            .then(res => {
                setMessage("Card statement downloaded!");
                setColor("#a0cf67");
                const blob = new Blob([res.data], {type: 'application/pdf'})
                const fileDownloadUrl = URL.createObjectURL(blob);
                setContent(fileDownloadUrl);
                setFilename("card statement");
                setIsLoading(false);
            })
            .catch(err => {
                console.log("ERROR downloading the card statement\n", err.response);
                setMessage("Server might be down, try later");
                setColor("red");
                setIsLoading(false);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }

    async function DownloadTransactionReport(){
        if(await TokenValidation()){
            setIsLoading(true);
            let fromData = range[0].selectedYear + "-" + range[0].selectedMonth + "-" + range[0].selectedDay;
            let toData = range[1].selectedYear + "-" + range[1].selectedMonth + "-" + range[1].selectedDay; 
            let formatDataInUrl = `?fromDate=${fromData}&toDate=${toData}`;
            axios.get(API.downloadTransactions + formatDataInUrl, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                },
                responseType: 'arraybuffer'
            })
            .then(res => {
                setMessage("Transaction report downloaded!");
                setColor("#a0cf67");
                console.log(res);
                const blob = new Blob([res.data], {type: 'application/pdf'})
                const fileDownloadUrl = URL.createObjectURL(blob);
                setContent(fileDownloadUrl);
                setFilename("transaction report");
                setIsLoading(false);
            })
            .catch(err => {
                console.log("ERROR downloading the transaction report\n", err.response);
                setMessage("Server might be down, try later");
                setColor("red");
                setIsLoading(false);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }

    //INITIALIZE DATE FOR DATEPICKER
    useEffect(() => {
        const today = new Date();
        const twoDaysAgo = new Date();

        twoDaysAgo.setDate(today.getDate() - 2);
        const pastDay = twoDaysAgo.getDate();
        const pastMonth = monthsName[twoDaysAgo.getMonth()];
        const pastYear = twoDaysAgo.getFullYear();

        setRange([
            {
                selectedDay: pastDay,
                selectedMonth: pastMonth,
                selectedYear: pastYear
            },
            {
                selectedDay: today.getDate(),
                selectedMonth: monthsName[today.getMonth()],
                selectedYear: today.getFullYear()
            }
        ]);

        setRangeAnalytics([
            {
                selectedDay: pastDay,
                selectedMonth: pastMonth,
                selectedYear: pastYear
            },
            {
                selectedDay: today.getDate(),
                selectedMonth: monthsName[today.getMonth()],
                selectedYear: today.getFullYear()
            }
        ]);

        setRangeHistory([
            {
                selectedDay: pastDay,
                selectedMonth: pastMonth,
                selectedYear: pastYear
            },
            {
                selectedDay: today.getDate(),
                selectedMonth: monthsName[today.getMonth()],
                selectedYear: today.getFullYear()
            }
        ]);
    }, [])

    useEffect(() => {
        if(content !== null && filename !== ""){
            document.getElementById("download-link").click();
            URL.revokeObjectURL(content);
            setContent(null);
            setFilename("");
        }
    }, [content, filename])

    let messageVisibility = message != "" ? "" : "none"

    return(
        <div className="d-flex analytics-container">
            <a 
                id="download-link"
                download={`${filename}.pdf`}
                href={content}
                style={{display: 'none'}}>
            </a>
            <div className="download">
                <div className="analytics-header">
                    <span className="analytics-title">Analytics</span>

                    <div className="analytics-icons">
                        {isLoading && (
                            <div style={{position: 'relative'}}>
                                <Loader type="ThreeDots" color="var(--main-color)" height="45" width="100" />
                            </div>
                        )}
                        <div>
                            <img 
                                onClick={() => DownloadTransactionReport()}
                                className="mx-3" src={process.env.PUBLIC_URL + "/Images/Icons/download1.png"}/>
                        </div>

                        <div>
                            <img 
                                onClick={() => DownloadCardStatemanet()}                                
                                src={process.env.PUBLIC_URL + "/Images/Icons/download2.png"}/>
                        </div>
                    </div>
                </div>
                
                <div className="date-selectors">
                    <DropdownDateSelector
						id="analytics-1"
                        range={range}
                        setRange={setRange.bind(this)}/>
                </div>
            </div>
            <div className="body">
            <div className="home-container">
            <div className= "grid-container row p-0 m-0">
                <div className="item col-lg-6 col-md-8 col-12">
                    {loadingTransactions && (<LoadingScreen position="absolute"/>)}
                    <div className="inner-container">
                        <div className="top">
                            <span className="text">Transaction Analytics</span>
                            <div className="date-picker-header">
                                <div className="date-picker-container"> 
                                    <DropdownDateSelector
										id="analytics-2"
                                        range ={rangeAnalytics}
                                        left={leftDatepicker}
                                        right={rightDatepicker}
                                        setRange = {setRangeAnalytics.bind(this)}/>
                                </div>
                            </div>
                        </div>

                        <div className="wolveschart-container">
                            {error.length > 0 ? 
                                <div style={{margin: 'auto'}} className="error-message">
                                    {error}
                                </div>
                            :
                                <WolvesChart range={rangeAnalytics}/>
                            }
                        </div>
                    </div> 
                </div>

                <div className="item col-lg-6 col-md-8 col-12">
                    {loadingTransactions && (<LoadingScreen position="absolute"/>)}
                    <div className="inner-container">
                        <div className="top">
                            <span className="text">Transactions</span>
                            <div className="date-picker-header">
                                 <div className="date-picker-container"> 
                                   <DropdownDateSelector
										id="analytics-3"
                                        range ={rangeHistory}
                                        left={leftDatepicker}
                                        right={rightDatepicker}
                                        setRange = {setRangeHistory.bind(this)}/>
                                </div>
                            </div>
                        </div>

                        <div className="middle">
                            <div className="transaction-type-container">
                                <div
                                    className={transactionType == "In" ? "selected" : ""} 
                                    onClick={toggleBorder.bind(this)}>In</div>

                                <div
                                    className={transactionType == "Out" ? "selected" : ""} 
                                    onClick={toggleBorder.bind(this)}>Out</div>

                                <div
                                    className={transactionType == "Pending" ? "selected" : ""} 
                                    onClick={toggleBorder.bind(this)}>Pending</div>
                            </div>

                            <div className="time-interval-container">
                                <DropdownWithSimpleArrow
                                    headerValue={selectedTime}
                                    refPassed={refTimeFilter}
                                    setSelected={setSelectedTime.bind(this)}
                                    option={["Day", "Month", "Year"]}/>
                            </div>
                        </div>
                        
                        <div id="chart-container" style={{display: 'flex'}} className="chart-container">
                            {error.length > 0 ? 
                            <div style={{margin: 'auto'}} className="error-message">
                                {error}
                            </div>
                            : 
                            <div style={{width:'100%', height: '100%', display: 'flex'}}>
                                {dataSecondary.length > 0 && (
                                    <div style={{width: barWidth, minWidth:'100%', height: 250}}>
                                        <Bar
                                            data={data}
                                            width={"100%"}
                                            options= {options}/>
                                    </div>
                                )}

                                {dataSecondary.length === 0 &&(
                                    <div style={{margin: 'auto', textAlign:'center', fontFamily: 'AppFont', fontSize:'20px'}}>
                                        No transactions to display for this time interval
                                    </div>
                                )}
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        
            <div className="message-container">
                <div className="message" style={{display: messageVisibility, backgroundColor: color}}>
                    <div>
                        <img src={process.env.PUBLIC_URL + "/Images/Icons/checkmark.png"}/>
                        <span className="mx-3 ml-0">{message}</span>
                    </div>
                    <div>
                        <img 
                            style={{cursor: 'pointer', zIndex: 11}}
                            width="24px"
                            onClick={() => {setMessage("")}}
                            src={process.env.PUBLIC_URL + "/Images/Icons/cancel.png"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}