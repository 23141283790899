import './App.css';
import WelcomePage from './Welcome/welcome.js';
import SignUpPage from './SignUp/signup.js';
import InsertPhonePage from './SignUp/phoneInsert.js';
import {BrowserRouter as Router, Route, useHistory} from 'react-router-dom';
import EmailVerificationPage from './SignUp/emailVerification';
import PhoneVerificationPage from './SignUp/phoneVerification';
import EmailConfirmedPage from './SignUp/emailConfirmed';
import PhoneConfirmedPage from './SignUp/phoneConfirmed';
import TermsConditionsPage from './SignUp/terms&conditions';
import FormNamePage from './SignUp/formName';
import FormRegionPage from './SignUp/formRegion';
import FormLocationPage from './SignUp/formLocation';
import FormPasswordPage from './SignUp/formPassword';
import FormProfessionPage from './SignUp/formProfession';
import PhotoUploadPage from './SignUp/photoUpload';
import CongratsPaga from './SignUp/congrats';
import Test2 from './Test/test2';
import SiguInPage from './SignIn/signin';
import SignConfirmedPage from './SignIn/signConfirmed';
import SignDonePage from './SignIn/signDone';
import SecurityPassword from './Dashboar/securityPassword';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardPage from './Card/card.js';
import KycPage from './SignIn/kyc';
import PinPage from './SignIn/pin';
import PinCheckPage from './SignIn/pinCheck';
import TopupPageStandalone from './Topup/topuPage';
import TransactionPageStandalone from './Transaction/transactionPage';
import CardPageStandalone from './Card/cardPageStandalone';
import SecurityPageStandalone from './Dashboar/securityPage';
import PersoalInfoPageStandalone from './Dashboar/personalInfoPage';
import FaqPageStandalone from './Dashboar/faqPage';
import TermsAndCondPageStandalone from './Dashboar/termAndCondPage';
import PrivacyPageStandalone from './Dashboar/privacyPage';
import SupportPageStandalone from './Dashboar/supportPage';
import AccountPageStandalone from './Dashboar/accountPage';
import GifPageStandalone from './Card/giftPage';
import Topup2PageStandalone from './Topup/topu2Page';
import AnalyticsPageStandalone from './Transaction/analyticsPage';
import PersonalInfoPicStanalone from './Dashboar/personalInfoPic';
import NotificationPageStandalone from './Notification/notificationPage';
import OffersPageStandalone from './Notification/offersPage';
import SettingsPageStandalone from './Notification/settingsPage';
import LandingPage from './Landing/landing';
import PrincingPage from './Pricing/pricing';
import { BankInfoPageStandalone } from './Dashboar/bankInfo';
import { CardListStandalone } from './Card/cardList';
import { CardAddStandalone } from './Card/cardAdd';
import ResetPwPage from './SignIn/resetPw';
import { useEffect } from 'react';
import t from './services/translations'
function App() {
  useEffect(()=>{
    window.document.title = t('AppTitle', { Brand: t('AppBrand') });
  }, []);
  return (
    <Router>
      <Route path="/" exact component={LandingPage}/>
      <Route path="/pricing" exact component={PrincingPage}/>

      <Route path="/app" exact component={WelcomePage}/>
      <Route path="/signup" exact component={SignUpPage}/>
      <Route path="/insertPhone" exact component={InsertPhonePage}/>
      <Route path="/emailVerification" exact component={EmailVerificationPage}/>
      <Route path="/phoneVerification" exact component={PhoneVerificationPage}/>
      <Route path="/emailConfirmed" exact component={EmailConfirmedPage}/>
      <Route path="/phoneConfirmed" exact component={PhoneConfirmedPage}/>
      <Route path="/termsconditions" exact component={TermsConditionsPage}/>
      <Route path="/formName" exact component={FormNamePage}/>
      <Route path="/formRegion" exact component={FormRegionPage}/>
      <Route path="/formLocation" exact component={FormLocationPage}/>
      <Route path="/formProfession" exact component={FormProfessionPage}/>
      <Route path="/formPassword" exact component={FormPasswordPage}/>
      <Route path="/congrats" exact component={CongratsPaga}/>
      <Route path="/photoUpload" exact component={PhotoUploadPage}/>

      <Route path="/signin" exact component={SiguInPage}/>
      <Route path="/kyc" exact component={KycPage}/>
      <Route path="/signConfirmed" exact component={SignConfirmedPage}/>
      <Route path="/pin" exact component={PinPage}/>
      <Route path="/signDone" exact component={SignDonePage}/>
      <Route path="/reset_password" exact component={ResetPwPage}/>

      <Route path="/home" exact component={CardPage}/>
      <Route path="/dashboard/security" exact component={SecurityPageStandalone}/>
      <Route path="/dashboard/security/password" exact component={SecurityPassword}/>
      <Route path="/dashboard/personalInfo" exact component={PersoalInfoPageStandalone}/>
      <Route path="/dashboard/personalInfo/picture" exact component={PersonalInfoPicStanalone}/>
      <Route path="/dashboard/faq" exact component={FaqPageStandalone}/>
      <Route path="/dashboard/termsAndCond" exact component={TermsAndCondPageStandalone}/>
      <Route path="/dashboard/privacy" exact component={PrivacyPageStandalone}/>
      <Route path="/dashboard/support" exact component={SupportPageStandalone}/>
      <Route path="/dashboard/account" exact component={AccountPageStandalone}/>
      <Route path="/dashboard/bankInfo" exact component={BankInfoPageStandalone}/>

      <Route path="/card" exact component={CardPageStandalone}/>
      <Route path="/card/new" component={GifPageStandalone}/>
      <Route path="/cardList" component={CardListStandalone}/>
      <Route path="/cardAdd" component={CardAddStandalone}/>

      <Route path="/topup" exact component={Topup2PageStandalone}/>

      <Route path="/transactions" exact component={TransactionPageStandalone}/>
      <Route path="/transactions/analytics" exact component={AnalyticsPageStandalone}/>

      <Route path="/news" exact component={NotificationPageStandalone}/>
      <Route path="/offers" exact component={OffersPageStandalone}/>
      <Route path="/settings" exact component={SettingsPageStandalone}/>

      <Route path="/test" exact component={Test2}/> 
      <Route path="/pinCheck" exact component={PinCheckPage}/>

    </Router>
  );
}

export default App;
