import axios from 'axios';
import { useEffect, useState } from 'react';
import { API, TokenData, TokenValidation } from '../Global/components/utils';
import './offers.css';
import {useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { ErrorWithTimer } from '../Global/components/reusable';

export default function Offers(){
    const [imgsToDisplay, setImgsToDisplay] = useState([]);
    const [linksToDisplay, setLinksToDisplay] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const history = useHistory();
    let totalOffers = 0;

    useEffect(async () => {
        setLoading(true);

        if(await TokenValidation()){
            axios.get(API.offersUrl, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                let ids = [];
                totalOffers = res.data.totalCount;
                res.data.items.forEach(item => ids.push(item.id));
                let imgs = [];
                let links = [];
    
                ids.forEach(id => {
                    let url = API.offersUrl + "/" + id;
    
                    axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                        }
                    })
                    .then(res => {
                        imgs.push(res.data.imageBase64);
                        links.push(res.data.redirectUrl);
                        if(imgs.length === totalOffers){
                            setImgsToDisplay(imgs);
                            setLinksToDisplay(links);
                        }
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log("ERROR getting offers by id\n", err);
                        setError("Not all offers loaded");
                        setImgsToDisplay(imgs);
                        setLinksToDisplay(links);
                        setLoading(false);
                    })
                })
            })
            .catch(err => {
                console.log("ERROR getting offers\n", err);
                if(err.response.status >= 500){
                    setError("Internal server error loading the offers")
                }
                else{
                    setError("Some errro occured during your request")
                }
                setLoading(false);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }, [])

    return(
        <div className="offers-container">
            {loading && (
                <div style={{position: 'absolute'}}>
                    <Loader type="ThreeDots" color="var(--main-color)" height="100" width="100" />
                </div>
            )}
            <span className="error-message">{error}</span>
            {imgsToDisplay.map((img, index) =>
                <Item 
                    url={linksToDisplay[index]}
                    img={img}/>
                )
            } 
        </div>
    );
}

function Item({name = "", price = "", img="", url=""}) {
    return(
        <div 
            onClick={() => window.open(url, "_blank")}
            className="sigle-item">
            <img 
                width="260px"
                height="260px"
                src={"data:image/png;base64," + img}/>
            <div className="info">
                <span className="name">{name}</span>
                <span className="price">{price}</span>
            </div>
        </div>
    );
}