import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DownloadContainer, StickyHeader } from '../Global/components/StickyHeader';
import { getCardPrince, SessionVars } from '../Global/components/utils';
import './pricing.css';
import t from '../services/translations';

export default function PrincingPage(){
    const history = useHistory();
    useEffect(() => {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            document.getElementById("createButton").style.opacity = 1;
        }
        window.scrollTo(0, 0);
        document.getElementsByTagName("html")[0].style.backgroundColor = "var(--bg-color)";
    }, [])

    return(
        <div className="pricing-page-container">
            <StickyHeader/>

            <div className="title">
                <span>
                    Choose your {t('AppBrand')} Debit Card
                </span>
            </div>

            <div className="cards-container">
                <div className="my-card">
                    <span className="title-card">
                        {t('AppBrand')} Official Debit Card
                    </span>
                    <img src={process.env.PUBLIC_URL + "/Images/" + t('CardFrontImage')}/>
                    <span className="info">
                        The Official {t('AppBrand')} Debit Card will arrive by post, ready for you to use online and 
                        in-store. This card will emit a secure tone which will enable you to log in to the app, 
                        and in the future pay online. You can easily and instantly top up your card right from 
                        your exclusive app and see a list of all transactions – easily helping you to manage your 
                        expenditure. On top of that, all card holders will have access to exclusive prize draws 
                        and club offers when using their card
                    </span>
                    <a target="_blank" href="https://www.google.com">Terms And Conditions</a>
                    <div id="createButton" onClick={() => history.push("/app")} className="button">
                        Create Account
                    </div>
                </div>
            </div>

            <DownloadContainer/>
            <div className="footer">
                <div className="text-container">
                    <div className="text1">
                        THE REPUTATION EXCHANGE PLC Suite 2a1, Northside House, Mount Pleasant, Barnet, Hertfordshire, EN4 9EB - United Kingdom - Company Number 09977505
                    </div>

                    <div className="text2">
                        Privacy Policy | Cookie Policy
                    </div>
                </div>
            </div>
        </div>
    );
}