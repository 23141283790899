import { useEffect, useRef, useState } from 'react';
import './cardDone.css';
import t from '../services/translations'

export default function CardDone({openModal}){
    const [modalPosition, setModalPosition] = useState("fixed");

    const refModal = useRef();

    function HandleResize(){
        if(refModal.current.clientHeight + 20 > window.innerHeight){
            setModalPosition("absolute");
        }
        else{
            setModalPosition("fixed");    
        }
    }

    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    })

    return(
        <div>
            <div ref={refModal} style={{position: modalPosition}} className="modal-container-card-done">   
                <button 
                    onClick={openModal.bind(this, "")}
                    className="close-btn">&times;</button>
                <div className="header">
                    <div className="title-container">
                        <div className="checkmark2">
                            <div className="oval"/>
                            <div className="line1"/>
                            <div className="line2"/>
                        </div>

                        <span className="title1">Congratulations</span>
                        <span className="subtitle1">Your {t('AppBrand')} Offical Debit Card has been succesfully ordered.</span>
                    </div>
                    <div className="card-container">
                        <img 
                            src={process.env.PUBLIC_URL + "/Images/" + t('CardFrontImage')}
                            className="card-logo"/>
                    </div>
                </div>
            </div>
            <div className="overlay"/>
        </div>
    );
}