import LeftSide from "../Global/components/leftSide";
import {AlreadyHaveAnAccount, MiniLogo, PageTitle} from "../Global/components/reusable";
import {ContinueBtn, LargeInputWithTextOnTop} from '../Global/components/buttons';
import React, {useEffect, useRef, useState } from "react";
import {Scrollbars} from 'react-custom-scrollbars';
import './signup.css'
import axios from "axios";
import { API, SessionVars } from "../Global/components/utils";
import { setPassword } from "../Global/components/Redux/actions";

function SignUpPage(){
    return(
        <div className="container-fluid row main m-0 p-0">
            <LeftSide/>
            <RightSide/> 
        </div>
    );
}

const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}

// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context("../Images/Flags", false, /\.(png|jpe?g|svg)$/));

const options = [
    {language:"United Kindom - English", img:"united-kingdom.png"},
    {language:"Deutschland - Deutsch", img:"germany.png"}, 
    {language:"France - France", img:"france.png"},
    {language:"Espana - Espanol", img:"spain.png"},
    {language:"Romania - Romana", img:"romania.png"}
];

function RightSide () {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const dropdownRef = useRef();

    const toggling = () => setIsOpen(!isOpen);
  
    const onOptionClicked = value => () => {
      setSelectedOption(value);
      setIsOpen(false);
    };

    function continueBtn(){
        if(email != ""){
            setEmailError("");
        }
        
        axios.post( API.channelValidationUrl, {
            identifier: email,
            type: "Email"
        })
        .then(res => {
            setEmailError("");
            console.log(res.data);
            sessionStorage.setItem(SessionVars.appName, email);
            sessionStorage.setItem(SessionVars.emailChannelId, res.data);
            window.open("/emailVerification", "_self");
        })
        .catch(err => {setEmailError("Invalid email"); console.log("Invalid email address\n: ", err)});
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        function handler(event) {
            if(!dropdownRef.current?.contains(event.target)) {
                setIsOpen(false)
            }
        }
        window.addEventListener('click', handler)

        return () => window.removeEventListener('click', handler)
    }, [])

    let languageStyle = () => {
        if(!selectedOption) 
            return "";
            
        else{
            return "has-value"
        }
    }
    

    let dropdownStyle = isOpen ? "dropdown-header" : "dropdown-header"; 
    let arrowStyle = isOpen ? "arrow" : "arrow";
    let ddContainerZ = isOpen ? "2" : "0";

        return(
            <div className="col-lg-7 p-0 m-0 d-flex right row">
                <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                    
                    <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                        <div>
                            <MiniLogo/>
                        </div>

                        <div className="page-title-container">
                            <PageTitle
                                title="Sign Up"
                                message="Let's get started!"/>
                        </div>
                    </div>

                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-column w-100">
                            <span style={{fontFamily: 'AppFontBold', fontSize: '22px', marginBottom: '15px', lineHeight: '20px'}} className="">
                                Enter your email address and
                                we’ll email you a code you need
                                to enter on the next page
                            </span>
                            
                            <div className="email-container-signupPage mb-2">
                                <LargeInputWithTextOnTop
                                    error={emailError}
                                    setError = {setEmailError.bind(this)}
                                    upText="Email address"
                                    onChange={e => {setEmail(e.target.value)}}
                                    placeholder="Please enter your email address"/>               
                            </div>   
                        </div>

                        {/* <div className="dropdown-container" ref={dropdownRef}>
                            <span className="up-text">Language</span>
                            <div className={dropdownStyle} onClick={toggling}>
                                <div className="d-flex justify-content-between align-items-center h-100">
                                    <span className={languageStyle()}>{selectedOption || "Select Language"}</span>
                                    <div onClick={toggling} className={arrowStyle}/>
                                </div>
                            </div>
                            
                            {isOpen && (
                            <div className="hide">
                            <div className="scroll-container">
                                <Scrollbars
                                renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                style={{height: '100%', width: '100%', zIndex: '2'}}>
                                    <div className="dropdown-list">
                                        {options.map((option, i) => (
                                            <div className="wrapper">
                                                <div className="list-item-container"  onClick={onOptionClicked(option.language)}>
                                                    {Object.entries(cache).map(module => {
                                                        const image = module[1].default;
                                                        const name = module[0].replace("./","");
                                                        if(name.indexOf(option.img) >= 0){
                                                            return (
                                                                <img src={image} width="40px"/>
                                                            )
                                                        }
                                                    })}
                                                    
                                                    <li className="list-item" key={i} defaultValue="Select your language">
                                                        {option.language}
                                                    </li>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Scrollbars>
                            </div>
                            </div>
                            )}
                    </div>*/}
                    </div> 

                    <div className="continue-btn-container-signupPage">
                        <ContinueBtn onClick={() => {continueBtn()}}/>
                    </div>
                    
                    <div className="already-container-signupPage w-100">
                        <AlreadyHaveAnAccount
                            text="Already have an account?"
                            textGold=" Sign In"/>
                    </div>
                    
                </div>
            </div>
    );
}

export default SignUpPage;