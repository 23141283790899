import './test2.css';
import { useEffect, useRef, useState, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { API, FormatCurrency } from '../Global/components/utils';
import { askForPermissioToReceiveNotifications } from '../Firebase/push-notification';
import axios from 'axios';
import { meaWallet } from '../Global/meaWallet';

export default function Test2(){
	const [text, setText] = useState();

	function Haha(setText = e => {}){
		setText("aa");
	}

	function Haha2(){

	}

	return(
		<div style={{width: '100vw', minHeight:'100vh', padding: '100px 0'}} className="contianer-fluid d-flex flex-column align-items-center">
			<button onClick={meaWallet}>
				Click me
			</button>
		</div>
	);
}