import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ContinueBtn, LargeInputWithTextOnTop } from '../Global/components/buttons';
import LeftSide  from '../Global/components/leftSide';
import { ErrorWithTimer, MiniLogo, PageTitle } from '../Global/components/reusable';
import { API } from '../Global/components/utils';
import '../Welcome/welcome.css';

export default function ResetPwPage(){

    return(
        <div className="container-fluid d-flex row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
} 

function RightSide(){
    const [email, setEmail] = useState();
    const [error, setError] = useState();

    function sendCode(){
        console.log(email.length == 0);
        axios.post(API.sentEmailResetPw, {
            "email": email,
            "appName": "AUTH",
        })
        .then(res => {
            var link = document.getElementById("next");
            localStorage.clear();
            sessionStorage.clear();
            link.click();
        })
        .catch(err => {
            if(err.response.status >= 400 && err.response.status< 500){
                setError("Invalid email address");
            }
            else{
                setError("Server error");
            }
        })

        
    }


    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row">
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>

                    <div className="page-title-container">
                        <PageTitle
                            title="Forgot password"
                            message="Enter an email address to receive instructions"/>
                    </div>
                </div>

                <div>
                    <LargeInputWithTextOnTop
                        upText='Email'
                        maxLenght={256}
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Please enter your email'/>
                </div>
                <div></div>

                <Link id="next" style={{position: 'absolute'}} to={{pathname: '/signin', flag: true}}></Link>
                <div style={{marginBottom: '20px'}}>
                    <ErrorWithTimer error={error} setError={(e) => setError(e)}/>
                    <ContinueBtn 
                        isDisabled = {email?.length == 0}
                        onClick={() => sendCode()}
                        message='Send'/>
                </div>
            </div>
        </div>

    );
}
