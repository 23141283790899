import LeftSide from "../Global/components/leftSide";
import { MiniLogo, PageTitle } from "../Global/components/reusable";
import { BackAndContinueBtns, ContinueBtn} from '../Global/components/buttons';
import { Link } from 'react-router-dom';
import './congrats.css'

export default function CongratsPaga(props){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide password={props.location.state}/>
        </div>
    );
}

function RightSide({password}) {
    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row" style={{height: 'auto'}}>
        <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
            <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                <div>
                    <MiniLogo/>
                </div>
            
                <div className="page-title-container">
                    <PageTitle
                        title="Sign Up"
                        message="Let's get started!"/>
                </div>

                </div>
                <div className="d-flex flex-column confirmation-container align-items-center mb-5">
                    <div className="checkmark2">
                            <div className="line1"/>
                            <div className="line2"/>
                            <div className="oval"/>
                    </div>
                
                    <div className="congrats mt-3">Congratulations!</div>
                    <div className="message">Your account has been created</div>
                    
                </div>
                <div style={{marginBottom: '20px'}}>
                    <BackAndContinueBtns
                        backHandler={() =>  window.open("/signin", "_self")}
                        continueHandler={() => window.open("/photoUpload", "_self")}
                        back="Login"
                        forward="Upload profile picture"/>
                </div>
            </div>
        </div>
    );
}